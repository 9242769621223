import React from 'react';
import {
    Card, CardBody, Col
} from 'reactstrap';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import CommonHelpers from '../../../helpers/CommonHelpers';

const ContactProfileRoles = ({roles}) => {
    const { t } = useTranslation(['contacts']);

    return (
        <>
            {(roles && roles.length > 0) && (
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody className="profile__card">
                            <div className="profile__information">
                                <div className="profile__data">
                                    <p className="profile__name">
                                        {t('userRoles', {ns: 'contacts'})}
                                    </p>
                                    {roles && roles.map(role => <p key={`index_${role}`} className="profile__work">{CommonHelpers.getRoleNameText(role)}</p>)}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </>
    );
};

ContactProfileRoles.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ContactProfileRoles;
