import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import materialTextField from '../../../shared/components/form/materialTextField';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import UserHelpers from '../../../helpers/users/UserHelpers';
import CommonHelpers from '../../../helpers/CommonHelpers';
import { emailValid, required } from '../../../helpers/forms/validation';

const UsersForm = ({ userInformation, handleSaveUser, closeUserForm }) => {
    const [roles, setRoles] = useState([]);
    const [activeRole, setActiveRole] = useState('');
    const [initialUserValues, setInitialUserValues] = useState({});

    const { t } = useTranslation(['settings', 'common', 'forms']);

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    useEffect(() => {
        function initializeValues() {
            let initialRoles = [];
            let initialRoleTypes = [];
            if (Object.hasOwn(userInformation, 'roles')) {
                initialRoles = userInformation.roles.map((role) => {
                    return { [role.role_name]: true };
                });
                initialRoleTypes = userInformation.roles.map((role) => {
                    return { [`${role.role_name}_type`]: role.role_type };
                });
            }

            let initialValues = {
                first_name: userInformation.first_name ?? '',
                last_name: userInformation.last_name ?? '',
                email: userInformation.email ?? '',
            };
            initialValues = Object.assign(...initialRoles, initialValues);
            initialValues = Object.assign(...initialRoleTypes, initialValues);
            setInitialUserValues(initialValues);
        }

        // Get roles
        getRoles();
        initializeValues();
    }, [userInformation]);

    const onRoleNameClick = (event) => {
        const checkedRole = _.find(roles, { 'role_name': event.target.name});
        checkedRole.checked = !checkedRole.checked;
        setRoles(roles.map((role) => {
            return role.role_name === event.target.name ? checkedRole : role;
        }));
    };

    const getRoles = async () => {
        const rolesData = await UserHelpers.getRoles();
        const checkedRolesData = addRoleChecks(rolesData);
        setRoles(checkedRolesData);
    };

    const addRoleChecks = (rolesData) => {
        return rolesData.map((r) => {
            r.checked = false;
            if (Object.hasOwn(userInformation, 'roles')) {
                const idx = _.findIndex(userInformation.roles, { 'role_name': r.role_name });
                r.checked = idx >= 0;
            }
            return r;
        });
    };

    const handleOnMouseEnterRole = (e) => {
        const eventRole = e.currentTarget.getAttribute('data-role');
        const checkedRole = _.find(roles, { 'role_name': eventRole});
        if (checkedRole && checkedRole.role_name) {
            setActiveRole(checkedRole.role_name);
        }
    };

    const roleList = roles.map((r) => {
        return (
            <div
                key={`check_index_${r.role_name}`}
                data-role={r.role_name}
                className="mt-3 mb-4"
                onMouseEnter={handleOnMouseEnterRole}
            >
                <Field
                    name={r.role_name}
                    type="checkbox"
                    component={renderCheckBoxField}
                    label={CommonHelpers.getRoleNameText(r.role_name)}
                    className="colored-click"
                    onChangeHandler={onRoleNameClick}
                />
            </div>
        );
    });

    const roleTypes = (roleName) => {
        const role = _.find(roles, { role_name: roleName});
        const types = [...new Set(role.role_permissions.map(permission => permission.role_type))];
        return types.map((type) => {
            return (
                <Col className="mt-2">
                    <div key={`radio_index_${role.role_name}_${type}`} className="mb-2">
                        <Field
                            name={`${role.role_name}_type`}
                            component={renderRadioButtonField}
                            label={CommonHelpers.getRoleTypeText(type)}
                            radioValue={type}
                            disabled={!role.checked}
                            className="colored-click"
                        />
                    </div>
                    <ul className="list list--icon list--icon-green pl-1">
                        {role.role_permissions.map(permission => (Number(permission.role_type) === Number(type)
                            && (
                                <li key={`description_index_${permission.permission_name}_${type}`} className={!role.checked ? 'form__radio_button_disabled' : undefined}>
                                    <p><span className="lnr lnr-checkmark-circle" />{t(permission.permission_name)}</p>
                                </li>
                            )
                        ))}
                    </ul>
                </Col>
            );
        });
    };

    const organizeDataForSave = (values) => {
        const firstName = values.first_name;
        const lastName = values.last_name;
        const email = values.email;
        const organizedRoles = [];
        // Organize user data and roles for API call
        Object.keys(values).forEach((key) => {
            // Add role id and type of each role assigned to user
            const idx = roles.findIndex(e => key.includes(e.role_name));
            if (idx > -1) {
                // Add only roles that have value true
                if (!key.includes('type') && values[key]) {
                    const organizedRoleId = roles[idx].role_id;
                    const organizedRoleType = values[`${key}_type`];
                    organizedRoles.push({id: organizedRoleId, type: organizedRoleType});
                }
            }
        });
        const addUser = Object.keys(userInformation).length === 0;
        let oldEmail = '';
        if (!addUser) {
            oldEmail = userInformation.email;
        }

        handleSaveUser(firstName, lastName, email, oldEmail, organizedRoles, addUser);
    };

    return (
        <Col>
            <Row className="mb-3 mt-3">
                <Col>
                    <Button
                        className="icon"
                        outline
                        onClick={() => closeUserForm()}
                    >
                        <p><ArrowBackIcon /></p>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col className="card__title">
                            <h5 className="bold-text">{Object.keys(userInformation).length === 0 ? t('addAccount') : t('editAccount')}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={organizeDataForSave} initialValues={initialUserValues}>
                                {({ handleSubmit }) => (
                                    <form className="material-form" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <h4 className="subhead mb-3">{t('userInfo')}</h4>
                                                <Row>
                                                    <Col xs={12} lg={4}>
                                                        <Field
                                                            name="first_name"
                                                            component={materialTextField}
                                                            label={t('firstName', { ns: 'common' })}
                                                            validate={required}
                                                        />
                                                    </Col>
                                                    <Col xs={12} lg={4}>
                                                        <Field
                                                            name="last_name"
                                                            component={materialTextField}
                                                            label={t('lastName', { ns: 'common' })}
                                                            validate={required}
                                                        />
                                                    </Col>
                                                    <Col xs={12} lg={4}>
                                                        <Field
                                                            name="email"
                                                            component={materialTextField}
                                                            placeholder="example@mail.com"
                                                            type="email"
                                                            label={t('email', { ns: 'common' })}
                                                            validate={composeValidators(required, emailValid)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4 className="mt-2 mb-2 subhead">{t('roles')}</h4>
                                                <Row>
                                                    <Col xs={4}>
                                                        {roleList}
                                                    </Col>
                                                    <Col xs={12} lg={8}>
                                                        <Row>
                                                            {activeRole && roleTypes(activeRole)}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button color="primary" type="submit">
                                                    {t('buttons.save', {ns: 'forms'})}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

UsersForm.propTypes = {
    userInformation: PropTypes.shape(),
    handleSaveUser: PropTypes.func.isRequired,
    closeUserForm: PropTypes.func.isRequired
};

UsersForm.defaultProps = {
    userInformation: {}
};

export default UsersForm;
