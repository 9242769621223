import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
}) => {
    const { t } = useTranslation(['meetings', 'login', 'settings', 'contacts', 'companies']);
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);
    const hasPermissionManageCloudUsers = PermissionHelpers.hasPermission(PERMISSIONS.manage_cloud_users);
    const hasPermissionShowContacts = PermissionHelpers.hasPermission(PERMISSIONS.show_contacts);
    const hasPermissionShowCompanies = PermissionHelpers.hasPermission(PERMISSIONS.show_companies);

    return (
        <div className="sidebar__content">
            <ul className="sidebar__block">
                <SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>
                    <button type="button" className="sidebar__link" onClick={changeToLight}>
                        <p className="sidebar__link-title">Light Theme</p>
                    </button>
                    <button type="button" className="sidebar__link" onClick={changeToDark}>
                        <p className="sidebar__link-title">Dark Theme</p>
                    </button>
                </SidebarCategory>
            </ul>
            <ul className="sidebar__block">
                <SidebarLink title="Dashboard" icon="home" route="/dashboard" onClick={onClick} />
                {hasPermissionShowMeetings && (
                    <SidebarLink title={t('meetings')} icon="calendar-full" route="/meetings" onClick={onClick} />
                )}
                {hasPermissionShowContacts && (
                    <SidebarLink title={t('contacts', {ns: 'contacts'})} icon="users" route="/contacts" onClick={onClick} />
                )}
                {hasPermissionShowCompanies && (
                    <SidebarLink title={t('companies', {ns: 'companies'})} icon="book" route="/companies" onClick={onClick} />
                )}

                {/* <SidebarCategory title="Example Pages" icon="diamond" sidebarCollapse={sidebarCollapse}>
                    <SidebarLink title="Page one" route="/pages/one" onClick={onClick} />
                    <SidebarLink title="Page two" route="/pages/two" onClick={onClick} />
                    <SidebarCategory title="Example Subpages" sidebarCollapse={sidebarCollapse}>
                        <SidebarLink title="Subpage one" route="/" onClick={onClick} />
                        <SidebarLink title="Subpage two" route="/" onClick={onClick} />
                    </SidebarCategory>
                </SidebarCategory> */}
            </ul>
            <ul className="sidebar__block">
                <SidebarCategory title={t('settings', { ns: 'settings' })} icon="cog" sidebarCollapse={sidebarCollapse}>
                    <SidebarLink title={t('companyInformation', { ns: 'settings' })} route="/settings/company" onClick={onClick} />
                    {hasPermissionManageCloudUsers && (
                        <SidebarLink title={t('users', { ns: 'settings' })} route="/settings/users" onClick={onClick} />
                    )}
                    {/* <SidebarLink title={t('integrations', { ns: 'settings' })} route="/settings/integrations" onClick={onClick} /> */}
                </SidebarCategory>
            </ul>
        </div>
    );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
