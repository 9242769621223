import React, {useEffect, useState} from 'react';
import {Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Session from '../../../helpers/Session';

function PrivateRoute({component: Component, ...rest}) {
    const [logged, setLogged] = useState(false);
    const [sessionChecked, setSessionChecked] = useState(false);

    useEffect(() => {
        async function checkSession() {
            const response = await Session.isUserLogged();
            setLogged(response);
            setSessionChecked(true);
        }
        checkSession();
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (logged && sessionChecked) {
                    return <Component {...props} {...rest} />;
                }

                if (!sessionChecked) {
                    return '';
                }

                return <Redirect to="/" />;
            }}
        />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired
};

export default PrivateRoute;
