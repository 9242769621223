import { Modal } from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { getDirection } from '../../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import EditNameForm from '../forms/EditNameForm';
import CompanyNameHelpers from '../../../../helpers/Company/CompanyNameHelpers';
import {ERROR_CODES} from '../../../../helpers/ErrorCodes';

const CompanyNameModal = ({ rtl, companyName, setUpdatedName, toggleModal, showModal }) => {
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['companies']);

   const toggleNameModal = () => {
        setErrorAlert('');
       toggleModal();
    };

    const handleSave = async (data) => {
         try {
            const updatedName = await CompanyNameHelpers.updateCompanyName(
                companyName.id,
                data.name,
                data.businessId
            );

             setUpdatedName(updatedName);
             toggleModal();
        } catch (e) {
             const errorCode = e.code ?? '';
             let errMsg = t('errors.failedToSaveData', {ns: 'forms'});
             if (errorCode === ERROR_CODES.companyNameAlreadyExist) {
                 errMsg = t('errors.companyNameAlreadyExist', {ns: 'companies'});
             }

            setErrorAlert(errMsg);
        }
    };

    const initialNameValues = {
        businessId: companyName.businessId,
        name: companyName.name
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                toggle={toggleNameModal}
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleNameModal}
                    />
                    <h4 className="text-modal  modal__title">
                        {t('companyName', {ns: 'companies'})}
                    </h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave} initialValues={initialNameValues}>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <EditNameForm
                                    toggleModal={toggleNameModal}
                                    errorAlertText={errorAlert}
                                    submitButtonText={t('buttons.save', {ns: 'forms'})}
                                />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

CompanyNameModal.propTypes = {
    rtl: RTLProps.isRequired,
    companyName: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setUpdatedName: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default connect(state => ({
    rtl: getDirection(state),
}))(CompanyNameModal);
