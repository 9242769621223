import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import materialTextField from '../../../../shared/components/form/materialTextField';
import { matchesOtherValue, passwordValid, required } from '../../../../helpers/forms/validation';

const ChangePasswordForm = ({ toggleModal }) => {
    const { t } = useTranslation(['profile', 'common', 'forms']);
    const {
        getFieldState
    } = useForm();

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    return (
        <>
            <Row>
                <Col>
                    <Field
                        name="old_password"
                        component={materialTextField}
                        type="password"
                        label={t('profileMain.oldPassword')}
                        validate={composeValidators(required, passwordValid)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="new_password"
                        component={materialTextField}
                        type="password"
                        label={t('profileMain.newPassword')}
                        validate={composeValidators(required, passwordValid)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="confirm_password"
                        component={materialTextField}
                        type="password"
                        label={t('profileMain.confirmPassword')}
                        validate={composeValidators(required, passwordValid, matchesOtherValue(getFieldState('new_password')?.value, t('profileMain.newPassword', {ns: 'profile'})))}
                    />
                </Col>
            </Row>
            <ButtonToolbar className="modal__footer">
                <Button className="modal_cancel" onClick={toggleModal}>{t('cancelBtn', {ns: 'common'})}</Button>{' '}
                <Button className="modal_ok" color="primary" type="submit">{t('buttons.save', {ns: 'forms'})}</Button>
            </ButtonToolbar>
        </>
    );
};

ChangePasswordForm.propTypes = {
    toggleModal: PropTypes.func.isRequired
};

export default ChangePasswordForm;
