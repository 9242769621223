class RouteHelpers {
    static getCompanyRoute(companyId) {
        return `/companies/${companyId}`;
    }

    static getContactRoute(contactId) {
        return `/contacts/${contactId}`;
    }

    static getMeetingRoute(meetingId) {
        return `/meetings/${meetingId}`;
    }

    static getMeetingRegistrationRoute(registrationId) {
        return `/meetings/registration/${registrationId}`;
    }

    static getNewMeetingRoute() {
        return '/meetings/new';
    }
}

export default RouteHelpers;
