import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import {Form} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import NewMeetingForm from './forms/NewMeetingForm';
import MeetingHelpers from '../../helpers/meetings/MeetingHelpers';
import Alert from '../../shared/components/alert/Alert';
import {ALERT_TYPE} from '../../helpers/constants';
import RouteHelpers from '../../helpers/RouteHelpers';

const NewMeeting = () => {
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('success');
    const {t} = useTranslation(['meetings', 'common']);
    const history = useHistory();

    const hasPermissionCreateMeetings = PermissionHelpers.hasPermission(PERMISSIONS.create_and_update_meeting);

    const showErrorAlert = (text) => {
        setAlertType(ALERT_TYPE.danger);
        setAlertText(text);
    };

    const onSubmit = async (data) => {
        try {
            const createdMeeting = await MeetingHelpers.createMeeting(
                data.title,
                data.startDate,
                data.startTime,
                data.deliveryType,
                !data.paid
            );

            const meetingId = createdMeeting.id ?? '';

            // Redirect to meeting page
            history.push(RouteHelpers.getMeetingRoute(meetingId));
        } catch (e) {
            // Show error
            showErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    const initialValues = {
        paid: false
    };

    return (
        <>
            {hasPermissionCreateMeetings ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">
                                {t('newMeeting', {ns: 'meetings'})}
                            </h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    {alertText.length > 0 && (
                                        <Row>
                                            <Col xs={12} className="mb-4">
                                                <Alert color={alertType} className="mt-1">
                                                    <p>{alertText}</p>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}

                                    <Form onSubmit={onSubmit} initialValues={initialValues}>
                                        {({handleSubmit, dirty, form}) => (
                                            <form className="material-form material-form-flex" onSubmit={handleSubmit}>
                                                <NewMeetingForm dirty={dirty} form={form} />
                                            </form>
                                        )}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default NewMeeting;
