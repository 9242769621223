import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getRoles,
    getUserEmail, getUserId,
    getUserImage, getUserMobile,
    getUserName, getUserTitle
} from '../../../../redux/reducers/userReducer';
import UserHelpers from '../../../../helpers/users/UserHelpers';
import ContactProfile from '../../../Contact/components/ContactProfile';
import {CONTACT_PROFILE_TYPE} from '../../../../helpers/constants';
import ContactProfileRoles from '../../../Contact/components/ContactProfileRoles';

const ProfileMain = ({ roles, fullname, email, title, mobile, image, contactId }) => {
    const [passwordChangeError, setPasswordChangeError] = useState(false);
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);

    const changePassword = (values) => {
        const oldPassword = values.old_password ?? '';
        const newPassword = values.new_password ?? '';
        const confirmPassword = values.confirm_password ?? '';

        UserHelpers.changeUserPassword(oldPassword, newPassword, confirmPassword)
            .then((r) => {
                setPasswordChangeSuccess(true);
                setPasswordChangeError(false);
            })
            .catch((e) => {
                setPasswordChangeError(true);
                setPasswordChangeSuccess(false);
            });
    };

    return (
        <>
            <ContactProfile
                image={image}
                contactId={contactId}
                email={email}
                title={title}
                mobile={mobile}
                fullname={fullname}
                changePassword={changePassword}
                passwordChangeError={passwordChangeError}
                passwordChangeSuccess={passwordChangeSuccess}
                showChangePasswordButton
                profileType={CONTACT_PROFILE_TYPE.user}
            />
            <ContactProfileRoles roles={roles} />
        </>
    );
};

ProfileMain.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    fullname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        roles: getRoles(state),
        fullname: getUserName(state),
        email: getUserEmail(state),
        image: getUserImage(state),
        contactId: getUserId(state),
        title: getUserTitle(state),
        mobile: getUserMobile(state)
    };
}

export default connect(
    mapStateToProps
)(ProfileMain);
