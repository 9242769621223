import {
    CHANGE_THEME_TO_LIGHT,
    CHANGE_THEME_TO_DARK
} from '../actionTypes';

const initialState = {
  className: 'theme-light',
};

export default function themeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_THEME_TO_LIGHT: {
            return { className: 'theme-light' };
        }
        case CHANGE_THEME_TO_DARK: {
            return { className: 'theme-dark' };
        }
        default:
            return state;
    }
}

export const getTheme = state => state.themeReducer;
