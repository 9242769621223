import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Col, Row,
} from 'reactstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import { connect } from 'react-redux';
import CommonHelpers from '../../../helpers/CommonHelpers';
import DataReactTable from '../../Common/DataReactTable';
import UsersTableFilters from './UsersTableFilters';
import { getUserEmail } from '../../../redux/reducers/userReducer';

const UsersTable = ({ openUserForm, openDeleteUserModal, accounts, loggedEmail }) => {
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [selectedRoleType, setSelectedRoleType] = useState(0);
    const [selectedRoleName, setSelectedRoleName] = useState('');

    const { t } = useTranslation(['common', 'settings']);

    const SEARCH_FOR_ROLE_NAME = 0;
    const SEARCH_FOR_ROLE_TYPE = 1;

    const createTableData = (m) => {
        const columns = [
            {
                Header: '#',
                accessor: 'idx',
                disableGlobalFilter: true
            },
            {
                Header: t('firstName'),
                accessor: 'first_name',
            },
            {
                Header: t('lastName'),
                accessor: 'last_name',
                disableGlobalFilter: true,
            },
            {
                Header: t('email'),
                accessor: 'email',
                disableGlobalFilter: true,
            },
            {
                Header: t('company'),
                accessor: 'company_name',
                disableGlobalFilter: true,
            },
            {
                Header: t('role'),
                accessor: 'roleList',
                disableGlobalFilter: true,
            },
            {
                Header: '',
                accessor: 'editOrDelete',
                disableGlobalFilter: true,
                Cell: ({ row }) => (
                    <>
                        <button
                            type="button"
                            onClick={() => sendUserDataToForm(row.values)}
                            className="table__button table__button"
                        >
                            <span className="table__button-icon lnr lnr-pencil" />
                        </button>
                        {row.values.email !== loggedEmail && (
                            <button
                                type="button"
                                onClick={() => openDeleteUserModal(row.values.email)}
                                className="table__button table__button"
                            >
                                <span className="table__button-icon lnr lnr-trash" />
                            </button>
                        )}
                    </>
                )
            }
        ];



        m.map((item, index) => {
            // role(s)
            const roles = _.map(item.roles, r => `${CommonHelpers.getRoleNameText(r.role_name)}(${CommonHelpers.getRoleTypeText(r.role_type)})`);
            item.roleList = _.join(roles, ', ');

            // index
            item.idx = index + 1;

            return item;
        });


        return { tableHeaderData: columns, tableRowsData: m };
    };

    const sendUserDataToForm = (rowValues) => {
        const email = rowValues.email;
        const rowAccount = _.find(accounts, { email });
        rowValues.roles = rowAccount.roles;
        openUserForm(rowValues);
    };

    const filterRole = (item, value, searchKey) => {
        const roles = item.roles;

        if (searchKey === SEARCH_FOR_ROLE_NAME) {
            return roles.some(role => role.role_name === value);
        }
        return roles.some(role => role.role_type === value);
    };

    const applyFilters = () => {
        let result = accounts;

        if (Number(selectedRoleType) > 0) {
            result = _.filter(result, e => filterRole(e, selectedRoleType, SEARCH_FOR_ROLE_TYPE));
        }

        if (selectedRoleName !== '') {
            result = _.filter(result, e => filterRole(e, selectedRoleName, SEARCH_FOR_ROLE_NAME));
        }

        setFilteredAccounts(result);
    };

    const filterByRoleName = (roleName) => {
        setSelectedRoleName(roleName);
    };

    const filterByRoleType = (roleType) => {
        setSelectedRoleType(roleType);
    };

    useEffect(() => {
        // Set filtered accounts
        setFilteredAccounts(accounts);
    }, [accounts]);

    useEffect(() => {
        // update table when filters change
        applyFilters();
    }, [selectedRoleType, selectedRoleName]);

    return (
        <Col>
            {(accounts.length > 0) && (
                <>
                    <Row>
                        <Col>
                            <UsersTableFilters
                                accounts={accounts}
                                filterByRoleType={filterByRoleType}
                                filterByRoleName={filterByRoleName}
                            />
                        </Col>
                        <Col className="mb-4 mt-4">
                            <Button
                                className="icon float-right"
                                color="success"
                                onClick={() => openUserForm({})}
                            >
                                <p><AccountPlusIcon /> {t('addAccount', { ns: 'settings' })}</p>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataReactTable
                                reactTableData={createTableData(filteredAccounts)}
                                searchEngineOn={false}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    );
};

UsersTable.propTypes = {
    openUserForm: PropTypes.func.isRequired,
    openDeleteUserModal: PropTypes.func.isRequired,
    row: PropTypes.shape({
        values: PropTypes.shape({
            email: PropTypes.string
        })
    }),
    accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    loggedEmail: PropTypes.string.isRequired
};

UsersTable.defaultProps = {
    row: {
        values: {
            email: ''
        }
    }
};

function mapStateToProps(state) {
    return {
        loggedEmail: getUserEmail(state)
    };
}

export default connect(
    mapStateToProps,
    null
)(UsersTable);
