import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ChangeImageButton from '../../Common/ChangeImageButton';
import {CLOUDINARY_TARGETS} from '../../../helpers/Cloudinary/CloudinaryConstants';
import ContactHelpers from '../../../helpers/Contacts/ContactHelpers';

const ChangeContactImageButton = ({ contactId, setError, changeImage }) => {
    const {t} = useTranslation('profile');

    const saveNewImage = async (imageName) => {
        try {
            const updated = await ContactHelpers.updateContactImage(contactId, imageName);
            changeImage(imageName);
            setError('');
        } catch (e) {
            setError('profileMain.imageUploadFailed');
        }
    };

    return (
        <ChangeImageButton
            setError={setError}
            changeImage={saveNewImage}
            targetType={CLOUDINARY_TARGETS.contact}
        />
    );
};

ChangeContactImageButton.propTypes = {
    contactId: PropTypes.number.isRequired,
    setError: PropTypes.func.isRequired,
    changeImage: PropTypes.func.isRequired
};

export default ChangeContactImageButton;
