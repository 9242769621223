import React, {useState} from 'react';
import {
    Col, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import DataReactTable from '../../Common/DataReactTable';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';
import CompanyDomainModal from './modals/CompanyDomainModal';
import NewDomainModal from './modals/NewDomainModal';
import Modal from '../../../shared/components/Modal';
import DomainHelpers from '../../../helpers/Company/DomainHelpers';

const CompanyDomains = ({companyId, domains, setUpdatedDomain, removeDomain}) => {
    const { t } = useTranslation(['companies', 'common']);
    const hasPermissionEditCompanies = PermissionHelpers.hasPermission(PERMISSIONS.manage_companies);
    const [selectedForEdit, setSelectedForEdit] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalError, setDeleteModalError] = useState('');
    const orderedDomains = _.orderBy(domains, ['domain'], ['asc']);

    // Edit modal
    const showEditDomainForm = (row) => {
        setSelectedForEdit({
            domain: row.original.domain,
            id: row.original.id
        });
        toggleEditModal();
    };

    const toggleEditModal = () => {
        setShowEditModal(prevShowEditModal => !prevShowEditModal);
    };

    // Delete modal
    const showDeleteDomainModal = (row) => {
        setSelectedForDelete({
            domain: row.original.domain,
            id: row.original.id
        });
        toggleDeleteModal();
    };

    const toggleDeleteModal = () => {
        setDeleteModalError('');
        setShowDeleteModal(prevShowDeleteModal => !prevShowDeleteModal);
    };

    const deleteDomain = async () => {
        try {
            const deleted = await DomainHelpers.deleteDomain(selectedForDelete.id);
            removeDomain(selectedForDelete.id);
            toggleDeleteModal();
        } catch (e) {
            setDeleteModalError(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };


    const columns = React.useMemo(() => [
        {
            Header: '#',
            disableGlobalFilter: true,
            Cell: ({ row }) => (
                <span>{row.index + 1}</span>
            )
        },
        {
            Header: t('domain', { ns: 'common' }),
            accessor: 'domain',
            disableGlobalFilter: true
        },
        {
            Header: '',
            id: 'editOrDelete',
            disableGlobalFilter: true,
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    {hasPermissionEditCompanies && (
                        <>
                            <button
                                type="button"
                                onClick={() => showEditDomainForm(row)}
                                className="table__button table__button"
                            >
                                <span className="table__button-icon lnr lnr-pencil" />
                            </button>
                            {!row.original.primary && (
                                <button
                                    type="button"
                                    onClick={() => showDeleteDomainModal(row)}
                                    className="table__button table__button"
                                >
                                    <span className="table__button-icon lnr lnr-trash" />
                                </button>
                            )}
                        </>
                    )}
                </>
            ),
        },
    ], []);

    const createTableData = (data) => {
        return { tableHeaderData: columns, tableRowsData: data };
    };

    /**
     * Primary domain with bold text
     * @param row
     * @returns {{style: {fontWeight: (string)}}}
     */
    const getTableRowProps = ({ row }) => {
        return {
            style: {
                fontWeight: row.original.primary ? 'bold' : '',
            },
        };
    };

    return (
        <>
            <Row>
                <Col>
                    <NewDomainModal companyId={companyId} setUpdatedDomain={setUpdatedDomain} />

                    <Modal
                        show={showDeleteModal}
                        color="danger"
                        message={t('modals.deleteDomainConfirmation', { ns: 'companies', domain: selectedForDelete.domain })}
                        toggleModal={toggleDeleteModal}
                        okBtn={t('buttons.delete', { ns: 'common' })}
                        handleOk={deleteDomain}
                        alertDangerText={deleteModalError}
                    />

                    <CompanyDomainModal
                        domain={selectedForEdit}
                        toggleModal={toggleEditModal}
                        showModal={showEditModal}
                        setUpdatedDomain={setUpdatedDomain}
                    />
                </Col>
            </Row>

            {(orderedDomains.length > 0) && (
                <Row>
                    <Col>
                        <DataReactTable
                            reactTableData={createTableData(orderedDomains)}
                            searchEngineOn={false}
                            getTableRowProps={getTableRowProps}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

CompanyDomains.propTypes = {
    companyId: PropTypes.number.isRequired,
    domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    removeDomain: PropTypes.func.isRequired,
    row: PropTypes.shape({
        index: PropTypes.string,
        original: PropTypes.shape({
            id: PropTypes.number,
            domain: PropTypes.string,
            primary: PropTypes.bool
        }),
    }),
    setUpdatedDomain: PropTypes.func.isRequired
};

CompanyDomains.defaultProps = {
    row: {
        index: '',
        original: {
            id: '',
            domain: '',
            primary: false
        }
    }
};

export default CompanyDomains;
