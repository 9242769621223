import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import InvoiceAddressHelpers from '../../../../helpers/Order/InvoiceAddressHelpers';
import InvoiceAddressModal from './InvoiceAddressModal';

const EditInvoiceAddressModal = ({
                                     invoiceAddress,
                                     setUpdatedInvoiceAddress,
                                     toggleModal,
                                     showModal,
                                     companyNames
                                 }) => {
    const [errorAlert, setErrorAlert] = useState('');
    const {t} = useTranslation(['order']);

    const handleSave = async (data) => {
        try {
            const updatedAddress = await InvoiceAddressHelpers.updateInvoiceAddress(
                invoiceAddress.id,
                data.address,
                data.city,
                data.invoiceCompany,
                data.country,
                data.eInvoiceAddress,
                data.eInvoiceCode,
                data.eInvoiceEmail,
                data.eInvoiceOperator,
                data.identifier,
                data.info,
                data.postalCode
            );

            setUpdatedInvoiceAddress(updatedAddress);
            toggleModal();
        } catch (e) {
            setErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    return (
        <>
            <InvoiceAddressModal
                companyNames={companyNames}
                invoiceAddress={invoiceAddress}
                toggleModal={toggleModal}
                showModal={showModal}
                saveData={handleSave}
                companySelectorDisabled
                alertText={errorAlert}
            />
        </>
    );
};

EditInvoiceAddressModal.propTypes = {
    companyNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    invoiceAddress: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setUpdatedInvoiceAddress: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default EditInvoiceAddressModal;
