import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
// import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const DataReactTable = ({ reactTableData, searchEngineOn, renderRowSubComponent, pageSize, fetchData, manualPageOptions, defaultPageSize, getTableRowProps }) => {
    const [rows, setData] = useState(reactTableData.tableRowsData);
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [isSortable, setIsSortable] = useState(true);
    const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
    const [isDisabledEditable, setIsDisabledEditable] = useState(false);
    const [isDisabledResizable, setIsDisabledResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(searchEngineOn);

    useEffect(() => {
        setData(reactTableData.tableRowsData);
    }, [reactTableData]);

    const handleClickIsEditable = () => {
        if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setIsEditable(!isEditable);
    };
    const handleClickIsResizable = () => {
        setIsEditable(false);
        setWithDragAndDrop(false);
        setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
        setIsDisabledEditable(!isDisabledEditable);
        setIsResizable(!isResizable);
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };
    const handleClickWithDragAndDrop = () => {
        if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setWithDragAndDrop(!withDragAndDrop);
    };
    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setData(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setData(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isEditable,
        isResizable,
        isSortable,
        withDragAndDrop,
        withPagination,
        withSearchEngine,
        manualPageSize: pageSize ?? [10, 20, 30, 40],
        placeholder: 'Search by First name...',
    };

    return (
        <>
                    <div className="react-table__wrapper">
                        {/* <ReactTableCustomizer
                            handleClickIsEditable={handleClickIsEditable}
                            handleClickIsResizable={handleClickIsResizable}
                            handleClickIsSortable={handleClickIsSortable}
                            handleClickWithDragAndDrop={handleClickWithDragAndDrop}
                            handleClickWithPagination={handleClickWithPagination}
                            handleClickWithSearchEngine={handleClickWithSearchEngine}
                            isEditable={isEditable}
                            isResizable={isResizable}
                            isSortable={isSortable}
                            isDisabledDragAndDrop={isDisabledDragAndDrop}
                            isDisabledEditable={isDisabledEditable}
                            isDisabledResizable={isDisabledResizable}
                            withDragAndDrop={withDragAndDrop}
                            withPagination={withPagination}
                            withSearchEngine={withSearchEngine}
                            fullCustomizer
                        /> */}
                    </div>
                    <ReactTableBase
                        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                        columns={reactTableData.tableHeaderData}
                        data={rows}
                        updateEditableData={updateEditableData}
                        updateDraggableData={updateDraggableData}
                        tableConfig={tableConfig}
                        renderRowSubComponent={renderRowSubComponent}
                        fetchData={fetchData}
                        manualPageOptions={manualPageOptions}
                        defaultPageSize={defaultPageSize}
                        getTableRowProps={getTableRowProps}
                    />
        </>
    );
};

DataReactTable.propTypes = {
    defaultPageSize: PropTypes.number,
    fetchData: PropTypes.func,
    getTableRowProps: PropTypes.func,
    manualPageOptions: PropTypes.arrayOf(PropTypes.number),
    pageSize: PropTypes.arrayOf(PropTypes.shape()),
    reactTableData: PropTypes.shape({
        tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })),
        tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    renderRowSubComponent: PropTypes.func,
    searchEngineOn: PropTypes.bool.isRequired
};

DataReactTable.defaultProps = {
    defaultPageSize: '',
    fetchData: () => {},
    getTableRowProps: () => {},
    manualPageOptions: [],
    pageSize: [10, 20, 30, 40],
    renderRowSubComponent: () => {},
};

export default DataReactTable;
