import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import TopbarMenuLink from './TopbarMenuLink';
import { getUserImage, getUserName } from '../../../redux/reducers/userReducer';
import CloudinaryHelpers from '../../../helpers/Cloudinary/CloudinaryHelpers';
import { CLOUDINARY_TARGETS } from '../../../helpers/Cloudinary/CloudinaryConstants';
import UserHelpers from '../../../helpers/users/UserHelpers';

const defaultImage = 'default_profile_picture_dl18d6';

const TopbarProfile = ({ userName, userImage }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { t } = useTranslation('common');


    const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    };

    const logOut = () => {
      UserHelpers.logout();
    };

    return (
        <div className="topbar__profile">
            <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
                <CloudinaryImage
                    className="topbar__avatar-img"
                    publicId={CloudinaryHelpers.getPublicId(CLOUDINARY_TARGETS.contact, userImage ? userImage : defaultImage)}
                    alt="avatar"
                >
                    <Transformation width="100" height="100" crop="thumb" radius="max" />
                </CloudinaryImage>
                <p className="topbar__avatar-name">{userName !== '' ? userName : 'Nameless Person'}</p>
                <DownIcon className="topbar__icon" />
            </button>
            {isCollapsed && (
            <button
                type="button"
                aria-label="button collapse"
                className="topbar__back"
                onClick={handleToggleCollapse}
            />
        )}
          <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
              <div className="topbar__menu">
                  {/* <div className="topbar__menu-divider" /> */}
                  <TopbarMenuLink title={t('topBar.profile')} icon="user" path="/user/profile" onClick={handleToggleCollapse} />
                  <TopbarMenuLink title={t('topBar.logOut')} icon="exit" path="/" onClick={logOut} />
              </div>
          </Collapse>
        </div>
    );
};

TopbarProfile.propTypes = {
    userName: PropTypes.string.isRequired,
    userImage: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        userName: getUserName(state),
        userImage: getUserImage(state)
    };
}

export default withRouter(connect(
    mapStateToProps,
    null
)(TopbarProfile));
