import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { emailValid, required } from '../../../../helpers/forms/validation';
import { getUserEmail, getUserFirstName, getUserLastName } from '../../../../redux/reducers/userReducer';

const renderTextField = ({
                             input,
                             label,
                             meta: {
                                 touched,
                                 error
                             }
                         }) => (
    <TextField
        className={classNames(
            'material-form__field',
            { 'material-form__field__error': touched && error }
        )}
        label={label}
        error={touched && !!error}
        helperText={touched ? error : null}
        value={input.value}
        onFocus={e => input.onFocus(e)}
        onBlur={e => input.onBlur(e)}
        onChange={(e) => {
            e.preventDefault();
            input.onChange(e.target.value);
        }}
    />
);

renderTextField.propTypes = {
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderTextField.defaultProps = {
    meta: null,
    label: '',
};

const ProfileSettings = ({ handleSaveUser, firstname, lastname, email }) => {
    const { t } = useTranslation('profile');

    const initialValues = {
        firstname,
        lastname,
        email
    };

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const onSubmit = (values) => {
        const newFirstName = values.firstname;
        const newLastName = values.lastname;
        const newEmail = values.email;

        handleSaveUser(newFirstName, newLastName, newEmail, email);
    };

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({
                  handleSubmit,
                  form,
                  dirty
              }) => (
                <form className="material-form" onSubmit={handleSubmit}>
                    <div>
                        <span className="material-form__label">{t('profileEdit.firstName')}</span>
                        <Field
                            name="firstname"
                            component={renderTextField}
                            validate={required}
                        />
                    </div>
                    <div>
                        <span className="material-form__label">{t('profileEdit.lastName')}</span>
                        <Field
                            name="lastname"
                            component={renderTextField}
                            validate={required}
                        />
                    </div>
                    <div>
                        <span className="material-form__label">{t('profileEdit.email')}</span>
                        <Field
                            name="email"
                            component={renderTextField}
                            type="email"
                            validate={composeValidators(required, emailValid)}
                        />
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">{t('profileEdit.updateProfile')}</Button>
                        {dirty && (
                            <Button type="button" onClick={form.reset} disabled={form.pristine || form.submitting}>
                                {t('profileEdit.cancel')}
                            </Button>
                        )}
                    </ButtonToolbar>
                </form>
            )}
        </Form>
    );
};

ProfileSettings.propTypes = {
    handleSaveUser: PropTypes.func.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        firstname: getUserFirstName(state),
        lastname: getUserLastName(state),
        email: getUserEmail(state)
    };
}

export default connect(
    mapStateToProps
)(ProfileSettings);
