import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const materialTextField = ({
    input, label, meta: { touched, error }, children, select, multiline, rows, maxRows, disabled
}) => (
    <TextField
        className={classNames(
            'material-form__field',
            { 'material-form__field__error': touched && error}
            )}
        label={label}
        type={input.type}
        error={touched && error}
        helperText={touched ? error : ' '}
        value={input.value}
        select={select}
        multiline={multiline}
        onFocus={e => input.onFocus(e)}
        onBlur={e => input.onBlur(e)}
        onChange={(e) => {
            e.preventDefault();
            input.onChange(e.target.value);
        }}
        rows={rows}
        maxRows={maxRows}
        disabled={disabled}
    >
        {children}
    </TextField>
);

materialTextField.propTypes = {
    input: PropTypes.shape().isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    select: PropTypes.bool,
    children: PropTypes.node,
    multiline: PropTypes.bool,
};

materialTextField.defaultProps = {
    label: '',
    meta: null,
    select: false,
    children: [],
    multiline: false,
};

export default materialTextField;
