import { Modal } from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { getDirection } from '../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import EditProductPackageForm from '../forms/EditProductPackageForm';
import ProductPackageHelpers from '../../../helpers/ProductPackages/ProductPackageHelpers';

const CopyProductPackageModal = ({ rtl, productPackage, addNewPackage, targetId, targetType }) => {
    const [show, setShow] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['common', 'productPackages']);

    const toggleModal = () => {
        setErrorAlert('');
        setShow(prevShow => !prevShow);
    };

    const handleSave = async (data) => {
        try {
             const createdPackage = await ProductPackageHelpers.createProductPackage(
                targetId,
                targetType,
                data.title,
                data.description,
                data.active,
                data.products
            );

            addNewPackage(createdPackage);
        } catch (e) {
            setErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    const getInitialProducts = (pp) => {
        let products = [];

        if (pp.products) {
            products = pp.products.map((p) => {
                return {
                    'code': p.code ?? '',
                    'title': p.title ?? '',
                    'price': p.price ?? '',
                    'quantity': p.quantity ?? '',
                };
            });
        }

        return products;
    };

    const initialPackageValues = {
        active: Number(productPackage.active) === 1,
        title: productPackage.title ?? '',
        description: productPackage.description ?? '',
        products: getInitialProducts(productPackage)
    };

    return (
        <>
            <button
                type="button"
                onClick={toggleModal}
                className="table__button table__button"
            >
                <span className="table__button-icon lnr lnr-file-add" />
            </button>

            <Modal
                isOpen={show}
                toggle={toggleModal}
                size="lg"
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleModal}
                    />
                    <h4 className="text-modal  modal__title">
                        {t('copyProductPackage', {ns: 'productPackages'})}
                    </h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave} initialValues={initialPackageValues} mutators={{...arrayMutators}} keepDirtyOnReinitialize>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <EditProductPackageForm
                                    toggleModal={toggleModal}
                                    errorAlertText={errorAlert}
                                    submitButtonText={t('buttons.copy', {ns: 'forms'})}
                                />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

CopyProductPackageModal.propTypes = {
    addNewPackage: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    productPackage: PropTypes.oneOfType([PropTypes.object]).isRequired,
    targetId: PropTypes.number.isRequired,
    targetType: PropTypes.string.isRequired
};

export default connect(state => ({
    rtl: getDirection(state),
}))(CopyProductPackageModal);
