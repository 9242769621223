import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import CodeError from '../../shared/components/error/CodeError';

class InvoiceAddressHelpers {
    static async createInvoiceAddress(nameId, contactId, address, city, invoiceCompany, country, eInvoiceAddress, eInvoiceCode,
                                      eInvoiceEmail, eInvoiceOperator, identifier, info, postalCode) {
        try {
            const addresses = await axiosWithPersonalToken.post('invoice-address', {
                nameId,
                contactId,
                address,
                city,
                invoiceCompany,
                country,
                eInvoiceAddress,
                eInvoiceCode,
                eInvoiceEmail,
                eInvoiceOperator,
                identifier,
                info,
                postalCode
            });
            return addresses.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async getCompanyInvoiceAddresses(companyId) {
        try {
            const addresses = await axiosWithPersonalToken.get(`companies/${companyId}/invoice-addresses`, {});
            return addresses.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async updateInvoiceAddress(id, address, city, invoiceCompany, country, eInvoiceAddress, eInvoiceCode,
                                      eInvoiceEmail, eInvoiceOperator, identifier, info, postalCode) {
        try {
            const addresses = await axiosWithPersonalToken.put(`invoice-address/${id}`, {
                address,
                city,
                invoiceCompany,
                country,
                eInvoiceAddress,
                eInvoiceCode,
                eInvoiceEmail,
                eInvoiceOperator,
                identifier,
                info,
                postalCode
            });
            return addresses.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async deleteInvoiceAddress(id) {
        try {
            const deleted = await axiosWithPersonalToken.delete(`invoice-address/${id}`, {});
            return deleted.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static resolveInvoiceCompanyName(invoiceAddress) {
        if (!invoiceAddress) return '';

        if (invoiceAddress.invoiceCompany && invoiceAddress.invoiceCompany.length > 0) {
            return invoiceAddress.invoiceCompany;
        }

        if (invoiceAddress.company && invoiceAddress.company.name) {
            return invoiceAddress.company.name;
        }

        return '';
    }

    static resolveBusinessId(invoiceAddress) {
        if (!invoiceAddress) return '';

        if (invoiceAddress.businessId && invoiceAddress.businessId.length > 0) {
            return invoiceAddress.businessId;
        }

        if (invoiceAddress.company && invoiceAddress.company.businessId) {
            return invoiceAddress.company.businessId;
        }

        return '';
    }
}

export default InvoiceAddressHelpers;
