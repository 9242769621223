import React from 'react';
import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';

const Dashboard = () => (
    <Container className="dashboard">
        <Row>
            <Col md={12}>
                <h3 className="page-title">Dashboard</h3>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Tervetuloa!</h5>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default Dashboard;
