import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import CodeError from '../../shared/components/error/CodeError';

class ContactHelpers {
    static async getContact(id) {
        try {
            const contact = await axiosWithPersonalToken.get(`contacts/${id}`, {});
            return contact.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async getContactRoles(id) {
        try {
            const roles = await axiosWithPersonalToken.get(`contacts/${id}/roles`, {});
            return roles.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async getContacts(limit, offset, searchKey) {
        try {
            const contacts = await axiosWithPersonalToken.get('contacts', {
                params: {
                    limit,
                    offset,
                    searchKey
                }
            });
            return contacts.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async updateContactImage(contactId, imageName) {
        try {
            const contact = await axiosWithPersonalToken.post(`contacts/${contactId}/image`, {
                image: imageName
            });
            return contact.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }
}

export default ContactHelpers;
