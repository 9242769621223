import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThemeProps, RTLProps } from '@/shared/prop-types/ReducerProps';
import { getTheme } from '../../redux/reducers/themeReducer';
import { getDirection } from '../../redux/reducers/rtlReducer';

const MainWrapper = ({
  theme, children, rtl, location,
}) => {
    const direction = location.pathname === '/' ? 'ltr' : rtl.direction;

    return (
        <div className={`${theme.className} ${direction}-support`} dir={direction}>
            <div className="wrapper">
                {children}
            </div>
        </div>
    );
};

MainWrapper.propTypes = {
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
    children: PropTypes.node.isRequired,
    location: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
    return {
        theme: getTheme(state),
        rtl: getDirection(state)
    };
}

export default withRouter(connect(
    mapStateToProps,
    null
)(MainWrapper));
