import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import { getDirection } from '../../redux/reducers/rtlReducer';
import Alert from './alert/Alert';

const ModalComponent = ({
    show, color, okBtn, cancelBtn, title, message, colored, header, rtl, toggleModal, handleOk, alertDangerText
}) => {
    const { t } = useTranslation('common');
    let Icon;
    const okBtnText = okBtn ?? t('okBtn');
    const cancelBtnText = cancelBtn ?? t('cancelBtn');

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    return (
        <div>
            <Modal
                isOpen={show}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleModal}
                    />
                    {header ? '' : Icon}
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div className="modal__body">
                    {message}
                </div>
                {alertDangerText.length > 0 && (
                    <div>
                        <Alert color="danger" className="mt-2">
                            {alertDangerText}
                        </Alert>
                    </div>
                )}
                <ButtonToolbar className="modal__footer">
                    <Button className="modal_cancel" onClick={toggleModal}>{cancelBtnText}</Button>{' '}
                    <Button className="modal_ok" outline={colored} color={color} onClick={handleOk}>{okBtnText}</Button>
                </ButtonToolbar>
            </Modal>
        </div>
    );
};

ModalComponent.propTypes = {
    alertDangerText: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    okBtn: PropTypes.string,
    cancelBtn: PropTypes.string,
    rtl: RTLProps.isRequired,
    toggleModal: PropTypes.func.isRequired,
    handleOk: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

ModalComponent.defaultProps = {
    alertDangerText: '',
    title: '',
    message: '',
    colored: false,
    header: false,
    okBtn: null,
    cancelBtn: null
};

export default connect(state => ({
    rtl: getDirection(state),
}))(ModalComponent);
