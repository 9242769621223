import React, {useState} from 'react';
import {
    Col, Row
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DataReactTable from '../../Common/DataReactTable';
import CompanyNameModal from './modals/CompanyNameModal';
import NewCompanyNameModal from './modals/NewCompanyNameModal';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';

const CompanyNames = ({names, setUpdatedName, companyId}) => {
    const { t } = useTranslation(['companies']);
    const orderedNames = _.orderBy(names, ['name'], ['asc']);
    const [selectedForEdit, setSelectedForEdit] = useState({});
    const [showModal, setShowModal] = useState(false);
    const hasPermissionEditCompanies = PermissionHelpers.hasPermission(PERMISSIONS.manage_companies);

    const showEditNameForm = (row) => {
        setSelectedForEdit({
            businessId: row.original.businessId,
            id: row.original.id,
            name: row.original.name
        });
        toggleEditModal();
    };

    const toggleEditModal = () => {
        setShowModal(prevShowModal => !prevShowModal);
    };

    const columns = React.useMemo(() => [
        {
            Header: '#',
            disableGlobalFilter: true,
            Cell: ({ row }) => (
                <span>{row.index + 1}</span>
            )
        },
        {
            Header: t('name', { ns: 'common' }),
            accessor: 'name',
            disableGlobalFilter: true
        },
        {
            Header: t('businessId', { ns: 'common' }),
            accessor: 'businessId',
            disableGlobalFilter: true
        },
        {
            Header: '',
            id: 'editOrDelete',
            disableGlobalFilter: true,
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    {hasPermissionEditCompanies && (
                        <button type="button" onClick={() => showEditNameForm(row)} className="table__button table__button">
                            <span className="table__button-icon lnr lnr-pencil" />
                        </button>
                    )}
                </>
            ),
        },
    ], []);


    const createTableData = (data) => {
        return { tableHeaderData: columns, tableRowsData: data };
    };

    /**
     * Primary name with bold text
     * @param row
     * @returns {{style: {fontWeight: (string)}}}
     */
    const getTableRowProps = ({ row }) => {
        return {
            style: {
                fontWeight: row.original.primary ? 'bold' : '',
            },
        };
    };

    return (
        <>
            <Row>
                <Col>
                    <NewCompanyNameModal companyId={companyId} setUpdatedName={setUpdatedName} />

                    <CompanyNameModal
                        companyName={selectedForEdit}
                        toggleModal={toggleEditModal}
                        showModal={showModal}
                        setUpdatedName={setUpdatedName}
                    />
                </Col>
            </Row>

            {(names.length > 0) && (
                <Row>
                    <Col>
                        <DataReactTable
                            reactTableData={createTableData(orderedNames)}
                            searchEngineOn={false}
                            getTableRowProps={getTableRowProps}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

CompanyNames.propTypes = {
    companyId: PropTypes.number.isRequired,
    names: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    row: PropTypes.shape({
        index: PropTypes.string,
        original: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            primary: PropTypes.bool
        }),
    }),
    setUpdatedName: PropTypes.func.isRequired
};

CompanyNames.defaultProps = {
    row: {
        index: '',
        original: {
            id: '',
            name: '',
            primary: false
        }
    }
};

export default CompanyNames;
