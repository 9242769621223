import React from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';

const AccessDenied = () => {
    const {t} = useTranslation('common');

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            {t('accessDenied')}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AccessDenied;
