import { Modal } from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { getDirection } from '../../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import EditInvoiceAddressForm from '../forms/EditInvoiceAddressForm';

const InvoiceAddressModal = ({ rtl, invoiceAddress, saveData, toggleModal, showModal,
                                 companyNames, companySelectorDisabled, alertText }) => {
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['order']);

    const toggleInvoiceAddressModal = () => {
        setErrorAlert('');
        toggleModal();
    };

    const handleSave = async (data) => {
        const oneFieldRequired = [
            'address', 'city', 'country', 'eInvoiceAddress', 'eInvoiceCode',
            'eInvoiceEmail', 'eInvoiceOperator', 'identifier', 'postalCode', 'invoiceCompany'
        ];

        let valueGiven = false;

        Object.entries(data).forEach(([key, value]) => {
            if (value.length > 0 && oneFieldRequired.includes(key)) {
                valueGiven = true;
            }
        });

        if (!valueGiven) {
            setErrorAlert(t('errors.atLeastOneFieldValueRequired', {ns: 'forms'}));
        } else {
            setErrorAlert('');
            saveData(data);
        }
    };

    const initialValues = {
        nameId: invoiceAddress.nameId ?? '',
        address: invoiceAddress.address ?? '',
        city: invoiceAddress.city ?? '',
        invoiceCompany: invoiceAddress.invoiceCompany ?? '',
        country: invoiceAddress.country ?? '',
        eInvoiceAddress: invoiceAddress.eInvoiceAddress ?? '',
        eInvoiceCode: invoiceAddress.eInvoiceCode ?? '',
        eInvoiceEmail: invoiceAddress.eInvoiceEmail ?? '',
        eInvoiceOperator: invoiceAddress.eInvoiceOperator ?? '',
        identifier: invoiceAddress.identifier ?? '',
        info: invoiceAddress.info ?? '',
        postalCode: invoiceAddress.postalCode ?? ''
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                toggle={toggleInvoiceAddressModal}
                size="lg"
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleInvoiceAddressModal}
                    />
                    <h4 className="text-modal  modal__title">
                        {t('invoiceInformation', {ns: 'order'})}
                    </h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave} initialValues={initialValues}>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <EditInvoiceAddressForm
                                    companyNames={companyNames}
                                    companySelectorDisabled={companySelectorDisabled}
                                    toggleModal={toggleInvoiceAddressModal}
                                    errorAlertText={errorAlert.length > 0 ? errorAlert : alertText}
                                    submitButtonText={t('buttons.save', {ns: 'forms'})}
                                />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

InvoiceAddressModal.propTypes = {
    alertText: PropTypes.string.isRequired,
    companyNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    companySelectorDisabled: PropTypes.bool.isRequired,
    rtl: RTLProps.isRequired,
    invoiceAddress: PropTypes.oneOfType([PropTypes.object]).isRequired,
    saveData: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default connect(state => ({
    rtl: getDirection(state),
}))(InvoiceAddressModal);
