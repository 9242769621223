import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import {
    Col, Container, Row
} from 'reactstrap';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import ContactProfile from './components/ContactProfile';
import ContactHelpers from '../../helpers/Contacts/ContactHelpers';
import ContactProfileCompany from './components/ContactProfileCompany';
import {CONTACT_PROFILE_TYPE} from '../../helpers/constants';
import ContactProfileRoles from './components/ContactProfileRoles';

const Contact = () => {
    const { t } = useTranslation(['common']);
    const { id } = useParams();
    const [contact, setContact] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [roles, setRoles] = useState([]);
    const hasPermissionShowContacts = PermissionHelpers.hasPermission(PERMISSIONS.show_contacts);

    async function getContact() {
        if (hasPermissionShowContacts) {
            try {
                const contactData = await ContactHelpers.getContact(id);
                setContact(contactData);
                setDataLoaded(true);
            } catch (e) {
                setDataLoaded(true);
            }
        }
    }

    async function getContactRoles() {
        if (hasPermissionShowContacts) {
            try {
                const rolesData = await ContactHelpers.getContactRoles(id);
                setRoles(rolesData);
            } catch (e) {
                //
            }
        }
    }

    useEffect(() => {
        getContact();
        getContactRoles();
    }, [hasPermissionShowContacts]);

    const changeContactImage = (imageName) => {
        const clonedContactData = _.cloneDeep(contact);
        if (clonedContactData) {
            clonedContactData.image = imageName;
        }
        setContact(clonedContactData);
    };

    return (
        <Container>
            <div className="profile">
                <Row>
                    {(dataLoaded && !contact.id) && (
                        <Col xs={12}>
                            {t('dataNotFound', {ns: 'common'})}
                        </Col>
                    )}

                    {(dataLoaded && contact.id) && (
                        <>
                            <Col md={12} lg={12} xl={4}>
                                <Row>
                                    <ContactProfile
                                        image={contact.image ?? ''}
                                        contactId={contact.id ?? ''}
                                        email={contact.email ?? ''}
                                        title={contact.title ?? ''}
                                        mobile={contact.mobile ?? ''}
                                        fullname={`${contact.firstName} ${contact.lastName}`}
                                        changePassword={() => {}}
                                        showChangePasswordButton={false}
                                        profileType={CONTACT_PROFILE_TYPE.contact}
                                        changeContactImage={changeContactImage}
                                    />
                                    <ContactProfileRoles roles={roles} />
                                    <ContactProfileCompany companyData={contact.company ?? []} />
                                </Row>
                            </Col>
                            {/* <ProfileTabs /> */}
                        </>
                    )}
                </Row>
            </div>
        </Container>
    );
};

export default Contact;
