import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import {ORDER_STATUS, PAYMENT_STATUS, PAYMENT_TYPE, TARGET_REGISTRATION_STATUS} from '../constants';
import i18n from '../../translations';

class OrderHelpers {
    static async getOrder(id) {
        try {
            const order = await axiosWithPersonalToken.get(`orders/${id}`, {});
            return order.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static getOrderPaymentStatusText(statusCode) {
        if (statusCode === PAYMENT_STATUS.paid) {
            return i18n.t('paymentStatusCode.paid', { ns: 'order' });
        }
        if (statusCode === PAYMENT_STATUS.open) {
            return i18n.t('paymentStatusCode.open', { ns: 'order' });
        }
        if (statusCode === PAYMENT_STATUS.charged) {
            return i18n.t('paymentStatusCode.charged', { ns: 'order' });
        }
        if (statusCode === PAYMENT_STATUS.manual) {
            return i18n.t('paymentStatusCode.manual', { ns: 'order' });
        }

        return '';
    }

    static getOrderPaymentStatusClass(statusCode) {
        if (!statusCode) return '';

        statusCode = statusCode.toUpperCase();

        if (statusCode === PAYMENT_STATUS.paid || statusCode === PAYMENT_STATUS.charged) {
            return 'text-green';
        }
        if (statusCode === PAYMENT_STATUS.manual) {
            return 'text-yellow';
        }
        if (statusCode === PAYMENT_STATUS.open) {
            return 'text-red';
        }
        return '';
    }

    static getOrderPaymentTypeText(statusCode) {
        if (statusCode === PAYMENT_TYPE.creditCard) {
            return i18n.t('paymentTypeCode.creditCard', { ns: 'order' });
        }
        if (statusCode === PAYMENT_TYPE.invoice) {
            return i18n.t('paymentTypeCode.invoice', { ns: 'order' });
        }

        return '';
    }


    static getOrderStatusClass(statusCode) {
        if (!statusCode) return '';

        statusCode = statusCode.toUpperCase();

        if (statusCode === ORDER_STATUS.active) {
            return 'text-green';
        }
        if (statusCode === ORDER_STATUS.inProgress) {
            return 'text-yellow';
        }
        if (statusCode === ORDER_STATUS.cancelled) {
            return 'text-red';
        }
        return '';
    }

    static getOrderStatusText(statusCode) {
        if (statusCode === ORDER_STATUS.active) {
            return i18n.t('statusCode.active', { ns: 'common' });
        }
        if (statusCode === ORDER_STATUS.cancelled) {
            return i18n.t('statusCode.cancelled', { ns: 'common' });
        }
        if (statusCode === ORDER_STATUS.inProgress) {
            return i18n.t('statusCode.inProgress', { ns: 'common' });
        }

        return '';
    }
}

export default OrderHelpers;
