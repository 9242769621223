import React, {useEffect, useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../Layout';
import MainWrapper from '../MainWrapper';
import LogIn from '../../LogIn';
import Dashboard from '../../Dashboard';
import Meetings from '../../Meetings';
import Settings from './Settings';
import PrivateRoute from './PrivateRoute';
import Meeting from '../../Meetings/Meeting';
import AccessToken from '../../../helpers/AccessToken';
import store from '../store';
import {logInUser} from '../../../redux/actions/userActions';
import NewMeeting from '../../Meetings/NewMeeting';
import Profile from '../../User/Profile';
import UserHelpers from '../../../helpers/users/UserHelpers';
import SetPassword from '../../User/SetPassword';
import MeetingRegistration from '../../Meetings/MeetingRegistration';
import Contacts from '../../Contacts';
import Companies from '../../Companies';
import CompanyProfile from '../../Company';
import Contact from '../../Contact';

const WrappedRoutes = () => {
    const [sessionChecked, setSessionChecked] = useState(false);

    const checkSessionLogin = async () => {
        const accessToken = AccessToken.getAccessToken();

        if (accessToken.length > 0) {
            try {
                const contactData = await UserHelpers.getUser();

                if (contactData.email) {
                    store.dispatch(logInUser(
                        contactData.contact,
                        contactData.email,
                        contactData.firstname,
                        contactData.lastname,
                        accessToken,
                        contactData.permissions,
                        contactData.roles,
                        contactData.contactImage,
                        contactData.title,
                        contactData.mobile
                    ));
                }

                setSessionChecked(true);
            } catch (e) {
                setSessionChecked(true);
            }
        }
    };

    useEffect(async () => {
        await checkSessionLogin();
    }, []);

    return (
        <>
            {sessionChecked ? (
                <div>
                    <Layout />
                    <div className="container__wrap">
                        <Switch>
                            {/* } <Route path="/pages" component={Pages} /> */}
                            <Route exact path="/contacts" component={Contacts} />
                            <Route exact path="/contacts/:id" component={Contact} />
                            <Route exact path="/companies" component={Companies} />
                            <Route exact path="/companies/:id" component={CompanyProfile} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route exact path="/meetings" component={Meetings} />
                            <Route exact path="/meetings/new" component={NewMeeting} />
                            <Route exact path="/meetings/:id" component={Meeting} />
                            <Route exact path="/meetings/registration/:id" component={MeetingRegistration} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/user/profile" component={Profile} />
                        </Switch>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
        );
};

/* const Pages = () => (
    <Switch>
        <Route path="/pages/one" component={ExamplePageOne} />
        <Route path="/pages/two" component={ExamplePageTwo} />
    </Switch>
); */

const Router = () => (
    <MainWrapper>
        <main>
            <Switch>
                <Route exact path="/" component={LogIn} />
                <Route path="/user/setPassword" component={SetPassword} />
                {/* <Route exact path="/log_in" component={LogIn} /> */}
                <PrivateRoute path="/" component={WrappedRoutes} />
            </Switch>
        </main>
    </MainWrapper>
);

export default Router;
