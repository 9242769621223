import React, { useState } from 'react';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom';
import MeetingsTable from './MeetingsTable';
import { TARGET_SEARCH_PARAMS } from '../../helpers/constants';
import RouteHelpers from '../../helpers/RouteHelpers';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../helpers/roles/Permissions';

const MeetingsTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation('common');
  const history = useHistory();
  const hasPermissionCreateMeetings = PermissionHelpers.hasPermission(PERMISSIONS.create_and_update_meeting);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            {t('upcoming')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            {t('past')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            {t('cancelledPlural')}
          </NavLink>
        </NavItem>

        {hasPermissionCreateMeetings && (
            <NavItem className="tabs-nav-button">
              <Button
                  className="icon float-right"
                  color="success"
                  onClick={() => history.push(RouteHelpers.getNewMeetingRoute())}
              >
                <p>{t('addMeeting', { ns: 'meetings' })}</p>
              </Button>
            </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <MeetingsTable tableType={TARGET_SEARCH_PARAMS.upcoming} />
        </TabPane>
        <TabPane tabId="2">
          <MeetingsTable tableType={TARGET_SEARCH_PARAMS.past} />
        </TabPane>
        <TabPane tabId="3">
          <MeetingsTable tableType={TARGET_SEARCH_PARAMS.cancelled} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default MeetingsTabs;
