import React from 'react';
import {
    Card, CardBody, Col
} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import GlobeIcon from 'mdi-react/GlobeIcon';
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import CloudinaryHelpers from '../../../helpers/Cloudinary/CloudinaryHelpers';
import { CLOUDINARY_TARGETS } from '../../../helpers/Cloudinary/CloudinaryConstants';

const defaultImage = 'company';

const ContactProfileCompany = ({companyData}) => {
    const {t} = useTranslation('profile');

    return (
        <Col md={12} lg={12} xl={12}>
                <Card>
                    <CardBody className="profile__card">
                        {companyData.name ? (
                            <div className="profile__information">
                                <div className="profile__company-logo">
                                    <CloudinaryImage
                                        publicId={CloudinaryHelpers.getPublicId(CLOUDINARY_TARGETS.company, companyData.logo ? companyData.logo : defaultImage)}
                                        alt="avatar"
                                    >
                                        <Transformation width="150" height="150" />
                                    </CloudinaryImage>
                                </div>
                                <div className="profile__data">
                                    <p className="profile__name">{companyData.name}</p>
                                    {companyData.businessId && (
                                        <p className="profile__contact">
                                            <GlobeIcon size="16px" />
                                            {` ${companyData.businessId}`}
                                        </p>
                                    )}
                                    {companyData.web && (
                                        <p className="profile__contact">
                                            <GlobeIcon size="16px" />
                                            <a
                                                className="link"
                                                href={companyData.web.startsWith('http') ? companyData.web : `https://${companyData.web}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {` ${companyData.web}`}
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="profile__information">
                                <p>{t('profileCompany.noCompanyInformation')}</p>
                            </div>
                        )}
                    </CardBody>
                </Card>
        </Col>
    );
};

ContactProfileCompany.propTypes = {
    companyData: PropTypes.arrayOf(PropTypes.shape({
        web: PropTypes.string.isRequired,
        startsWith: PropTypes.func.isRequired
    })).isRequired
};

export default ContactProfileCompany;
