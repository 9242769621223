import i18n from '../translations/index';
import {TARGET_REGISTRATION_STATUS} from './constants';

class RegistrationHelpers {
    static getRegistrationStatusText(statusCode) {
        if (!statusCode) return '';

        statusCode = statusCode.toUpperCase();

        if (statusCode === TARGET_REGISTRATION_STATUS.active) {
            return i18n.t('statusCode.active', {ns: 'common'});
        }
        if (statusCode === TARGET_REGISTRATION_STATUS.waiting) {
            return i18n.t('statusCode.inProgress', {ns: 'common'});
        }
        if (statusCode === TARGET_REGISTRATION_STATUS.cancelled) {
            return i18n.t('statusCode.cancelled', {ns: 'common'});
        }
        return '';
    }


    static getRegistrationStatusClass(statusCode) {
        if (!statusCode) return '';

        statusCode = statusCode.toUpperCase();

        if (statusCode === TARGET_REGISTRATION_STATUS.active) {
            return 'text-green';
        }
        if (statusCode === TARGET_REGISTRATION_STATUS.waiting) {
            return 'text-yellow';
        }
        if (statusCode === TARGET_REGISTRATION_STATUS.cancelled) {
            return 'text-red';
        }
        return '';
    }
}

export default RegistrationHelpers;
