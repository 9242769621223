import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import CodeError from '../../shared/components/error/CodeError';

class DomainHelpers {
    static async createDomain(companyId, domain) {
        try {
            const newDomain = await axiosWithPersonalToken.post(`companies/${companyId}/domains`, {
                domain
            });
            return newDomain.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async deleteDomain(id) {
        try {
            const deleted = await axiosWithPersonalToken.delete(`companies/domains/${id}`, {});
            return deleted.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async updateDomain(id, domain) {
        try {
            const updated = await axiosWithPersonalToken.put(`companies/domains/${id}`, {
                domain
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }
}

export default DomainHelpers;
