import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, ButtonToolbar, Col, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import materialTextField from '../../../shared/components/form/materialTextField';

const ContactAndCompanySearchInput = ({makeSearch, searchDisabled, label}) => {
    const { t } = useTranslation(['common']);

    const onSubmit = async (data) => {
        makeSearch(data.searchKey);
    };

    return (
        <Row className="mb-3">
            <Col xs={12}>
                <Form onSubmit={onSubmit}>
                    {({handleSubmit, dirty, form}) => (
                        <form className="material-form material-form-flex" onSubmit={handleSubmit}>
                            <Col xs={12} xl={8}>
                                <Field
                                    name="searchKey"
                                    component={materialTextField}
                                    label={label}
                                    maxLength={255}
                                />
                            </Col>
                            <Col xs={12} xl={4}>
                                <ButtonToolbar className="form__button-toolbar">
                                    <Button color="primary" type="submit" disabled={searchDisabled}>
                                        {t('buttons.search', { ns: 'common' })}
                                    </Button>
                                </ButtonToolbar>
                            </Col>
                        </form>
                    )}
                </Form>
            </Col>
        </Row>
    );
};

ContactAndCompanySearchInput.propTypes = {
    label: PropTypes.string.isRequired,
    makeSearch: PropTypes.func.isRequired,
    searchDisabled: PropTypes.bool.isRequired
};

export default ContactAndCompanySearchInput;
