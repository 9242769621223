import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { getLanguage } from '../../../redux/reducers/languageReducer';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const fi = `${process.env.PUBLIC_URL}/img/language/fi.png`;

const GbLng = () => (
    <span className="topbar__language-btn-title">
        <img src={gb} alt="gb" />
        <span>EN</span>
    </span>
);

const FiLng = () => (
    <span className="topbar__language-btn-title">
        <img src={fi} alt="fi" />
        <span>FI</span>
    </span>
);

const TopbarLanguage = ({ onLanguageChange, language }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [mainButtonContent, setMainButtonContent] = useState(<FiLng />);

    const toggleLanguage = () => {
        setIsCollapsed(!isCollapsed);
    };

    const setMainButton = (lng) => {
        switch (lng) {
            case 'en':
                setMainButtonContent(<GbLng />);
                break;
            case 'fi':
                setMainButtonContent(<FiLng />);
                break;
            default:
                setMainButtonContent(<FiLng />);
                break;
        }
    };

    const changeLanguage = (lng) => {
        onLanguageChange(lng);
        setMainButton(lng);
    };

    useEffect(() => {
        // Set main button after initial language is loaded on page refresh
        setMainButton(language);
    }, [language]);

    return (
        <div className="topbar__collapse topbar__collapse--language">
            <button className="topbar__btn" type="button" onClick={toggleLanguage}>
                {mainButtonContent}
                <DownIcon className="topbar__icon" />
            </button>
            {isCollapsed && (
                <button
                  className="topbar__back"
                  type="button"
                  aria-label="language button"
                  onClick={toggleLanguage}
                />
            )}
            <Collapse
              isOpen={isCollapsed}
              className="topbar__collapse-content topbar__collapse-content--language"
            >
                <button
                    className="topbar__language-btn"
                    type="button"
                    onClick={() => changeLanguage('fi')}
                >
                    <FiLng />
                </button>
                <button
                  className="topbar__language-btn"
                  type="button"
                  onClick={() => changeLanguage('en')}
                >
                    <GbLng />
                </button>
            </Collapse>
        </div>
    );
};

TopbarLanguage.propTypes = {
    onLanguageChange: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        language: getLanguage(state)
    };
}

export default connect(
    mapStateToProps,
    null
)(TopbarLanguage);
