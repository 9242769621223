import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import MeetingHelpers from '../../helpers/meetings/MeetingHelpers';
import MeetingTabs from './tabs/MeetingTabs';

const Meeting = () => {
    const {id} = useParams();
    const {t} = useTranslation(['meetings', 'common']);
    const [meeting, setMeeting] = useState({});
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);

    useEffect(() => {
        async function getMeeting() {
            if (hasPermissionShowMeetings) {
                const response = await MeetingHelpers.getMeeting(id);
                setMeeting(response);
            }
        }

        getMeeting();
    }, [hasPermissionShowMeetings]);

    return (
        <>
            {hasPermissionShowMeetings ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">
                                {meeting.title}
                            </h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="tabs tabs--bordered-bottom">
                                        {meeting.id && (
                                            <MeetingTabs meeting={meeting} setMeeting={setMeeting} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default Meeting;
