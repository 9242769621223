import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import CodeError from '../../shared/components/error/CodeError';

class CompanyHelpers {
    static async getCompanies(limit, offset, searchKey) {
        try {
            const companies = await axiosWithPersonalToken.get('companies', {
                params: {
                    limit,
                    offset,
                    searchKey
                }
            });
            return companies.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getCompany(id) {
        try {
            const companies = await axiosWithPersonalToken.get(`companies/${id}`, {});
            return companies.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async changeCompanyLogo(companyId, imageName) {
        try {
            const updated = await axiosWithPersonalToken.put(`companies/${companyId}/image`, {
                imageName
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }
}

export default CompanyHelpers;
