import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import materialTextField from '../../../../shared/components/form/materialTextField';
import {required, validDomain} from '../../../../helpers/forms/validation';
import RequiredField from '../../../../shared/components/form/RequiredField';
import Alert from '../../../../shared/components/alert/Alert';

const EditDomainForm = ({ toggleModal, errorAlertText, submitButtonText }) => {
    const { t } = useTranslation(['common']);

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const getRequiredFieldLabel = (label) => {
        return <>{label}<RequiredField /></>;
    };

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Field
                        name="domain"
                        component={materialTextField}
                        label={getRequiredFieldLabel(t('domain', {ns: 'common'}))}
                        validate={composeValidators(required, validDomain)}
                    />
                </Col>
            </Row>

            {errorAlertText.length > 0 && (
                <Row className="mt-2 mb-2">
                    <Col xs={12}>
                        <Alert color="danger">
                            {errorAlertText}
                        </Alert>
                    </Col>
                </Row>
            )}

            <ButtonToolbar className="modal__footer">
                <Button className="modal_cancel" onClick={toggleModal}>
                    {t('cancelBtn', {ns: 'common'})}
                </Button>{' '}
                <Button className="modal_ok" color="primary" type="submit">
                    {submitButtonText}
                </Button>
            </ButtonToolbar>
        </>
    );
};

EditDomainForm.propTypes = {
    errorAlertText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default EditDomainForm;
