import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import MeetingRegistrationHelpers from '../../helpers/meetings/MeetingRegistrationHelpers';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';
import RegistrationHelpers from '../../helpers/RegistrationHelpers';
import Order from '../Order/Order';

const MeetingRegistration = () => {
    const {id} = useParams();
    const {t} = useTranslation(['meetings', 'common']);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [registration, setRegistration] = useState({});
    const [meeting, setMeeting] = useState({});
    const [questions, setQuestions] = useState([]);
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);

    useEffect(() => {
        async function getMeetingRegistration() {
            if (hasPermissionShowMeetings) {
                try {
                    const response = await MeetingRegistrationHelpers.getMeetingRegistration(id);
                    setRegistration(response.registration);
                    setMeeting(response.meeting);
                    setQuestions(response.questions);
                    setDataLoaded(true);
                } catch (error) {
                    setDataLoaded(true);
                }
            }
        }

        getMeetingRegistration();
    }, [hasPermissionShowMeetings]);

    const getQuestionAnswers = (answers) => {
        const texts = _.flatMap(answers, function duplicate(n) {
            return n.answer;
        });

        return _.join(texts, ', ');
    };

    return (
        <>
            {hasPermissionShowMeetings ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12} className="mb-3">
                            <h3 className="page-title">
                                {t('registration', {ns: 'registration'})}
                            </h3>
                            <h4>
                                <span className="mr-2">{meeting.title}</span>
                                {meeting.startDate && (
                                    <span>
                                        ({DateTimeHelpers.formatDate(meeting.startDate)})
                                    </span>
                                )}
                            </h4>
                        </Col>
                    </Row>

                    {(dataLoaded && registration.id) && (
                        <Row>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardBody className="dashboard__card-widget">
                                        <div className="card__title">
                                            <h5 className="bold-text">
                                                {t('participant', {ns: 'registration'})}
                                            </h5>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <b>{t('firstName', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.firstName}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('lastName', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.lastName}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('title', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.title}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('email', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.email}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('mobile', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.mobile}
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardBody className="dashboard__card-widget">
                                        <div className="card__title">
                                            <h5 className="bold-text">
                                                {t('company', {ns: 'common'})}
                                            </h5>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <b>{t('name', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.company}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('businessId', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.businessId}
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardBody className="dashboard__card-widget">
                                        <div className="card__title">
                                            <h5 className="bold-text">
                                                {t('registration', {ns: 'registration'})}
                                            </h5>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <b>{t('status', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8} className={RegistrationHelpers.getRegistrationStatusClass(registration.status)}>
                                                    {RegistrationHelpers.getRegistrationStatusText(registration.status)}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('registrationDate', {ns: 'registration'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.registrationDate ? (
                                                        <>
                                                            {DateTimeHelpers.formatDate(registration.registrationDate)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {DateTimeHelpers.formatDate(registration.createdAt)}
                                                        </>
                                                    )}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('additionalInformation', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.info}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('deliveryAndCancellationTermsAccepted', {ns: 'registration'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {registration.termsAccepted ? t('yes', {ns: 'common'}) : t('no', {ns: 'common'})}
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardBody className="dashboard__card-widget">
                                        <div className="card__title">
                                            <h5 className="bold-text">
                                                {t('questions', {ns: 'common'})}
                                            </h5>
                                        </div>
                                        <div>
                                            <Row>
                                                {questions.map((item, index) => {
                                                    return (
                                                        <>
                                                            <Col md={12} lg={4}>
                                                                <b>{item.question}</b>
                                                            </Col>
                                                            <Col md={12} lg={8}>
                                                                {getQuestionAnswers(item.answers)}
                                                            </Col>
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xs={12}>
                                <Order orderId={registration.orderId} />
                            </Col>
                        </Row>
                    )}

                    {(dataLoaded && typeof registration.id === 'undefined') && (
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody className="dashboard__card-widget">
                                        {t('dataNotFound', {ns: 'common'})}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}

                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default MeetingRegistration;
