import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import { PERMISSIONS } from '../../helpers/roles/Permissions';
import CompaniesTable from './components/CompaniesTable';

const Companies = () => {
    const { t } = useTranslation(['common', 'contacts']);
    const hasPermissionShowCompanies = PermissionHelpers.hasPermission(PERMISSIONS.show_companies);

    return (
        <>
            {hasPermissionShowCompanies ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">{t('companies', {ns: 'companies'})}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="tabs tabs--bordered-bottom">
                                        <CompaniesTable />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default Companies;
