import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Company from '../../Settings/Company';
import Users from '../../Settings/Users';
import Integrations from '../../Settings/Integrations';

export default () => (
    <Switch>
        <Route path="/settings/company" component={Company} />
        <Route path="/settings/users" component={Users} />
        <Route path="/settings/integrations" component={Integrations} />
    </Switch>
);
