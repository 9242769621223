import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { renderComponentField } from './FormField';

export const SelectField = React.forwardRef(({
                                               onChangeHandler, onChange, value, defaultValue, name, placeholder, options,
}, ref) => {
  const handleChange = (selectedOption) => {
    onChange(selectedOption); // Required to keep the selected value
    if (typeof onChangeHandler === 'function') {
      onChangeHandler(selectedOption); // Required to call the function in the form
    }
    };

  return (
    <Select
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      options={options}
      clearable={false}
      isSearchable={false}
      className="react-select"
      placeholder={placeholder}
      classNamePrefix="react-select"
      ref={ref}
    />
  );
});

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ])
};

SelectField.defaultProps = {
  placeholder: '',
  options: [],
  defaultValue: '',
  onChangeHandler: null,
  // value: ''
};

export default renderComponentField(SelectField);
