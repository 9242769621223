import {
    CHANGE_DIRECTION_TO_RTL,
    CHANGE_DIRECTION_TO_LTR
} from '../actionTypes';

const initialState = {
    direction: 'ltr',
};

export default function rtlReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DIRECTION_TO_RTL: {
            return { direction: 'rtl' };
        }
        case CHANGE_DIRECTION_TO_LTR: {
            return { direction: 'ltr' };
        }
        default:
            return state;
    }
}

export const getDirection = state => state.rtlReducer;
