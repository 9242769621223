import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import {useHistory, useLocation} from 'react-router-dom';
import SetPasswordForm from './components/SetPasswordForm';
import UserHelpers from '../../../helpers/users/UserHelpers';

const SetPassword = (props) => {
    const [setPasswordFailed, setSetPasswordFailed] = useState(false);
    const [extraErrorText, setExtraErrorText] = useState('');
    const [setPasswordButtonDisabled, setSetPasswordButtonDisabled] = useState(false);
    const [passwordToken, setPasswordToken] = useState('');
    const [passwordContactId, setPasswordContactId] = useState('');
    const { t } = useTranslation(['login', 'common']);
    const location = useLocation();
    const history = useHistory();

    const timerRef = useRef(null);

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        const id = new URLSearchParams(location.search).get('id');
        setPasswordToken(token);
        setPasswordContactId(id);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const showError = (additionalText) => {
        timerRef.current = setTimeout(() => {
            setSetPasswordFailed(true);
            setExtraErrorText(additionalText);
            setSetPasswordButtonDisabled(false);
        }, 500);
    };

    const onSetPassword = async (formInputs) => {
        setSetPasswordFailed(false);
        setSetPasswordButtonDisabled(true);

        if (!passwordToken || !passwordContactId) {
            showError(t('passwordSetFailed'));
        } else {
            try {
                const passwordSet = await UserHelpers.setPassword(formInputs.password, formInputs.confirm_password, passwordToken, passwordContactId);
                history.push('/?passwordSet=true');
            } catch (error) {
                if (error.status === StatusCodes.UNAUTHORIZED && error.code === 'VERIFICATION_REQUIRED') {
                    showError(t('emailNotVerifiedInfo'));
                } else {
                    showError('');
                }
            }
        }
    };

    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">{t('setPassword.setPasswordForAccount', {ns: 'common'})}</h3>
                    </div>
                    <Form
                        onSubmit={onSetPassword}
                    >
                        {({ handleSubmit }) => (
                            <form className="form" onSubmit={handleSubmit}>
                                <SetPasswordForm
                                    setPasswordFailed={setPasswordFailed}
                                    extraErrorText={extraErrorText}
                                    setPasswordButtonDisabled={setPasswordButtonDisabled}
                                />
                            </form>
                            )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

SetPassword.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default connect(
    null,
    {}
)(SetPassword);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
