import {
    CHANGE_THEME_TO_DARK,
    CHANGE_THEME_TO_LIGHT
} from '../actionTypes';

export const changeThemeToDark = () => ({
    type: CHANGE_THEME_TO_DARK
});
export const changeThemeToLight = () => ({
    type: CHANGE_THEME_TO_LIGHT
});
