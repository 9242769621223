class AxiosHelpers {
    static handleRequestError(error) {
        let errorMsg = '';
        let errorCode = '';
        let statusCode = '';

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data) {
                if (typeof error.response.data.message !== 'undefined') {
                    errorMsg = error.response.data.message;
                }

                if (typeof error.response.data.errorCode !== 'undefined') {
                    errorCode = error.response.data.errorCode;
                }
            }

            if (typeof error.response.status !== 'undefined') {
                statusCode = error.response.status;
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js

        } else if (error.message) {
            // Something happened in setting up the request that triggered an Error

        }

        return {
            code: errorCode,
            message: errorMsg,
            status: statusCode
        };
    }
}


export default AxiosHelpers;
