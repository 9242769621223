import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Col, Container, Row
} from 'reactstrap';
import { connect } from 'react-redux';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import ProfileCompany from './components/ProfileCompany';

const Profile = () => {
    const { t } = useTranslation(['meetings', 'common']);

    return (
        <Container>
            <div className="profile">
                <Row>
                    <Col md={12} lg={12} xl={4}>
                        <Row>
                            <ProfileMain />
                            <ProfileCompany />
                        </Row>
                    </Col>
                    <ProfileTabs />
                </Row>
            </div>
        </Container>
    );
};

export default Profile;
