/* eslint-disable camelcase */
import common_fi from './fi/common.json';
import common_en from './en/common.json';

import companies_fi from './fi/companies.json';
import companies_en from './en/companies.json';

import contacts_fi from './fi/contacts.json';
import contacts_en from './en/contacts.json';

import errors_fi from './fi/errors.json';
import errors_en from './en/errors.json';

import forms_fi from './fi/forms.json';
import forms_en from './en/forms.json';

import login_fi from './fi/login.json';
import login_en from './en/login.json';

import meetings_fi from './fi/meetings.json';
import meetings_en from './en/meetings.json';

import order_en from './en/order.json';
import order_fi from './fi/order.json';

import product_packages_en from './en/productPackages.json';
import product_packages_fi from './fi/productPackages.json';

import tables_en from './en/tables.json';
import tables_fi from './fi/tables.json';

import tooltips_fi from './fi/tooltips.json';
import tooltips_en from './en/tooltips.json';

import settings_fi from './fi/settings.json';
import settings_en from './en/settings.json';

import profile_fi from './fi/profile.json';
import profile_en from './en/profile.json';

import registration_fi from './fi/registration.json';
import registration_en from './en/registration.json';

export default {
    en: {
        common: common_en,
        companies: companies_en,
        contacts: contacts_en,
        errors: errors_en,
        forms: forms_en,
        login: login_en,
        meetings: meetings_en,
        order: order_en,
        productPackages: product_packages_en,
        registration: registration_en,
        tables: tables_en,
        tooltips: tooltips_en,
        settings: settings_en,
        profile: profile_en
    },
    fi: {
        common: common_fi,
        companies: companies_fi,
        contacts: contacts_fi,
        errors: errors_fi,
        forms: forms_fi,
        login: login_fi,
        meetings: meetings_fi,
        order: order_fi,
        productPackages: product_packages_fi,
        registration: registration_fi,
        tables: tables_fi,
        tooltips: tooltips_fi,
        settings: settings_fi,
        profile: profile_fi
    },
};
