import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import _ from 'lodash';
import MeetingHelpers from '../../../helpers/meetings/MeetingHelpers';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';
import ProductPackagesTable from '../components/ProductPackagesTable';
import {PRODUCT_PACKAGE_TARGET_TYPE} from '../../../helpers/constants';

const MeetingPackagesTab = ({meeting}) => {
    const {id} = useParams();
    const [productPackages, setProductPackages] = useState([]);

    const {t} = useTranslation(['meetings', 'common', 'forms']);
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);

    const addNewPackage = (newPackage) => {
        const packages = _.cloneDeep(productPackages);
        packages.push(newPackage);
        setProductPackages(packages);
    };

    const setUpdatedPackage = (updatedPackage) => {
        const packages = _.cloneDeep(productPackages);
        const index = _.findIndex(packages, { 'id': updatedPackage.id});

        if (index >= 0) {
            packages[index] = updatedPackage;
        }

        setProductPackages(packages);
    };

    useEffect(() => {
        async function getProductPackages() {
            if (hasPermissionShowMeetings) {
                const response = await MeetingHelpers.getMeetingProductPackages(id);
                const responsePackages = _.orderBy(response, ['order'], ['asc']);
                setProductPackages(responsePackages);
            }
        }

        getProductPackages();
    }, []);

    return (
        <Row>
            <Col xs={12}>
                <ProductPackagesTable
                    productPackages={productPackages}
                    addNewPackage={addNewPackage}
                    setUpdatedPackage={setUpdatedPackage}
                    targetId={meeting.id}
                    targetType={PRODUCT_PACKAGE_TARGET_TYPE.meeting}
                />
            </Col>
        </Row>
    );
};

MeetingPackagesTab.propTypes = {
    meeting: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default MeetingPackagesTab;
