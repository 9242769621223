import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps } from '@/shared/prop-types/ReducerProps';
import ReactTableDnDBody from './ReactTableDnDBody';
import { getTheme } from '../../../../redux/reducers/themeReducer';

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow, renderRowSubComponent, visibleColumns, getTableRowProps }) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      return (
          <React.Fragment>
            <tr {...row.getRowProps(getTableRowProps({ row }))}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
             {row.isExpanded ? (
                  <tr>
                      <td colSpan={visibleColumns.length}>
                          {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {renderRowSubComponent({ row })}
                      </td>
                  </tr>
              ) : null}
          </React.Fragment>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableRowProps: PropTypes.func,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  renderRowSubComponent: PropTypes.func.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

ReactTableDefaultBody.defaultProps = {
    getTableRowProps: () => {},
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, renderRowSubComponent, visibleColumns, getTableRowProps
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          renderRowSubComponent={renderRowSubComponent}
          visibleColumns={visibleColumns}
          getTableRowProps={getTableRowProps}
        />
      )}
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  getTableRowProps: PropTypes.func,
  prepareRow: PropTypes.func.isRequired,
  renderRowSubComponent: PropTypes.func,
  updateDraggableData: PropTypes.func.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};

ReactTableBody.defaultProps = {
  renderRowSubComponent: () => {},
  getTableRowProps: () => {},
};

export default connect(state => ({
  theme: getTheme(state),
}))(ReactTableBody);
