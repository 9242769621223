import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';

class ProductPackageHelpers {
    static async createProductPackage(targetId, targetType, title, description, active, products) {
        try {
            const updated = await axiosWithPersonalToken.post('packages', {
                title,
                description,
                active,
                products,
                targetId,
                targetType
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateProductPackage(id, title, description, active, products) {
        try {
            const updated = await axiosWithPersonalToken.put(`packages/${id}`, {
                title,
                description,
                active,
                products
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }
}

export default ProductPackageHelpers;
