export const CHANGE_DIRECTION_TO_LTR = 'CHANGE_DIRECTION_TO_LTR';
export const CHANGE_DIRECTION_TO_RTL = 'CHANGE_DIRECTION_TO_RTL';

export const LOG_IN_USER = 'LOG_IN_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const CHANGE_USER_IMAGE = 'CHANGE_USER_IMAGE';

export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';

export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS';
export const SET_LANGUAGE_DEFAULTS = 'SET_DEFAULTS';
