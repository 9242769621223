import axios from 'axios';
import AccessToken from './helpers/AccessToken';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Authorization': 'N183C49607C5856DB8C180131FBBFD68EAC53ED5',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGIN
    }
});

export const axiosWithPersonalToken = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGIN
    }
});

axiosWithPersonalToken.interceptors.request.use((config) => {
    const token = AccessToken.getAccessToken();

    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});
