import React, { useState } from 'react';
import {
    Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ProfileTimeLine from '@/shared/components/ProfileTimeLine';
import Alert from '@/shared/components/alert/Alert';
import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
import UserHelpers from '../../../../helpers/users/UserHelpers';

const ProfileTabs = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [saveFailed, setSaveFailed] = useState(false);
    const [extraErrorText, setExtraErrorText] = useState('');

    const { t } = useTranslation('profile');

    const handleToggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const showSaveSuccessful = () => {
        setSaveSuccessful(true);
        setSaveFailed(false);
    };

    const showSaveFailed = () => {
        setSaveFailed(true);
        setSaveSuccessful(false);
    };

    const handleSaveUser = (firstName, lastName, email, oldEmail) => {
        const accountUpdated = UserHelpers.updateAccount(firstName, lastName, email, oldEmail);

        accountUpdated.then(() => {
            // set success alert for user
            UserHelpers.refreshUserData().then(() => {
                setActiveTab('1');
                showSaveSuccessful();
            })
                .catch(() => showSaveFailed());
        })
            .catch((error) => {
                // set error alert for user
                showSaveFailed();
            });
    };

    return (
        <Col md={12} lg={12} xl={8}>
            {saveSuccessful && (
                <Alert
                    color="success"
                    className="mb-2"
                >
                    <p>
                        {t('profileEdit.saveSuccessful')}
                    </p>
                </Alert>
            )}
            {saveFailed && (
                <Alert
                    color="danger"
                    className="mb-2"
                >
                    <p>
                        {`${t('profileEdit.saveFailed')} ${extraErrorText ?? ''}`}
                    </p>
                </Alert>
            )}
            <Card>
                <div className="profile__card tabs tabs--bordered-bottom">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => handleToggle('1')}
                                >
                                    {t('profileTabs.activity')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => handleToggle('2')}
                                >
                                    {t('profileTabs.edit')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => handleToggle('3')}
                                >
                                    {t('profileTabs.changelog')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <ProfileActivities />
                            </TabPane>
                            <TabPane tabId="2">
                                <ProfileSettings handleSaveUser={handleSaveUser} />
                            </TabPane>
                            <TabPane tabId="3">
                                <ProfileTimeLine />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </Card>
        </Col>
    );
};

export default ProfileTabs;
