import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/app.scss';
import {CloudinaryContext} from 'cloudinary-react';
import Router from './Router/index';
import ScrollToTop from './ScrollToTop';
import { getLanguage } from '../../redux/reducers/languageReducer';
import {CloudinaryConfig} from '../../helpers/Cloudinary/CloudinaryConfig';

const App = ({ locale }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        window.addEventListener('load', () => {
            setIsLoading(false);
            setTimeout(() => setIsLoaded(true), 500);
        });
    }, []);

  return (
          <BrowserRouter>
              <ScrollToTop>
                  <Fragment>
                      {!isLoaded && (
                      <div className={`load${isLoading ? '' : ' loaded'}`}>
                          <div className="load__icon-wrap">
                              <svg className="load__icon">
                                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                              </svg>
                          </div>
                      </div>
                      )}
                      <div>
                          <CloudinaryContext cloudName={CloudinaryConfig.cloudName}>
                            <Router />
                          </CloudinaryContext>
                      </div>
                  </Fragment>
              </ScrollToTop>
          </BrowserRouter>
  );
};

App.propTypes = {
    locale: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        locale: getLanguage(state)
    };
}

export default connect(
    mapStateToProps,
    null
)(App);
