import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {changeThemeToDark, changeThemeToLight} from '../../redux/actions/themeActions';
import {changeMobileSidebarVisibility, changeSidebarVisibility} from '../../redux/actions/sidebarActions';
import {SidebarProps} from '../../shared/prop-types/ReducerProps';
import {getSidebar} from '../../redux/reducers/sidebarReducer';
import {changeLanguage} from '../../redux/actions/language';
import i18n from '../../translations';
import Session from '../../helpers/Session';
import UserHelpers from '../../helpers/users/UserHelpers';

const Layout = ({
                    sidebar, changeToDarkTheme, changeToLightTheme,
                    changeSBVisibility, changeUserLanguage, changeMobileSBVisibility
}) => {
  const layoutClass = classNames({
    'layout': true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    // dispatch(changeSidebarVisibility());
      changeSBVisibility();
  };

  const mobileSidebarVisibility = () => {
    changeMobileSBVisibility();
  };

  const changeToDark = () => {
    // dispatch(changeThemeToDark());
      // store.dispatch(changeThemeToDark());
      changeToDarkTheme();
  };

  const changeToLight = () => {
    // dispatch(changeThemeToLight());
      changeToLightTheme();
  };

  const onLanguageChange = useCallback(
      (lang) => {
          i18n
              .changeLanguage(lang.toLowerCase())
              .then((tt) => {
                  // t('key'); // -> same as i18next.t
                  // Update to store
                  changeUserLanguage(lang);
              });

            const updated = UserHelpers.updateLanguage(lang);

            updated.then(() => {
            });
        }, []
    );

    const initLanguage = () => {
        const language = Session.getLanguage();

        if (language) {
            onLanguageChange(language);
        }
    };

    /* const checkSessionLogin = async () => {
        const accessToken = AccessToken.getAccessToken();

        if (accessToken.length > 0) {
            try {
                const contactData = await ContactHelpers.getContact();

                if (contactData.email) {
                    store.dispatch(logInUser(
                        contactData.contact,
                        contactData.email,
                        contactData.firstname,
                        contactData.lastname,
                        accessToken,
                        contactData.permissions
                    ));
                }
            } catch (e) {
                // TODO
            }
        }
    }; */

    useEffect(async () => {
        // await checkSessionLogin();
        initLanguage();
    }, []);

    return (
        <div className={layoutClass}>
            <Topbar
                changeMobileSidebarVisibility={mobileSidebarVisibility}
                changeSidebarVisibility={sidebarVisibility}
                onLanguageChange={onLanguageChange}
            />
            <Sidebar
                sidebar={sidebar}
                changeToDark={changeToDark}
                changeToLight={changeToLight}
                changeMobileSidebarVisibility={mobileSidebarVisibility}
            />
        </div>
    );
};

Layout.propTypes = {
    sidebar: SidebarProps.isRequired,
    changeSBVisibility: PropTypes.func.isRequired,
    changeToDarkTheme: PropTypes.func.isRequired,
    changeToLightTheme: PropTypes.func.isRequired,
    changeUserLanguage: PropTypes.func.isRequired,
    changeMobileSBVisibility: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    sidebar: getSidebar(state),
  };
}

export default withRouter(connect(
    mapStateToProps,
    {
        changeUserLanguage: changeLanguage,
        changeSBVisibility: changeSidebarVisibility,
        changeToDarkTheme: changeThemeToDark,
        changeToLightTheme: changeThemeToLight,
        changeMobileSBVisibility: changeMobileSidebarVisibility
    }
)(Layout));
