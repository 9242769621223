import { Button, ButtonToolbar, Col, Row } from 'reactstrap';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import materialTextField from '../../../shared/components/form/materialTextField';
import {minValue, required} from '../../../helpers/forms/validation';
import RequiredField from '../../../shared/components/form/RequiredField';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import Alert from '../../../shared/components/alert/Alert';

const EditProductPackageForm = ({ toggleModal, errorAlertText, submitButtonText }) => {
    const { t } = useTranslation(['common']);
    const {
        getFieldState
    } = useForm();

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const getRequiredFieldLabel = (label) => {
        return <>{label}<RequiredField /></>;
    };

    return (
        <>
            <Row>
                <Col xs={12} lg={9}>
                    <Field
                        name="title"
                        component={materialTextField}
                        label={getRequiredFieldLabel(t('name', {ns: 'common'}))}
                        validate={composeValidators(required)}
                    />
                </Col>

                <Col xs={12} lg={3}>
                    <div className="form__form-group">
                        <div className="form__form-group-field inline-toggle mt-2">
                            <Field
                                name="active"
                                component={renderToggleButtonField}
                            />

                            <span className="form__form-group-label pt-3 pl-3">
                                {t('active', {ns: 'common'})}
                            </span>
                        </div>
                    </div>
                </Col>

                <Col xs={12}>
                    <Field
                        name="description"
                        component={materialTextField}
                        label={t('description', {ns: 'common'})}
                    />
                </Col>
            </Row>
            <Row className="mb-3 mt-3">
                <Col>
                    <h5 className="bold-text">
                        {t('products', {ns: 'common'})}
                    </h5>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t('code', {ns: 'common'})}</th>
                                <th>{t('name', {ns: 'common'})}</th>
                                <th>{t('price', {ns: 'common'})} (€)</th>
                                <th>{t('amount', {ns: 'common'})}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <FieldArray name="products">
                                {({ fields }) => fields.map((name, index) => (
                                    <tr key={name}>

                                            <td className="pr-2">
                                                <Field
                                                    name={`${name}.code`}
                                                    component={materialTextField}
                                                    disabled
                                                />
                                            </td>
                                            <td className="pr-2">
                                                <Field
                                                    name={`${name}.title`}
                                                    component={materialTextField}
                                                    disabled
                                                />
                                            </td>
                                            <td className="pr-2">
                                                <Field
                                                    name={`${name}.price`}
                                                    component={materialTextField}
                                                    placeholder="price"
                                                    validate={composeValidators(required, minValue(0))}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    name={`${name}.quantity`}
                                                    component={materialTextField}
                                                    placeholder="quantity"
                                                    validate={composeValidators(required, minValue(1))}
                                                />
                                            </td>
                                    </tr>
                                ))}
                            </FieldArray>
                        </tbody>
                    </table>
                </Col>
            </Row>

            {errorAlertText.length > 0 && (
                <Row className="mt-2 mb-2">
                    <Col xs={12}>
                        <Alert color="danger">
                            {errorAlertText}
                        </Alert>
                    </Col>
                </Row>
            )}

            <ButtonToolbar className="modal__footer">
                <Button className="modal_cancel" onClick={toggleModal}>
                    {t('cancelBtn', {ns: 'common'})}
                </Button>{' '}
                <Button className="modal_ok" color="primary" type="submit">
                    {submitButtonText}
                </Button>
            </ButtonToolbar>
        </>
    );
};

EditProductPackageForm.propTypes = {
    errorAlertText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default EditProductPackageForm;
