import {Button, ButtonToolbar, Col, Row} from 'reactstrap';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import materialTextField from '../../../../shared/components/form/materialTextField';
import {emailValid, required} from '../../../../helpers/forms/validation';
import Alert from '../../../../shared/components/alert/Alert';
import RequiredField from '../../../../shared/components/form/RequiredField';

const EditInvoiceAddressForm = ({ companyNames, toggleModal, errorAlertText, submitButtonText, companySelectorDisabled }) => {
    const { t } = useTranslation(['common', 'companies', 'order', 'forms']);

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const getRequiredFieldLabel = (label) => {
        return <>{label}<RequiredField /></>;
    };

    return (
        <>
            <Row className="mt-3">
                <Col xs={12}>
                    <div className="form__form-group">
                    <span className="material-form__label">
                          {getRequiredFieldLabel(t('companyName', {ns: 'companies'}))}
                    </span>
                        <div className="form__form-group-field">
                            <Field
                                name="nameId"
                                component={materialTextField}
                                validate={required}
                                select
                                disabled={companySelectorDisabled}
                            >
                                {companyNames.map(item => (
                                    <MenuItem className="material-form__option" value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </div>
                    </div>
                </Col>

                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} className="mb-2">
                            <h5>{t('invoicingAddress', {ns: 'order'})}</h5>
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="invoiceCompany"
                                component={materialTextField}
                                label={t('invoiceCompany', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="identifier"
                                component={materialTextField}
                                label={t('identifier', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="address"
                                component={materialTextField}
                                label={t('labels.address', {ns: 'forms'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="postalCode"
                                component={materialTextField}
                                label={t('postalCode', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="city"
                                component={materialTextField}
                                label={t('labels.city', {ns: 'forms'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="country"
                                component={materialTextField}
                                label={t('labels.country', {ns: 'forms'})}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12} className="mb-2">
                            <h5>{t('eInvoicingInformation', {ns: 'order'})}</h5>
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="eInvoiceCode"
                                component={materialTextField}
                                label={t('operatorCode', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="eInvoiceAddress"
                                component={materialTextField}
                                label={t('eInvoicingAddress', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="eInvoiceOperator"
                                component={materialTextField}
                                label={t('operator', {ns: 'order'})}
                            />
                        </Col>

                        <Col xs={12}>
                            <Field
                                name="eInvoiceEmail"
                                component={materialTextField}
                                label={t('email', {ns: 'common'})}
                                validate={composeValidators(emailValid)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {errorAlertText.length > 0 && (
                <Row className="mt-2 mb-2">
                    <Col xs={12}>
                        <Alert color="danger">
                            {errorAlertText}
                        </Alert>
                    </Col>
                </Row>
            )}

            <ButtonToolbar className="modal__footer">
                <Button className="modal_cancel" onClick={toggleModal}>
                    {t('cancelBtn', {ns: 'common'})}
                </Button>{' '}
                <Button className="modal_ok" color="primary" type="submit">
                    {submitButtonText}
                </Button>
            </ButtonToolbar>
        </>
    );
};

EditInvoiceAddressForm.propTypes = {
    companyNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    companySelectorDisabled: PropTypes.bool.isRequired,
    errorAlertText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default EditInvoiceAddressForm;
