import React, { useState } from 'react';
import {
    Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Alert from '@/shared/components/alert/Alert';
import CloudinaryHelpers from '../../../helpers/Cloudinary/CloudinaryHelpers';
import { CLOUDINARY_TARGETS } from '../../../helpers/Cloudinary/CloudinaryConstants';
import ChangeImageButton from '../../Common/ChangeImageButton';
import CompanyHelpers from '../../../helpers/Company/CompanyHelpers';

const defaultImage = 'default_profile_picture_dl18d6';

const MainCompanyProfile = ({company, changeLogo}) => {
    const [imageUploadError, setImageUploadError] = useState('');
    const {t} = useTranslation('profile');
    const primaryName = _.find(company.names, { 'primary': true});

    const saveNewImage = async (imageName) => {
        try {
            const updated = await CompanyHelpers.changeCompanyLogo(company.id, imageName);
            changeLogo(imageName);
            setImageUploadError('');
        } catch (e) {
            setImageUploadError('profileMain.imageUploadFailed');
        }
    };

    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody className="profile__card">
                    <div className="profile__information">
                        <div className="company-profile-avatar">
                             <CloudinaryImage
                                publicId={CloudinaryHelpers.getPublicId(CLOUDINARY_TARGETS.company, company.logo ? company.logo : defaultImage)}
                                alt="avatar"
                             >
                                 <Transformation width="150" height="150" />
                            </CloudinaryImage>
                        </div>
                        <div className="profile__data">
                            <p className="profile__name">
                                {primaryName && primaryName.name ? primaryName.name : ''}
                            </p>
                            <p className="profile__contact">
                                {primaryName && primaryName.businessId ? primaryName.businessId : ''}
                            </p>
                        </div>
                    </div>
                    <div className="profile__stats mt-2">
                        <ButtonToolbar>
                            <ChangeImageButton
                                setError={setImageUploadError}
                                changeImage={saveNewImage}
                                targetType={CLOUDINARY_TARGETS.company}
                            />
                        </ButtonToolbar>
                    </div>
                    {imageUploadError && (
                        <Alert color="danger" className="m-1">
                            <p>{t(imageUploadError)}</p>
                        </Alert>
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

MainCompanyProfile.propTypes = {
    changeLogo: PropTypes.func.isRequired,
    company: PropTypes.arrayOf(PropTypes.shape({
        names: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })).isRequired,
};

export default MainCompanyProfile;
