import store from '../containers/App/store';

class LanguageHelpers {
    static getLocaleNamedColumn(obj) {
        let lang = store.getState().languageReducer.language.toLowerCase();
        const defaultLanguage = store.getState().languageReducer.defaultLanguage.toLowerCase();

        if (lang === 'sv') {
            lang = 'se';
        }

        if (obj[lang] && obj[lang].length > 0) {
            return obj[lang];
        }

        if (obj[defaultLanguage]) {
            return obj[defaultLanguage];
        }

        return '';
    }
}

export default LanguageHelpers;
