import { SESSION_KEY } from './constants';

class AccessToken {
    static getAccessToken() {
        if (typeof window !== 'undefined') {
            const itemStr = localStorage.getItem(SESSION_KEY.access_token);

            if (!itemStr) {
                return '';
            }

            try {
                const item = JSON.parse(itemStr);
                const now = new Date();

                // compare the expiry time of the item with the current time
                if (now.toISOString() > item.expiry) {
                    localStorage.removeItem(SESSION_KEY.access_token);
                    return '';
                }

                return item.value;
            } catch (e) {
                return '';
            }
        } else {
            return '';
        }
    }
}

export default AccessToken;
