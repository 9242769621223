import React, { useState } from 'react';
import {
    Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import { useTranslation } from 'react-i18next';
import Alert from '@/shared/components/alert/Alert';
import CloudinaryHelpers from '../../../helpers/Cloudinary/CloudinaryHelpers';
import { CLOUDINARY_TARGETS } from '../../../helpers/Cloudinary/CloudinaryConstants';
import ChangePasswordButton from '../../User/Profile/components/ChangePasswordButton';
import {CONTACT_PROFILE_TYPE} from '../../../helpers/constants';
import ChangeUserImageButton from '../../User/Profile/components/ChangeUserImageButton';
import ChangeContactImageButton from './ChangeContactImageButton';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';

const defaultImage = 'default_profile_picture_dl18d6';

const ContactProfile = ({ fullname, email, title, mobile, image, contactId, changePassword, passwordChangeError,
                            passwordChangeSuccess, showChangePasswordButton, profileType, changeContactImage }) => {
    const [imageUploadError, setImageUploadError] = useState('');
    const {t} = useTranslation('profile');
    const hasPermissionManageContacts = PermissionHelpers.hasPermission(PERMISSIONS.manage_contacts);

    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody className="profile__card">
                    <div className="profile__information">
                        <div className="profile__avatar">
                            <CloudinaryImage
                                publicId={CloudinaryHelpers.getPublicId(CLOUDINARY_TARGETS.contact, image ? image : defaultImage)}
                                alt="avatar"
                            >
                                <Transformation width="150" height="150" crop="thumb" radius="max" />
                            </CloudinaryImage>
                        </div>
                        <div className="profile__data">
                            <p className="profile__name">{fullname ?? ''}</p>
                            <p className="profile__work">{title}</p>
                            <p className="profile__contact">
                                <span className="lnr lnr-envelope mr-2" />
                                {email}
                            </p>
                            <p className="profile__contact">
                                <span className="lnr lnr-phone-handset mr-2" />
                                {mobile}
                            </p>
                        </div>
                    </div>
                    <div className="profile__stats mt-2">
                        <ButtonToolbar>
                            {(contactId && hasPermissionManageContacts) && (
                                <>
                                    {profileType === CONTACT_PROFILE_TYPE.contact ? (
                                        <ChangeContactImageButton
                                            setError={setImageUploadError}
                                            changeImage={changeContactImage}
                                            contactId={contactId}
                                        />
                                    ) : (
                                        <ChangeUserImageButton setError={setImageUploadError} />
                                    )}
                                </>
                            )}
                            {(showChangePasswordButton && hasPermissionManageContacts) && (
                                <ChangePasswordButton changePassword={changePassword} />
                            )}
                        </ButtonToolbar>
                    </div>
                    {imageUploadError && (
                        <Alert color="danger" className="m-1">
                            <p>{t(imageUploadError)}</p>
                        </Alert>
                    )}
                    {passwordChangeError && (
                        <Alert color="danger" className="m-1">
                            <p>{t('profileMain.passwordChangeError')}</p>
                        </Alert>
                    )}
                    {passwordChangeSuccess && (
                        <Alert color="success" className="m-1">
                            <p>{t('profileMain.passwordChangeSuccess')}</p>
                        </Alert>
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

ContactProfile.propTypes = {
    fullname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    profileType: PropTypes.string.isRequired,
    changePassword: PropTypes.func.isRequired,
    passwordChangeError: PropTypes.string,
    passwordChangeSuccess: PropTypes.string,
    showChangePasswordButton: PropTypes.bool.isRequired,
    changeContactImage: PropTypes.func
};

ContactProfile.defaultProps = {
    passwordChangeError: '',
    passwordChangeSuccess: '',
    changeContactImage: () => {}
};

export default ContactProfile;
