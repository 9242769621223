import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-final-form';
import {Col, Row} from 'reactstrap';
import MeetingHelpers from '../../../helpers/meetings/MeetingHelpers';
import Alert from '../../../shared/components/alert/Alert';
import {ALERT_TYPE} from '../../../helpers/constants';
import DescriptionForm from '../forms/DescriptionForm';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';

const MeetingDescriptionTab = ({meeting, setMeeting}) => {
    const {id} = useParams();
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [initialDescriptionValues, setInitialDescriptionValues] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {t} = useTranslation(['meetings', 'common', 'forms']);
    const hasPermissionEditMeetings = PermissionHelpers.hasPermission(PERMISSIONS.create_and_update_meeting);

    const showSuccessAlert = (text) => {
        setAlertType(ALERT_TYPE.success);
        setAlertText(text);
    };

    const showErrorAlert = (text) => {
        setAlertType(ALERT_TYPE.danger);
        setAlertText(text);
    };

    const onSubmit = async (data) => {
        try {
            const newMeetingValues = await MeetingHelpers.updateMeetingDescriptions(
                id,
                data.description,
                data.terms
            );

            // Show success
            showSuccessAlert(t('dataSavedSuccessfully', {ns: 'forms'}));
            setIsSubmitted(true);
            // Set new values for meeting
            // (required to set new initial values to form for cancel-button to reset to currently saved values)
            setMeeting(newMeetingValues);
        } catch (e) {
            // Show error
            showErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    useEffect(() => {
        function getInitialValues() {
            const initialValues = {
                description: meeting.description ?? '',
                terms: meeting.termsAndConditions ?? ''
            };

            setInitialDescriptionValues(initialValues);
        }

        getInitialValues();
    }, [meeting]);

    return (
        <>
            {alertText.length > 0 && (
                <Row>
                    <Col xs={12} className="mb-4">
                        <Alert color={alertType} className="mt-1">
                            <p>{alertText}</p>
                        </Alert>
                    </Col>
                </Row>
            )}

            {/* : {reset, pristine, submitting} */}
            <Form onSubmit={onSubmit} initialValues={initialDescriptionValues}>
                {({handleSubmit, dirty, dirtySinceLastSubmit, form}) => (
                    <form className="material-form material-form-flex" onSubmit={handleSubmit}>
                        <DescriptionForm dirty={dirty} dirtySinceLastSubmit={dirtySinceLastSubmit} form={form} isSubmitted={isSubmitted} canEditMeetings={hasPermissionEditMeetings} />
                    </form>
                )}
            </Form>
        </>
    );
};

MeetingDescriptionTab.propTypes = {
    meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setMeeting: PropTypes.func.isRequired
};

export default MeetingDescriptionTab;
