export const CLOUDINARY_ROUTES = {
    banner: '/img/banners/fi/',
    company: '/img/companies/',
    contact: '/img/contacts/',
    meeting: '/img/meetings/'
};

export const CLOUDINARY_TARGETS = {
    banner: 'banner',
    company: 'company',
    contact: 'contact'
};
