import React from 'react';
import {
    Col,
    Row
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { SelectField } from '../../shared/components/form/Select';
import CommonHelpers from '../../helpers/CommonHelpers';
import {TARGET_SEARCH_PARAMS, TARGET_STATUS} from '../../helpers/constants';
import FormField from '../../shared/components/form/FormField';

const MeetingsTableFilters = (props) => {
    const { t } = useTranslation('common');
    const { meetings, filterByDate, filterByLanguage, filterByStatus, tableType } = props;
    const { control } = useForm();

    const getAllOption = () => ({ value: '0', label: t('all') });
    const getStringAllOption = () => ({ value: '', label: t('all') });

    const getLanguages = () => {
        let languages = _.map(meetings, 'language');
        languages = languages.map(o => o.toLowerCase());
        languages = _.uniq(languages).sort();

        const options = languages.map(item => ({ 'value': item, 'label': CommonHelpers.getLanguageText(item) }));
        return _.concat([getStringAllOption()], options);
    };

    const getStatuses = () => {
        if (tableType !== TARGET_SEARCH_PARAMS.cancelled) {
            return _.concat([getStringAllOption()], [
                    {
                        'value': TARGET_STATUS.active,
                        'label': CommonHelpers.getTargetStatusText(TARGET_STATUS.active)
                    },
                    {
                        'value': TARGET_STATUS.planned,
                        'label': CommonHelpers.getTargetStatusText(TARGET_STATUS.planned)
                    }
                ]);
        }

        return [
            {
                'value': TARGET_STATUS.cancelled,
                'label': CommonHelpers.getTargetStatusText(TARGET_STATUS.cancelled)
            }
        ];
    };

    const handleDateChange = (item) => {
        filterByDate(item.value);
    };

    const handleLanguageChange = (item) => {
        filterByLanguage(item.value);
    };

    const handleStatusChange = (item) => {
       filterByStatus(item.value);
    };

    return (
            <Row className="mb-3 mt-3">
                <Col xs={12} lg={4}>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                             {t('status')}
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="statusFilter"
                                control={control}
                                component={SelectField}
                                options={getStatuses()}
                                defaultValue={getStringAllOption()}
                                onChange={handleStatusChange}
                            />
                        </div>
                    </div>
                </Col>

                <Col xs={12} lg={4}>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                             {t('language')}
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="languageFilter"
                                control={control}
                                component={SelectField}
                                options={getLanguages()}
                                defaultValue={getStringAllOption()}
                                onChange={handleLanguageChange}
                            />
                        </div>
                    </div>
                </Col>

            {tableType !== TARGET_SEARCH_PARAMS.cancelled && (
                <Col xs={12} lg={4}>
                    <div className="form__form-group">
                        <span className="form__form-group-label">
                             {t('date')}
                        </span>
                        <div className="form__form-group-field">
                            <FormField
                                name="dateFilter"
                                control={control}
                                component={SelectField}
                                options={[
                                    getStringAllOption(),
                                    { value: '3', label: '3kk' },
                                    { value: '6', label: '6kk' },
                                    { value: '12', label: '12kk' }
                                ]}
                                defaultValue={getStringAllOption()}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </Col>
            )}

            </Row>
    );
};

MeetingsTableFilters.propTypes = {
    filterByDate: PropTypes.func.isRequired,
    filterByLanguage: PropTypes.func.isRequired,
    filterByStatus: PropTypes.func.isRequired,
    meetings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tableType: PropTypes.string.isRequired
};


export default MeetingsTableFilters;
