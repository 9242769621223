import React, {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Col, Row, UncontrolledTooltip} from 'reactstrap';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import DataReactTable from '../Common/DataReactTable';
import MeetingHelpers from '../../helpers/meetings/MeetingHelpers';
import CommonHelpers from '../../helpers/CommonHelpers';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';
import MeetingsTableFilters from './MeetingsTableFilters';
import {TARGET_REGISTRATION_STATUS, TARGET_SEARCH_PARAMS} from '../../helpers/constants';
import RouteHelpers from '../../helpers/RouteHelpers';

function SubRowAsync({ row }) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const { t, i18n } = useTranslation(['meetings', 'common']);

    React.useEffect(async () => {
        try {
            const participants = await MeetingHelpers.getMeetingParticipants(row.original.id);

            // array 1 = cancelled registrations
            // array 2 = other registrations
            const partitionedByStatus = _.partition(participants, { 'status': TARGET_REGISTRATION_STATUS.cancelled });

            // date = registration date or created_at
            const registrationsWithDate = partitionedByStatus[1].map((item) => {
                if (item.registrationDate.length > 0) {
                    item.date = item.registrationDate;
                } else {
                    item.date = item.createdAt;
                }
                return item;
            });

            // order active registrations by registration date (or created_at if registration date is missing)
            const orderedRegistrations = _.orderBy(registrationsWithDate, ['date'], ['asc']);
            const result = _.concat(orderedRegistrations, partitionedByStatus[0]);

            setData(result);
            setLoading(false);
        } catch (e) {
            // return [];
        }
    }, []);

    const getStatusClass = (statusCode) => {
        if (statusCode === TARGET_REGISTRATION_STATUS.waiting) {
            return 'registration-in-progress';
        }
        if (statusCode === TARGET_REGISTRATION_STATUS.cancelled) {
            return 'line-through';
        }
        return '';
    };

    return (
        <>
            {loading ? (
                <div>
                    {t('loading', { ns: 'common' })}
                </div>
            ) : (
                <>
                    <h5 className="bold-text">{t('participants', {ns: 'common'})}</h5>
                    <table className="table sub-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('registrationDate', { ns: 'registration' })}</th>
                                <th>{t('name', { ns: 'common' })}</th>
                                <th>{t('email', { ns: 'common' })}</th>
                                <th>{t('company', { ns: 'common' })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.map((item, index) => {
                                return (
                                    <tr className={getStatusClass(item.status)}>
                                        <td>{item.status !== TARGET_REGISTRATION_STATUS.cancelled ? index + 1 : ''}</td>
                                        <td>{item.date ? DateTimeHelpers.formatDate(item.date) : ''}</td>
                                        <td>
                                            <Link to={RouteHelpers.getMeetingRegistrationRoute(item.id)}>
                                                {item.firstName ?? ''} {item.lastName ?? ''}
                                            </Link>
                                        </td>
                                        <td>{item.email ?? ''}</td>
                                        <td>{item.company ?? ''}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
                )}
        </>
    );
}

const MeetingsTable = (props) => {
    const { t, i18n } = useTranslation(['meetings', 'common']);
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const { tableType } = props;

    const createTableData = (m) => {
        const columns = [
            {
                Header: '#',
                accessor: 'idx',
                disableGlobalFilter: true
            },
            {
                Header: t('name', { ns: 'common' }),
                accessor: 'title',
            },
            {
                Header: t('date', { ns: 'common' }),
                accessor: 'dateAndTime',
                disableGlobalFilter: true,
            },
            {
                Header: t('location', { ns: 'common' }),
                accessor: 'locationAndCity',
                disableGlobalFilter: true,
            },
            {
                Header: t('status', { ns: 'common' }),
                accessor: 'statusText',
                disableGlobalFilter: true,
            },
            {
                Header: t('registrations', { ns: 'common' }),
                accessor: 'registrationStatus',
                disableGlobalFilter: true,
            },
            {
                // Make an expander cell
                Header: '', // No header
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <>
                        <span {...row.getToggleRowExpandedProps({title: ''})} className="mr-3" id={`ParticipantsTooltip${row.original.id}`}>
                            <span className="lnr lnr-users" />
                        </span>
                        <UncontrolledTooltip placement="bottom" target={`ParticipantsTooltip${row.original.id}`}>
                            {t('showParticipants', { ns: 'tooltips' })}
                        </UncontrolledTooltip>

                        <Link to={RouteHelpers.getMeetingRoute(row.original.id)}>
                            <span id={`LinkTooltip${row.original.id}`} className="table__button-icon lnr lnr-arrow-right" />
                            <UncontrolledTooltip placement="bottom" target={`LinkTooltip${row.original.id}`}>
                                {t('showDetails', { ns: 'tooltips' })}
                            </UncontrolledTooltip>
                        </Link>
                    </>
                ),
            },
        ];



            m.map((item, index) => {
                // item.title = (<NavLink to={RouteHelpers.getMeetingRoute(item.id)}>{item.title}</NavLink>);

                // Date and time
                const startTime = DateTimeHelpers.formatTime(item.startTime);
                const endTime = DateTimeHelpers.formatTime(item.endTime);
                item.dateAndTime = `${DateTimeHelpers.formatDate(item.startDate)} ${startTime}`;
                if (endTime) {
                    item.dateAndTime = `${item.dateAndTime} - ${endTime}`;
                }

                // Location and city
                let location = item.location ?? '';
                if (item.city.length > 0) {
                    if (location.length > 0) {
                        location += ', ';
                    }
                    location += item.city;
                }

                item.locationAndCity = location;

                // status
                item.statusText = CommonHelpers.getTargetStatusText(item.status);

                // registrations
                item.registrationStatus = item.registrations;
                if (item.maxRegistrations) {
                    item.registrationStatus = `${item.registrationStatus} / ${item.maxRegistrations}`;
                }

                // index
                item.idx = index + 1;

                return item;
            });


        return { tableHeaderData: columns, tableRowsData: m };
    };

    const renderRowSubComponent = React.useCallback(
         ({ row }) => (
            <SubRowAsync row={row} />
        ),
        []
    );

    const setTimeToMidnight = (dateTime) => {
        dateTime.setHours(0);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
        return dateTime;
    };

    const applyFilters = () => {
        let result = meetings;

        // status
        if (selectedStatus !== '') {
            result = _.filter(result, { 'status': selectedStatus });
        }

        // language
        if (selectedLanguage !== '') {
            result = _.filter(result, function (o) {
                return o.language.toLowerCase() === selectedLanguage.toLowerCase();
            });
        }

        // time
        if (selectedDate !== '') {
            const months = Number(selectedDate);

           if (tableType === TARGET_SEARCH_PARAMS.upcoming) {
                const endDate = setTimeToMidnight(addMonths(new Date(), months));

                result = _.filter(result, function (r) {
                    const meetingDate = setTimeToMidnight(new Date(r.startDate));
                    return meetingDate <= endDate;
                });
            } else if (tableType === TARGET_SEARCH_PARAMS.past) {
               const startDate = setTimeToMidnight(subMonths(new Date(), months));

                result = _.filter(result, function (r) {
                    const meetingDate = setTimeToMidnight(new Date(r.startDate));
                    return meetingDate >= startDate;
                });
            }
        }

        setFilteredMeetings(result);
    };

    const filterByDate = (dateRange) => {
        setSelectedDate(dateRange);
    };

    const filterByLanguage = (languageCode) => {
        setSelectedLanguage(languageCode);
    };

    const filterByStatus = (status) => {
        setSelectedStatus(status);
    };

    const getMeetings = async () => {
        const meetingsData = await MeetingHelpers.getMeetings(tableType);
        setMeetings(meetingsData);
        setFilteredMeetings(meetingsData);
    };

    useEffect(() => {
        // Get meetings
        getMeetings();
    }, []);

    useEffect(() => {
        // update table when filters change
        applyFilters();
    }, [selectedDate, selectedLanguage, selectedStatus]);

    return (
            <Col>
                {(meetings.length > 0) && (
                    <>
                        <Row>
                            <Col>
                                <MeetingsTableFilters
                                    meetings={meetings}
                                    filterByDate={filterByDate}
                                    filterByLanguage={filterByLanguage}
                                    filterByStatus={filterByStatus}
                                    tableType={tableType}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <DataReactTable
                                    reactTableData={createTableData(filteredMeetings)}
                                    searchEngineOn={false}
                                    renderRowSubComponent={renderRowSubComponent}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Col>
    );
};

MeetingsTable.propTypes = {
    row: PropTypes.shape({
        getToggleRowExpandedProps: PropTypes.func,
        original: PropTypes.shape({
            id: PropTypes.number
        }),
        isExpanded: PropTypes.func
    }),
    tableType: PropTypes.string.isRequired
};

MeetingsTable.defaultProps = {
    row: {
    }
};

SubRowAsync.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired
        })
    }).isRequired
};

export default MeetingsTable;
