import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Field, useForm} from 'react-final-form';
import {Button, ButtonToolbar, Col} from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import {DELIVERY_TYPE_CODE, LANGUAGE_CODE, TARGET_STATUS} from '../../../helpers/constants';
import RequiredField from '../../../shared/components/form/RequiredField';
import CommonHelpers from '../../../helpers/CommonHelpers';
import {
    required,
    dateValid,
    timeValid,
    validStringInteger,
    timeEarlier,
    timeLater,
    requiredIfAnotherValueGiven
} from '../../../helpers/forms/validation';
import MeetingHelpers from '../../../helpers/meetings/MeetingHelpers';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import materialTextField from '../../../shared/components/form/materialTextField';

const InfoForm = ({dirty, dirtySinceLastSubmit, form, nroOfParticipants, isSubmitted, canEditMeetings}) => {
    const {t} = useTranslation(['meetings', 'common', 'forms']);
    const {
        getFieldState
    } = useForm();

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const isRegistrationClosed = () => {
        // Cancelled
        if (getFieldState('status') !== undefined && getFieldState('status').value !== undefined) {
            if (getFieldState('status').value.value === TARGET_STATUS.cancelled) {
                return true;
            }
        }

        // Full
        if (getFieldState('max') !== undefined) {
            if (getFieldState('max').value && getFieldState('max').value <= nroOfParticipants) {
                return true;
            }
        }

        // Registration closed
        if (getFieldState('registrationClosed') !== undefined) {
            if (getFieldState('registrationClosed').value) {
                return true;
            }
        }

        return false;
    };

    const getRequiredFieldLabel = (label) => {
        return <>{label}<RequiredField /></>;
    };

    return (
        <>
            <Col xs={12}>
                <Field
                    name="title"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('name', {ns: 'common'}))}
                    validate={required}
                    maxLength={255}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="startDate"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('startDate', {ns: 'common'}))}
                    placeholder="pp.kk.vvvv"
                    validate={composeValidators(required, dateValid)}
                    maxLength={10}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="startTime"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('startTime', {ns: 'common'}))}
                    placeholder="00:00"
                    validate={composeValidators(required, timeValid, timeEarlier(getFieldState('endTime')?.value, t('endTime', {ns: 'common'})))}
                    maxLength={5}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="endTime"
                    component={materialTextField}
                    label={t('endTime', {ns: 'common'})}
                    placeholder="00:00"
                    validate={composeValidators(timeValid, timeLater(getFieldState('startTime')?.value, t('startTime', {ns: 'common'})))}
                    maxLength={5}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="max"
                    component={materialTextField}
                    label={t('labels.maxParticipants', {ns: 'forms'})}
                    validate={composeValidators(validStringInteger)}
                    maxLength={10}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={6}>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <Field
                            name="lastRegistrationDate"
                            component={materialTextField}
                            label={t('labels.lastRegistrationDate', {ns: 'forms'})}
                            placeholder="pp.kk.vvvv"
                            validate={composeValidators(dateValid, requiredIfAnotherValueGiven(getFieldState('lastRegistrationTime')?.value, t('time', {ns: 'common'})))}
                            maxLength={10}
                            disabled={!canEditMeetings}
                        />
                        <Field
                            name="lastRegistrationTime"
                            component={materialTextField}
                            label={t('labels.lastRegistrationTime', {ns: 'forms'})}
                            placeholder="mm:tt"
                            validate={composeValidators(timeValid, requiredIfAnotherValueGiven(getFieldState('lastRegistrationDate')?.value, t('date', {ns: 'common'})))}
                            maxLength={5}
                            disabled={!canEditMeetings}
                        />
                    </div>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                    <span className="material-form__label">
                          {getRequiredFieldLabel(t('deliveryType', {ns: 'common'}))}
                    </span>
                    <div className="form__form-group-field">
                        <Field
                            name="deliveryType"
                            component={materialTextField}
                            validate={required}
                            select
                            disabled={!canEditMeetings}
                        >
                            <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.classRoom}>
                                {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.classRoom)}
                            </MenuItem>
                            <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.online}>
                                {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.online)}
                            </MenuItem>
                            <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.hybrid}>
                                {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.hybrid)}
                            </MenuItem>
                        </Field>
                    </div>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                    <span className="material-form__label">
                        {getRequiredFieldLabel(t('status', {ns: 'common'}))}
                    </span>

                    <div className="form__form-group-field">
                        <Field
                            name="status"
                            component={materialTextField}
                            select
                            disabled={!canEditMeetings}
                        >
                            <MenuItem className="material-form__option" value={TARGET_STATUS.active}>
                                {CommonHelpers.getTargetStatusText(TARGET_STATUS.active)}
                            </MenuItem>
                            <MenuItem className="material-form__option" value={TARGET_STATUS.planned}>
                                {CommonHelpers.getTargetStatusText(TARGET_STATUS.planned)}
                            </MenuItem>
                            <MenuItem className="material-form__option" value={TARGET_STATUS.cancelled}>
                                {CommonHelpers.getTargetStatusText(TARGET_STATUS.cancelled)}
                            </MenuItem>
                        </Field>

                    </div>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                     <span className="material-form__label">
                        {t('language', {ns: 'common'})}
                    </span>
                    <div className="form__form-group-field">
                        <Field
                            name="language"
                            component={materialTextField}
                            select
                            disabled={!canEditMeetings}
                        >
                            <MenuItem className="material-form__option" value={LANGUAGE_CODE.fi}>
                                {CommonHelpers.getLanguageText(LANGUAGE_CODE.fi)}
                            </MenuItem>
                            <MenuItem className="material-form__option" value={LANGUAGE_CODE.en}>
                                {CommonHelpers.getLanguageText(LANGUAGE_CODE.en)}
                            </MenuItem>
                        </Field>
                    </div>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="location"
                    component={materialTextField}
                    label={t('location', {ns: 'common'})}
                    maxLength={255}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="address"
                    component={materialTextField}
                    label={t('labels.address', {ns: 'forms'})}
                    maxLength={255}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="city"
                    component={materialTextField}
                    label={t('labels.city', {ns: 'forms'})}
                    maxLength={255}
                    disabled={!canEditMeetings}
                />
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                    <div className="form__form-group-field inline-toggle">
                        <span className="form__form-group-label pt-3 pr-3">
                            {t('paidMeeting', {ns: 'meetings'})}
                        </span>

                        <Field
                            name="paid"
                            component={renderToggleButtonField}
                            disabled={!canEditMeetings}
                        />
                    </div>
                </div>
            </Col>


            <Col xs={12} lg={3}>
                <div className="form__form-group">
                    <div className="form__form-group-field inline-toggle">
                        <span className="form__form-group-label pt-3 pr-3">
                            {t('labels.registrationClosed', {ns: 'forms'})}
                        </span>

                        <Field
                            name="registrationClosed"
                            component={renderToggleButtonField}
                            disabled={!canEditMeetings}
                        />
                    </div>
                </div>
            </Col>

            {isRegistrationClosed() && (
                <Col xs={12}>
                    <Field
                        name="registrationClosedReason"
                        component={materialTextField}
                        label={t('labels.reasonForClosingTheRegistration', {ns: 'forms'})}
                        maxLength={255}
                        disabled={!canEditMeetings}
                    />
                </Col>
            )}

            {canEditMeetings && (
                <Col xs={12} className="mt-3">
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">
                            {t('buttons.save', {ns: 'forms'})}
                        </Button>
                        {((dirty && !isSubmitted) || (dirtySinceLastSubmit && isSubmitted)) && (
                            <Button type="button" onClick={form.reset} disabled={form.pristine || form.submitting}>
                                {t('buttons.cancel', {ns: 'forms'})}
                            </Button>
                        )}
                    </ButtonToolbar>
                </Col>
            )}
        </>
    );
};

InfoForm.propTypes = {
    form: PropTypes.oneOfType([PropTypes.object]).isRequired,
    dirty: PropTypes.bool.isRequired,
    dirtySinceLastSubmit: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    nroOfParticipants: PropTypes.number.isRequired,
    canEditMeetings: PropTypes.bool.isRequired
};

export default InfoForm;
