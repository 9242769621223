import React, {useState} from 'react';
import {
    Col, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import DataReactTable from '../../Common/DataReactTable';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';
import InvoiceAddressHelpers from '../../../helpers/Order/InvoiceAddressHelpers';
import NewInvoiceAddressModal from './modals/NewInvoiceAddressModal';
import EditInvoiceAddressModal from './modals/EditInvoiceAddressModal';
import Modal from '../../../shared/components/Modal';

const CompanyInvoiceAddresses = ({invoiceAddresses, names, setUpdatedInvoiceAddress, removeInvoiceAddress}) => {
    const { t } = useTranslation(['companies', 'common', 'order']);
    const [selectedForEdit, setSelectedForEdit] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalError, setDeleteModalError] = useState('');
    const hasPermissionEditCompanies = PermissionHelpers.hasPermission(PERMISSIONS.manage_companies);

    // Edit modal
    const showEditAddressForm = (row) => {
        setSelectedForEdit({
            id: row.original.id,
            nameId: row.original.company.nameId,
            address: row.original.address,
            city: row.original.city,
            invoiceCompany: row.original.invoiceCompany,
            country: row.original.country,
            eInvoiceAddress: row.original.eInvoiceAddress,
            eInvoiceCode: row.original.eInvoiceCode,
            eInvoiceEmail: row.original.eInvoiceEmail,
            eInvoiceOperator: row.original.eInvoiceOperator,
            identifier: row.original.identifier,
            info: row.original.info,
            postalCode: row.original.postalCode,
        });
        toggleEditModal();
    };

    const toggleEditModal = () => {
        setShowEditModal(prevShowEditModal => !prevShowEditModal);
    };

    // Delete modal
    // Delete modal
    const showDeleteAddressModal = (row) => {
        setSelectedForDelete({
            id: row.original.id
        });
        toggleDeleteModal();
    };

    const toggleDeleteModal = () => {
        setDeleteModalError('');
        setShowDeleteModal(prevShowDeleteModal => !prevShowDeleteModal);
    };

    const deleteInvoiceAddress = async () => {
        try {
            const deleted = await InvoiceAddressHelpers.deleteInvoiceAddress(selectedForDelete.id);
            removeInvoiceAddress(selectedForDelete.id);
            toggleDeleteModal();
        } catch (e) {
            setDeleteModalError(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    const columns = React.useMemo(() => [
        {
            Header: '#',
            disableGlobalFilter: true,
            Cell: ({ row }) => (
                <span>{row.index + 1}</span>
            )
        },
        {
            Header: t('invoicingAddress', { ns: 'order' }),
            accessor: 'formattedAddress',
            disableGlobalFilter: true
        },
        {
            Header: t('eInvoicingAddress', { ns: 'order' }),
            accessor: 'formattedEAddress',
            disableGlobalFilter: true,
        },
        {
            Header: t('company', { ns: 'common' }),
            accessor: 'companyName',
             // disableGlobalFilter: true,
            canFilter: true,
            // Filter: SelectColumnFilter,
            // filter: 'includes'
            /* Filter: ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
                console.log('preFilteredRows', preFilteredRows);
                console.log('id', id);
                console.log('filterValue', filterValue);
                console.log('setFilter', setFilter);
                return (
                    <div>asdasdas</div>
                );
            }, */
        },
        {
            Header: '',
            id: 'editOrDelete',
            disableGlobalFilter: true,
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    {hasPermissionEditCompanies && (
                        <>
                            <button type="button" onClick={() => showEditAddressForm(row)} className="table__button table__button">
                                <span className="table__button-icon lnr lnr-pencil" />
                            </button>
                            <button
                                type="button"
                                onClick={() => showDeleteAddressModal(row)}
                                className="table__button table__button"
                            >
                                <span className="table__button-icon lnr lnr-trash" />
                            </button>
                        </>
                    )}
                </>
            ),
        },
    ], []);

    const createTableData = (data) => {
        const d = data.map((item, index) => {
            // Invoice address
            const addressArr = [
                InvoiceAddressHelpers.resolveInvoiceCompanyName(item),
                InvoiceAddressHelpers.resolveBusinessId(item),
                item.identifier ?? '',
                item.address ?? '',
                item.postalCode ?? '',
                item.city ?? '',
                item.country ?? ''
            ];

            const addressedWithoutEmptyValues = _.filter(addressArr, el => el.length > 0);
            item.formattedAddress = addressedWithoutEmptyValues.map(line => <div>{line}</div>);

            // E-invoice address
            const eAddressArr = [
                item.eInvoiceCode ?? '',
                item.eInvoiceOperator ?? '',
                item.eInvoiceAddress ?? '',
                item.eInvoiceEmail ?? ''
            ];

            const eAddressedWithoutEmptyValues = _.filter(eAddressArr, el => el.length > 0);
            item.formattedEAddress = eAddressedWithoutEmptyValues.map(line => <div>{line}</div>);

            // Company name
            item.companyName = item.company && item.company.name ? item.company.name : '';

            return item;
        });

        return { tableHeaderData: columns, tableRowsData: d };
    };

    /**
     * Primary address with bold text
     * @param row
     * @returns {{style: {fontWeight: (string)}}}
     */
    const getTableRowProps = ({ row }) => {
        return {
            style: {
                fontWeight: row.original.primary ? 'bold' : '',
            },
        };
    };

    return (
        <>
            <Row>
                <Col>
                    <NewInvoiceAddressModal
                        companyNames={names}
                        setUpdatedInvoiceAddress={setUpdatedInvoiceAddress}
                    />

                    <Modal
                        show={showDeleteModal}
                        color="danger"
                        message={t('modals.deleteInvoiceAddressConfirmation', { ns: 'companies' })}
                        toggleModal={toggleDeleteModal}
                        okBtn={t('buttons.delete', { ns: 'common' })}
                        handleOk={deleteInvoiceAddress}
                        alertDangerText={deleteModalError}
                    />

                    <EditInvoiceAddressModal
                        companyNames={names}
                        invoiceAddress={selectedForEdit}
                        toggleModal={toggleEditModal}
                        showModal={showEditModal}
                        setUpdatedInvoiceAddress={setUpdatedInvoiceAddress}
                    />
                </Col>
            </Row>

            {(invoiceAddresses.length > 0) && (
                <Row>
                    <Col>
                        <DataReactTable
                            reactTableData={createTableData(invoiceAddresses)}
                            searchEngineOn={false}
                            getTableRowProps={getTableRowProps}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

CompanyInvoiceAddresses.propTypes = {
    /* column: PropTypes.shape({
        filterValue: PropTypes.string.isRequired,
        setFilter: PropTypes.func.isRequired,
        preFilteredRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        id: PropTypes.number.isRequired
    }).isRequired, */
    invoiceAddresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    names: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    removeInvoiceAddress: PropTypes.func.isRequired,
    row: PropTypes.shape({
        index: PropTypes.string,
        original: PropTypes.shape({
            id: PropTypes.number,
            primary: PropTypes.bool
        }),
    }),
    setUpdatedInvoiceAddress: PropTypes.func.isRequired
};

CompanyInvoiceAddresses.defaultProps = {
    row: {
        index: '',
        original: {
            id: '',
            primary: false
        }
    }
};

export default CompanyInvoiceAddresses;
