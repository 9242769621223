import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PropTypes from 'prop-types';
import FormField from './FormField';

const PasswordField = React.forwardRef(({
                                            input,
                                            meta,
                                            keyIcon,
                                            showForgotPassword,
                                            ...props
                                        }, ref) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const { t } = useTranslation('login');
    return (
        <>
            <div className="form__form-group-field">
                {keyIcon && (
                    <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div>
                )}
                <FormField
                    {...props}
                    {...input}
                    type={passwordShown ? 'text' : 'password'}
                    ref={ref}
                />
                <button
                    className={`form__form-group-button${passwordShown ? ' active' : ''}`}
                    type="button"
                    onClick={() => setPasswordShown(shown => !shown)}
                ><EyeIcon />
                </button>

            </div>
            <div className="account__forgot-password">
                <div>
                    {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                </div>
                <div className="text-right">
                    {showForgotPassword && <NavLink className="" to="/reset_password">{t('forgotPassword')}</NavLink>}
                </div>
            </div>
        </>
    );
});

PasswordField.propTypes = {
    input: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
    keyIcon: PropTypes.bool,
    showForgotPassword: PropTypes.bool
};

PasswordField.defaultProps = {
    keyIcon: false,
    showForgotPassword: false
};

export default PasswordField;
