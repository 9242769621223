import { connect } from 'react-redux';
import { getPermissions } from '../../redux/reducers/userReducer';
import store from '../../containers/App/store';

class PermissionHelpers {
    static hasPermission(permissionName) {
        const permissions = getPermissions(store.getState());
        if (permissions) {
            return permissions.includes(permissionName);
        }

        return false;
    }
}

export default connect(
    null,
    { getPermissions }
)(PermissionHelpers);
