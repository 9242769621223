import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Card,
    CardBody,
    Col, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import OrderHelpers from '../../helpers/Order/OrderHelpers';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';
import LanguageHelpers from '../../helpers/LanguageHelpers';
import {ORDER_STATUS} from '../../helpers/constants';
import InvoiceAddressHelpers from '../../helpers/Order/InvoiceAddressHelpers';

const Order = (props) => {
    const {orderId} = props;
    const {t} = useTranslation(['order', 'common']);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [order, setOrder] = useState({});
    const [invoiceAddress, setInvoiceAddress] = useState({});
    const [productPackages, setProductPackages] = useState([]);
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);

    useEffect(() => {
        async function getOrder() {
            if (hasPermissionShowMeetings && orderId) {
                try {
                    const response = await OrderHelpers.getOrder(orderId);
                    setOrder(response.order);
                    setInvoiceAddress(response.invoiceAddress);
                    setProductPackages(response.productPackages);
                    setDataLoaded(true);
                } catch (error) {
                    setDataLoaded(true);
                }
            } else if (typeof orderId !== 'undefined') {
                setDataLoaded(true);
            }
        }

        getOrder();
    }, [orderId]);

    const getOrderRow = (item, isPackage) => {
        const itemClass = isPackage ? 'bold-text' : 'order-product-row';
        let nameInfo = '';

        // Product package target
        if (isPackage && item.target) {
            nameInfo = `(${DateTimeHelpers.formatDate(item.target.startDate)})`;
        }

        // Product group and type
        if (!isPackage && item.group?.name && item.type?.name) {
            const group = LanguageHelpers.getLocaleNamedColumn(item.group?.name);
            const type = LanguageHelpers.getLocaleNamedColumn(item.type?.name);
            nameInfo = `(${group} - ${type})`;
        }

        const rowClass = order.status === ORDER_STATUS.cancelled ? 'line-through' : '';

        return (
            <tr className={rowClass}>
                <td className={itemClass}>{item.code}</td>
                <td className={itemClass}>{item.name} {nameInfo}</td>
                <td className={itemClass}>{parseFloat(item.amount).toFixed(0)}</td>
                <td className={itemClass}>{item.price} €</td>
                <td className={itemClass}>{item.campaignCode}</td>
                <td className={itemClass}>{parseFloat(item.amount * item.price).toFixed(2)} €</td>
            </tr>
        );
    };

    const invoiceBusinessId = InvoiceAddressHelpers.resolveBusinessId(invoiceAddress);

    return (
        <>
            {hasPermissionShowMeetings ? (
                <Card>
                    <CardBody className="dashboard__card-widget">
                        {(dataLoaded && order.orderNumber) && (
                            <>
                                <div>
                                    <Row>
                                        <Col md={12} lg={6}>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="card__title">
                                                        <h5 className="bold-text">
                                                            {t('order', {ns: 'order'})}
                                                        </h5>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4}>
                                                    <b>{t('orderNumber', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {order.orderNumber}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('orderDate', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {DateTimeHelpers.formatDate(order.createdAt)}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('status', {ns: 'common'})}</b>
                                                </Col>
                                                <Col md={12} lg={8} className={OrderHelpers.getOrderStatusClass(order.status)}>
                                                    {OrderHelpers.getOrderStatusText(order.status)}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('orderer', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {order.contact?.firstName} {order.contact?.lastName}, {order.company?.name} ({order.contact?.email})
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('paymentType', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {OrderHelpers.getOrderPaymentTypeText(order.paymentType)}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('paymentStatus', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8} className={OrderHelpers.getOrderPaymentStatusClass(order.paymentStatus)}>
                                                    {OrderHelpers.getOrderPaymentStatusText(order.paymentStatus)}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('poNumber', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {order.poNumber}
                                                </Col>

                                                <Col md={12} lg={4}>
                                                    <b>{t('reference', {ns: 'order'})}</b>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    {order.reference}
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={12} lg={6} className="mt-sm-4 mt-lg-0">
                                                <div className="card__title">
                                                    <h5 className="bold-text">
                                                        {t('invoiceInformation', {ns: 'order'})}
                                                    </h5>
                                                </div>
                                                <div>
                                                    <Row>
                                                        <Col md={12} lg={6}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    {InvoiceAddressHelpers.resolveInvoiceCompanyName(invoiceAddress)}
                                                                </Col>
                                                                {invoiceBusinessId.length > 0 && (
                                                                    <Col md={12}>
                                                                        {t('businessId', {ns: 'common'})}: {invoiceBusinessId}
                                                                    </Col>
                                                                )}
                                                                <Col md={12}>
                                                                    {invoiceAddress.identifier}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.address}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.postalCode}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.city}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.country}
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col md={12} lg={6}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    {invoiceAddress.eInvoiceAddress}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.eInvoiceOperator}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.eInvoiceCode}
                                                                </Col>
                                                                <Col md={12}>
                                                                    {invoiceAddress.eInvoiceEmail}
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col md={12} lg={6} className="mt-3">
                                                            {invoiceAddress.info}
                                                        </Col>
                                                    </Row>
                                                </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-5">
                                    <Row>
                                        <Col md={12}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>{t('code', {ns: 'common'})}</th>
                                                        <th>{t('name', {ns: 'common'})}</th>
                                                        <th>{t('amount', {ns: 'common'})}</th>
                                                        <th>{t('price', {ns: 'common'})}</th>
                                                        <th>{t('campaign', {ns: 'order'})}</th>
                                                        <th>{t('total', {ns: 'order'})}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productPackages.map((item, index) => {
                                                        return (
                                                            <>
                                                                {getOrderRow(item, true)}
                                                                {item.products.map((product, idx) => {
                                                                    return (
                                                                        <>
                                                                            {getOrderRow(product, false)}
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        );
                                                    })}

                                                    <tr className="order-totals">
                                                        <td colSpan="4"></td>
                                                        <td>{t('totalWithoutVat', {ns: 'order'})}</td>
                                                        <td>{order.totalAmount} €</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"></td>
                                                        <td>{t('vatPercent', {ns: 'order', percent: parseFloat(order.vatPercent).toFixed(0)})}</td>
                                                        <td>{order.vatAmount} €</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"></td>
                                                        <td>{t('totalWithVat', {ns: 'order'})}</td>
                                                        <td>{(parseFloat(order.totalAmount) + parseFloat(order.vatAmount)).toFixed(2)} €</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                        {(dataLoaded && typeof order.orderNumber === 'undefined') && (
                            <div>{t('dataNotFound', {ns: 'common'})}</div>
                        )}
                    </CardBody>
                </Card>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

Order.propTypes = {
    orderId: PropTypes.number.isRequired
};

export default Order;
