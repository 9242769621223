import { Button, ButtonToolbar, Col, Modal, Row } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Field, useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { getDirection } from '../../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordButton = ({ rtl, changePassword }) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation(['profile', 'common', 'forms']);

    const toggleModal = () => {
        setShow(prevShow => !prevShow);
    };

    const handleSave = (values) => {
        toggleModal();
        changePassword(values);
    };

    return (
        <div>
            <Button
                className="icon"
                color="primary"
                outline
                onClick={toggleModal}
            >
                <p><KeyVariantIcon /> {t('profileMain.changePassword')}</p>
            </Button>
            <Modal
                isOpen={show}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleModal}
                    />
                    <KeyVariantIcon className="modal__title-icon" />
                    <h4 className="text-modal  modal__title">{t('profileMain.changePassword')}</h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave}>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <ChangePasswordForm toggleModal={toggleModal} />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

ChangePasswordButton.propTypes = {
    rtl: RTLProps.isRequired,
    changePassword: PropTypes.func.isRequired
};

export default connect(state => ({
    rtl: getDirection(state),
}))(ChangePasswordButton);
