import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeUserImage } from '../../../../redux/actions/userActions';
import UserHelpers from '../../../../helpers/users/UserHelpers';
import ChangeImageButton from '../../../Common/ChangeImageButton';
import {CLOUDINARY_TARGETS} from '../../../../helpers/Cloudinary/CloudinaryConstants';

const ChangeUserImageButton = ({ setError, changeImage }) => {
    const {t} = useTranslation('profile');

    const saveNewImage = (imageName) => {
        const updated = UserHelpers.updateUserImage(imageName);

        updated.then(() => {
            changeImage(imageName);
            setError('');
        }).catch(() => {
            setError('profileMain.imageUploadFailed');
        });
    };

    return (
        <ChangeImageButton
            setError={setError}
            changeImage={saveNewImage}
            targetType={CLOUDINARY_TARGETS.contact}
        />
    );
};

ChangeUserImageButton.propTypes = {
    setError: PropTypes.func.isRequired,
    changeImage: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        changeImage: changeUserImage
    }
)(ChangeUserImageButton);
