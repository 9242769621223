import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Col, Container, Row
} from 'reactstrap';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import MainCompanyProfile from './components/MainCompanyProfile';
import CompanyProfileTabs from './components/CompanyProfileTabs';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../helpers/roles/Permissions';
import CompanyHelpers from '../../helpers/Company/CompanyHelpers';
import InvoiceAddressHelpers from '../../helpers/Order/InvoiceAddressHelpers';

const CompanyProfile = () => {
    const { t } = useTranslation(['common']);
    const {id} = useParams();
    const [company, setCompany] = useState([]);
    const [invoiceAddresses, setInvoiceAddresses] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const hasPermissionShowCompanies = PermissionHelpers.hasPermission(PERMISSIONS.show_companies);

    useEffect(() => {
        async function getCompany() {
            if (hasPermissionShowCompanies) {
                try {
                    const companyData = await CompanyHelpers.getCompany(id);
                    setCompany(companyData);

                    const invoiceAddressesData = await InvoiceAddressHelpers.getCompanyInvoiceAddresses(id);
                    setInvoiceAddresses(invoiceAddressesData);

                    setDataLoaded(true);
                } catch (e) {
                    setDataLoaded(true);
                }
            }
        }

        getCompany();
    }, [hasPermissionShowCompanies]);

    const setUpdatedNameData = (newData) => {
        const clonedCompanyData = _.cloneDeep(company);

        if (clonedCompanyData.names) {
            const oldNameIndex = _.findIndex(clonedCompanyData.names, { 'id': newData.id });

            if (oldNameIndex >= 0 && clonedCompanyData.names[oldNameIndex]) {
                // Update data if name already exists
                clonedCompanyData.names[oldNameIndex] = newData;
            } else {
               // Add new name
                clonedCompanyData.names.push(newData);
            }

            setCompany(clonedCompanyData);
        }
    };

    const setUpdatedDomain = (newData) => {
        const clonedCompanyData = _.cloneDeep(company);

        if (clonedCompanyData.domains) {
            const oldDomainIndex = _.findIndex(clonedCompanyData.domains, { 'id': newData.id });

            if (oldDomainIndex >= 0 && clonedCompanyData.domains[oldDomainIndex]) {
                // Update data if name already exists
                clonedCompanyData.domains[oldDomainIndex] = newData;
            } else {
                // Add new name
                clonedCompanyData.domains.push(newData);
            }

            setCompany(clonedCompanyData);
        }
    };

    const removeDomain = (domainId) => {
        const clonedCompanyData = _.cloneDeep(company);
        if (clonedCompanyData.domains) {
            _.remove(clonedCompanyData.domains, function (item) {
                return item.id === domainId;
            });
            setCompany(clonedCompanyData);
        }
    };

    const setUpdatedInvoiceAddress = (newData) => {
        const clonedAddressesData = _.cloneDeep(invoiceAddresses);
        const oldAddressIndex = _.findIndex(clonedAddressesData, { 'id': newData.id });

        if (oldAddressIndex >= 0 && clonedAddressesData[oldAddressIndex]) {
            // Update data if invoice address already exists
            clonedAddressesData[oldAddressIndex] = newData;
        } else {
            // Add new name
            clonedAddressesData.push(newData);
        }

        setInvoiceAddresses(clonedAddressesData);
    };

    const removeInvoiceAddress = (invoiceAddressId) => {
        const clonedAddressesData = _.cloneDeep(invoiceAddresses);
        if (clonedAddressesData) {
            _.remove(clonedAddressesData, function (item) {
                return item.id === invoiceAddressId;
            });
            setInvoiceAddresses(clonedAddressesData);
        }
    };

    const changeLogo = (imageName) => {
        const clonedCompanyData = _.cloneDeep(company);
        if (clonedCompanyData) {
            clonedCompanyData.logo = imageName;
        }
        setCompany(clonedCompanyData);
    };

    return (
        <Container>
            <div className="profile">
                <Row>
                    {(dataLoaded && !company.id) && (
                        <Col xs={12}>
                            {t('dataNotFound', {ns: 'common'})}
                        </Col>
                    )}

                    {(dataLoaded && company.id) && (
                        <>
                            <Col md={12} lg={12} xl={4}>
                                <Row>
                                    <MainCompanyProfile company={company} changeLogo={changeLogo} />
                                </Row>
                            </Col>
                            <CompanyProfileTabs
                                company={company}
                                invoiceAddresses={invoiceAddresses}
                                setUpdatedDomain={setUpdatedDomain}
                                setUpdatedName={setUpdatedNameData}
                                removeDomain={removeDomain}
                                setUpdatedInvoiceAddress={setUpdatedInvoiceAddress}
                                removeInvoiceAddress={removeInvoiceAddress}
                            />
                        </>
                    )}
                </Row>
            </div>
        </Container>
    );
};

export default CompanyProfile;
