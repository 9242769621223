import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlexLayout } from 'react-table';
import ReactTableConstructor from './components/ReactTableConstructor';
import ReactTableCell from './components/ReactTableCell';
import ReactTableCellEditable from './components/ReactTableEditableCell';

const ReactTableBase = ({
                            tableConfig,
                            columns,
                            data,
                            updateDraggableData,
                            updateEditableData,
                            renderRowSubComponent,
                            fetchData,
                            manualPageOptions,
                            defaultPageSize,
                            getTableRowProps
                        }) => {
    const {
        isEditable,
        isResizable,
        isSortable,
        withDragAndDrop,
        withPagination,
        withSearchEngine,
        manualPageSize,
    } = tableConfig;
    const [filterValue, setFilterValue] = useState(null);

    let initialPageSize = 10;
    if (defaultPageSize && defaultPageSize > 0) {
        initialPageSize = defaultPageSize;
    } else if (manualPageSize && manualPageSize[0]) {
        initialPageSize = manualPageSize[0];
    }

    const tableOptions = {
        columns,
        data,
        updateDraggableData,
        updateEditableData,
        setFilterValue,
        defaultColumn: {},
        isEditable,
        withDragAndDrop: withDragAndDrop || false,
        dataLength: data.length,
        autoResetPage: false,
        disableSortBy: !isSortable,
        manualSortBy: !isSortable,
        manualGlobalFilter: !withSearchEngine,
        manualPagination: !withPagination,
        initialState: {
            pageIndex: 0,
            pageSize: initialPageSize,
            globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
        },
    };

    let tableOptionalHook = [];
    if (isResizable) tableOptionalHook = [useFlexLayout];
    if (withSearchEngine) {
        tableOptions.defaultColumn = {
            Cell: ReactTableCell,
        };
    }
    if (isEditable) {
        tableOptions.defaultColumn = {
            Cell: ReactTableCellEditable,
        };
    }

    return (
        <ReactTableConstructor
            key={isResizable || isEditable ? 'modified' : 'common'}
            tableConfig={tableConfig}
            tableOptions={tableOptions}
            tableOptionalHook={tableOptionalHook}
            renderRowSubComponent={renderRowSubComponent}
            fetchData={fetchData}
            manualPageOptions={manualPageOptions}
            getTableRowProps={getTableRowProps}
        />
    );
};

ReactTableBase.propTypes = {
    defaultPageSize: PropTypes.number,
    fetchData: PropTypes.func,
    getTableRowProps: PropTypes.func,
    manualPageOptions: PropTypes.arrayOf(PropTypes.number),
    tableConfig: PropTypes.shape({
        isEditable: PropTypes.bool,
        isResizable: PropTypes.bool,
        isSortable: PropTypes.bool,
        withDragAndDrop: PropTypes.bool,
        withPagination: PropTypes.bool,
        withSearchEngine: PropTypes.bool,
        manualPageSize: PropTypes.arrayOf(PropTypes.number),
    }),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.shape()),
    renderRowSubComponent: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
};

ReactTableBase.defaultProps = {
    defaultPageSize: '',
    fetchData: () => {},
    getTableRowProps: () => {},
    manualPageOptions: [],
    renderRowSubComponent: () => {},
};

ReactTableBase.defaultProps = {
    tableConfig: {
        isEditable: false,
        isResizable: false,
        isSortable: false,
        withDragAndDrop: false,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [],
    },
    columns: [
        { Header: '#', accessor: 'id' },
        { Header: 'Header Example Title one', accessor: 'first' },
        { Header: 'Header Example Title two', accessor: 'last' },
    ],
    data: [
        { id: 1, first: 'Cell Example Data one', last: 'Cell Example Data two' },
        { id: 2, first: 'Cell Example Data three', last: 'Cell Example Data four' },
    ],
    updateDraggableData: () => {},
    updateEditableData: () => {},
};

export default ReactTableBase;
