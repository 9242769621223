import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import {DELIVERY_TYPE_CODE} from '../constants';
import i18n from '../../translations';

class MeetingHelpers {
    static async createMeeting(title, startDate, startTime, deliveryType, free) {
        try {
            const newMeeting = await axiosWithPersonalToken.post('meetings', {
                deliveryType,
                free,
                startDate,
                startTime,
                title
            });
            return newMeeting.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getMeeting(id) {
        try {
            const meetings = await axiosWithPersonalToken.get(`meetings/${id}`, {});
            return meetings.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getMeetingProductPackages(id) {
        try {
            const productPackages = await axiosWithPersonalToken.get(`meetings/${id}/packages`, {});
            return productPackages.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getMeetings(type) {
        try {
            const meetings = await axiosWithPersonalToken.get('meetings', {
                params: {
                    type
                }
            });
            return meetings.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getMeetingParticipants(id) {
        try {
            const participants = await axiosWithPersonalToken.get(`meetings/${id}/registrations`, {});
            return participants.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static getDeliveryTypeText(typeCode) {
        typeCode = typeCode.toUpperCase();

        if (typeCode === DELIVERY_TYPE_CODE.classRoom) {
            return i18n.t('deliveryTypes.classRoom', { ns: 'meetings' });
        }
        if (typeCode === DELIVERY_TYPE_CODE.online) {
            return i18n.t('deliveryTypes.online', { ns: 'meetings' });
        }
        if (typeCode === DELIVERY_TYPE_CODE.hybrid) {
            return i18n.t('deliveryTypes.hybrid', { ns: 'meetings' });
        }
        return '';
    }

    static async updateMeeting(id, title, startDate, startTime, endTime, lastRegistrationDate, lastRegistrationTime, status,
                               language, registrationClosed, maxParticipants, location, address, city, deliveryType, free,
                               registrationClosedReason) {
        try {
            const updated = await axiosWithPersonalToken.put(`meetings/${id}`, {
                address,
                city,
                deliveryType,
                endTime,
                free,
                language,
                lastRegistrationDate,
                lastRegistrationTime,
                location,
                maxParticipants,
                registrationClosed,
                registrationClosedReason,
                startDate,
                startTime,
                status,
                title
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateMeetingDescriptions(id, description, terms) {
        try {
            const updated = await axiosWithPersonalToken.put(`meetings/${id}/descriptions`, {
                description,
                terms
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }
}

export default MeetingHelpers;
