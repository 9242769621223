import {
    CHANGE_USER_IMAGE,
    LOG_IN_USER,
    LOG_OUT_USER
} from '../actionTypes';

export const logInUser = (contactId, email, firstname, lastname, accessToken, permissions, roles, image, title, mobile) => ({
    type: LOG_IN_USER,
    contactId,
    email,
    firstname,
    lastname,
    accessToken,
    permissions,
    roles,
    image,
    title,
    mobile
});
export const logOutUser = () => ({
    type: LOG_OUT_USER
});
export const changeUserImage = image => ({
   type: CHANGE_USER_IMAGE,
   image
});



