import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import MeetingsTabs from './MeetingsTabs';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import { PERMISSIONS } from '../../helpers/roles/Permissions';

const Meetings = () => {
    const { t } = useTranslation(['meetings', 'common']);
    const hasPermissionShowMeetings = PermissionHelpers.hasPermission(PERMISSIONS.show_meetings);

    return (
        <>
            {hasPermissionShowMeetings ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">{t('meetings')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="tabs tabs--bordered-bottom">
                                        <MeetingsTabs />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default Meetings;
