import PropTypes from 'prop-types';
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

const TextEditor = React.forwardRef(({input, children, readOnly}, ref) => {
    return (
        <CKEditor
            editor={Editor}
            config={{
                language: 'fi'
            }}
            data={input.value}
            onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                editor.setData(input.value); // Needed to set the actual data and not empty string
                if (readOnly) {
                    editor.enableReadOnlyMode('editor-id');
                }
            }}
            onBlur={(event, editor) => {
            }}
            onFocus={(event, editor) => {
            }}
            onChange={(event, editor) => {
                input.onChange(editor.getData());
            }}
            ref={ref}
        >
            {children}
        </CKEditor>
        );
});

TextEditor.propTypes = {
    input: PropTypes.shape().isRequired,
    children: PropTypes.node,
    readOnly: PropTypes.bool
};

TextEditor.defaultProps = {
    children: [],
    readOnly: false
};

export default TextEditor;
