import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Field} from 'react-final-form';
import {Button, ButtonToolbar, Col} from 'reactstrap';

import TextEditor from '../../../shared/components/form/TextEditor';

const DescriptionForm = ({dirty, dirtySinceLastSubmit, form, isSubmitted, canEditMeetings}) => {
    const {t} = useTranslation(['common', 'forms']);

    return (
        <>
            <Col xs={12} className="mb-3">
                <div className="mb-2">
                    {t('description', {ns: 'common'})}
                </div>

                <Field
                    name="description"
                    component={TextEditor}
                    readOnly={!canEditMeetings}
                />
            </Col>

            <Col xs={12}>
                <div className="mb-2">
                    {t('termsAndConditions', {ns: 'common'})}
                </div>

                <Field
                    name="terms"
                    component={TextEditor}
                    readOnly={!canEditMeetings}
                />
            </Col>

            {canEditMeetings && (
                <Col xs={12} className="mt-3">
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">
                            {t('buttons.save', {ns: 'forms'})}
                        </Button>
                        {((dirty && !isSubmitted) || (dirtySinceLastSubmit && isSubmitted)) && (
                            <Button type="button" onClick={form.reset} disabled={form.pristine || form.submitting}>
                                {t('buttons.cancel', {ns: 'forms'})}
                            </Button>
                        )}
                    </ButtonToolbar>
                </Col>
            )}
        </>
    );
};

DescriptionForm.propTypes = {
    form: PropTypes.oneOfType([PropTypes.object]).isRequired,
    dirty: PropTypes.bool.isRequired,
    dirtySinceLastSubmit: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    canEditMeetings: PropTypes.bool.isRequired
};

export default DescriptionForm;
