import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormField from '../../../shared/components/form/FormField';
import { SelectField } from '../../../shared/components/form/Select';
import CommonHelpers from '../../../helpers/CommonHelpers';

const UsersTableFilters = (props) => {
    const { t } = useTranslation('common');
    const { accounts, filterByRoleName, filterByRoleType } = props;
    const { control } = useForm();

    const getAllOption = () => ({ value: '0', label: t('all') });
    const getStringAllOption = () => ({ value: '', label: t('all') });

    const getRoleNames = () => {
        let roles = _.map(accounts, 'roles');
        roles = _.flatten(roles);
        roles = _.uniqBy(roles, 'role_name');

        const options = roles.map(item => ({
            'value': item.role_name,
            'label': CommonHelpers.getRoleNameText(item.role_name)
        }));
        return _.concat([getStringAllOption()], options);
    };

    const getRoleTypes = () => {
        let roles = _.map(accounts, 'roles');
        roles = _.flatten(roles);
        roles = _.uniqBy(roles, 'role_type');

        const options = roles.map(item => ({
            'value': item.role_type,
            'label': CommonHelpers.getRoleTypeText(item.role_type)
        }));
        return _.concat([getAllOption()], options);
    };

    const handleRoleNameChange = (item) => {
        filterByRoleName(item.value);
    };

    const handleRoleTypeChange = (item) => {
        filterByRoleType(item.value);
    };

    return (
        <Row className="mb-3 mt-3">
            <Col xs={12} lg={3}>
                <div className="form__form-group">
                        <span className="form__form-group-label">
                             {t('role')}
                        </span>
                    <div className="form__form-group-field">
                        <FormField
                            name="roleNameFilter"
                            control={control}
                            component={SelectField}
                            options={getRoleNames()}
                            defaultValue={getStringAllOption()}
                            onChange={handleRoleNameChange}
                        />
                    </div>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                        <span className="form__form-group-label">
                             {t('roleTypeText')}
                        </span>
                    <div className="form__form-group-field">
                        <FormField
                            name="roleTypeFilter"
                            control={control}
                            component={SelectField}
                            options={getRoleTypes()}
                            defaultValue={getAllOption()}
                            onChange={handleRoleTypeChange}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

UsersTableFilters.propTypes = {
    filterByRoleName: PropTypes.func.isRequired,
    filterByRoleType: PropTypes.func.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default UsersTableFilters;
