import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Card, CardBody, Col, Container, Row
} from 'reactstrap';
import PermissionHelpers from '../../helpers/roles/PermissionHelpers';
import AccessDenied from '../Common/AccessDenied';
import { PERMISSIONS } from '../../helpers/roles/Permissions';
import ContactsTable from './components/ContactsTable';

const Contacts = () => {
    const { t } = useTranslation(['common', 'contacts']);
    const hasPermissionShowContacts = PermissionHelpers.hasPermission(PERMISSIONS.show_contacts);

    return (
        <>
            {hasPermissionShowContacts ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">{t('contacts', {ns: 'contacts'})}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="tabs tabs--bordered-bottom">
                                        <ContactsTable />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default Contacts;
