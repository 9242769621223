import React, { useState } from 'react';
import {
    Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanyDomains from './CompanyDomains';
import CompanyNames from './CompanyNames';
import CompanyInvoiceAddresses from './CompanyInvoiceAddresses';

const CompanyProfileTabs = ({company, invoiceAddresses, setUpdatedName, setUpdatedDomain, removeDomain,
                                setUpdatedInvoiceAddress, removeInvoiceAddress}) => {
    const [activeTab, setActiveTab] = useState('1');

    const { t } = useTranslation(['profile', 'companies']);

    const handleToggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <Col md={12} lg={12} xl={8}>
            <Card>
                <div className="profile__card tabs tabs--bordered-bottom">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => handleToggle('1')}
                                >
                                    {t('names', {ns: 'companies'})}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => handleToggle('2')}
                                >
                                    {t('domains', {ns: 'companies'})}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => handleToggle('3')}
                                >
                                    {t('invoicingAddresses', {ns: 'common'})}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <CompanyNames
                                    companyId={company.id}
                                    names={company.names ?? []}
                                    setUpdatedName={setUpdatedName}
                                />
                            </TabPane>
                            <TabPane tabId="2">
                                <CompanyDomains
                                    companyId={company.id}
                                    domains={company.domains ?? []}
                                    setUpdatedDomain={setUpdatedDomain}
                                    removeDomain={removeDomain}
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <CompanyInvoiceAddresses
                                    invoiceAddresses={invoiceAddresses}
                                    names={company.names ?? []}
                                    setUpdatedInvoiceAddress={setUpdatedInvoiceAddress}
                                    removeInvoiceAddress={removeInvoiceAddress}
                                />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </Card>
        </Col>
    );
};

CompanyProfileTabs.propTypes = {
    company: PropTypes.arrayOf(PropTypes.shape({
        domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })).isRequired,
    invoiceAddresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    removeDomain: PropTypes.func.isRequired,
    removeInvoiceAddress: PropTypes.func.isRequired,
    setUpdatedDomain: PropTypes.func.isRequired,
    setUpdatedInvoiceAddress: PropTypes.func.isRequired,
    setUpdatedName: PropTypes.func.isRequired
};

export default CompanyProfileTabs;
