import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Field, useForm} from 'react-final-form';
import {Button, ButtonToolbar, Col} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import {DELIVERY_TYPE_CODE} from '../../../helpers/constants';
import RequiredField from '../../../shared/components/form/RequiredField';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import {
    required,
    dateValid,
    timeValid,
    dateInFuture
} from '../../../helpers/forms/validation';
import MeetingHelpers from '../../../helpers/meetings/MeetingHelpers';
import materialTextField from '../../../shared/components/form/materialTextField';

const NewMeetingForm = ({dirty, form}) => {
    const {t} = useTranslation(['meetings', 'common', 'forms']);
    const {
        getFieldState
    } = useForm();
    const history = useHistory();

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const getRequiredFieldLabel = (label) => {
        return <>{label}<RequiredField /></>;
    };

    return (
        <>
            <Col xs={12}>
                <Field
                    name="title"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('name', {ns: 'common'}))}
                    validate={required}
                    maxLength={255}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="startDate"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('startDate', {ns: 'common'}))}
                    placeholder="pp.kk.vvvv"
                    validate={composeValidators(required, dateValid, dateInFuture)}
                    maxLength={10}
                />
            </Col>

            <Col xs={12} lg={3}>
                <Field
                    name="startTime"
                    component={materialTextField}
                    label={getRequiredFieldLabel(t('startTime', {ns: 'common'}))}
                    placeholder="00:00"
                    validate={composeValidators(required, timeValid)}
                    maxLength={5}
                />
            </Col>

            <Col xs={12} lg={3}>
                <span className="material-form__label">
                        {getRequiredFieldLabel(t('deliveryType', {ns: 'common'}))}
                    </span>
                <div className="form__form-group-field">
                    <Field
                        name="deliveryType"
                        component={materialTextField}
                        validate={required}
                        select
                    >
                        <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.classRoom}>
                            {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.classRoom)}
                        </MenuItem>
                        <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.online}>
                            {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.online)}
                        </MenuItem>
                        <MenuItem className="material-form__option" value={DELIVERY_TYPE_CODE.hybrid}>
                            {MeetingHelpers.getDeliveryTypeText(DELIVERY_TYPE_CODE.hybrid)}
                        </MenuItem>
                    </Field>
                </div>
            </Col>

            <Col xs={12} lg={3}>
                <div className="form__form-group">
                    <div className="form__form-group-field inline-toggle mt-2">
                        <Field
                            name="paid"
                            component={renderToggleButtonField}
                            initialValue
                        />

                        <span className="form__form-group-label pt-3 pl-3">
                            {t('paidMeeting', {ns: 'meetings'})}
                        </span>
                    </div>
                </div>
            </Col>

            <Col xs={12}>
                <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                        {t('buttons.save', {ns: 'forms'})}
                    </Button>
                    <Button type="button" onClick={() => history.goBack()}>
                        {t('buttons.cancel', {ns: 'forms'})}
                    </Button>
                </ButtonToolbar>
            </Col>
        </>
    );
};

NewMeetingForm.propTypes = {
    form: PropTypes.oneOfType([PropTypes.object]).isRequired,
    dirty: PropTypes.bool.isRequired
};

export default NewMeetingForm;
