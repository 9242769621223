import i18n from '../translations/index';
import {LANGUAGE_CODE, ROLE_NAMES, ROLE_TYPES, TARGET_STATUS} from './constants';

class CommonHelpers {
    static getLanguageText(languageCode) {
        languageCode = languageCode.toLowerCase();

        if (languageCode === LANGUAGE_CODE.fi) {
            return i18n.t('languages.finnish', { ns: 'common' });
        }
        if (languageCode === LANGUAGE_CODE.en) {
            return i18n.t('languages.english', { ns: 'common' });
        }

        return '';
    }

    static getTargetStatusText(status) {
        if (status === TARGET_STATUS.active) {
            return i18n.t('targetStatus.published', { ns: 'common' });
        }
        if (status === TARGET_STATUS.cancelled) {
            return i18n.t('targetStatus.cancelled', { ns: 'common' });
        }
        if (status === TARGET_STATUS.planned) {
            return i18n.t('targetStatus.planned', { ns: 'common' });
        }

        return '';
    }

    static getRoleNameText(roleName) {
        if (roleName === ROLE_NAMES.mainUser) {
            return i18n.t('roleName.mainUser', { ns: 'common' });
        }
        if (roleName === ROLE_NAMES.meetings) {
            return i18n.t('roleName.meetings', { ns: 'common' });
        }

        return '';
    }

    static getRoleTypeText(roleType) {
        if (Number(roleType) === ROLE_TYPES.admin) {
            return i18n.t('roleType.admin', { ns: 'common' });
        }
        if (Number(roleType) === ROLE_TYPES.viewer) {
            return i18n.t('roleType.viewer', { ns: 'common' });
        }

        return '';
    }
}

export default CommonHelpers;
