import { format } from 'date-fns';

class DateTimeHelpers {
    static formatDate(dateString) {
        if (!dateString) return null;
        // Not working in Safari if time is given
        dateString = dateString.slice(0, 10);
        dateString = new Date(dateString);
        return format(dateString, 'dd.MM.yyyy');
    }

    static formatTime(timeString) {
        if (!timeString) return null;

        if (timeString.length > 8) {
            // date and time given 'dd.mm.kkkk 00:00:00' -> remove date 'dd.mm.kkkk '
            timeString = timeString.slice(11);
        }

        return timeString.slice(0, 5);
    }
}

export default DateTimeHelpers;
