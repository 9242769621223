import {Button, Modal} from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { getDirection } from '../../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import EditNameForm from '../forms/EditNameForm';
import CompanyNameHelpers from '../../../../helpers/Company/CompanyNameHelpers';
import {ERROR_CODES} from '../../../../helpers/ErrorCodes';
import PermissionHelpers from '../../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../../helpers/roles/Permissions';

const NewCompanyNameModal = ({ rtl, companyId, setUpdatedName }) => {
    const [show, setShow] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['companies']);
    const hasPermissionEditCompanies = PermissionHelpers.hasPermission(PERMISSIONS.manage_companies);

    const toggleModal = () => {
        setErrorAlert('');
        setShow(prevShow => !prevShow);
    };

    const handleSave = async (data) => {
        try {
            const updatedName = await CompanyNameHelpers.createCompanyName(
                companyId,
                data.name,
                data.businessId
            );

            setUpdatedName(updatedName);
             toggleModal();
        } catch (e) {
             const errorCode = e.code ?? '';
             let errMsg = t('errors.failedToSaveData', {ns: 'forms'});
             if (errorCode === ERROR_CODES.companyNameAlreadyExist) {
                 errMsg = t('errors.companyNameAlreadyExist', {ns: 'companies'});
             }

            setErrorAlert(errMsg);
        }
    };

    const initialNameValues = {
        businessId: '',
        name: ''
    };

    return (
        <>
            {hasPermissionEditCompanies && (
                <Button
                    className="float-right"
                    color="success"
                    onClick={toggleModal}
                >
                    <p>{t('addCompanyName', { ns: 'companies' })}</p>
                </Button>
            )}

            <Modal
                isOpen={show}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleModal}
                    />
                    <h4 className="text-modal  modal__title">
                        {t('addCompanyName', {ns: 'companies'})}
                    </h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave} initialValues={initialNameValues} keepDirtyOnReinitialize>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <EditNameForm
                                    toggleModal={toggleModal}
                                    errorAlertText={errorAlert}
                                    submitButtonText={t('buttons.save', {ns: 'forms'})}
                                />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

NewCompanyNameModal.propTypes = {
    setUpdatedName: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired
};

export default connect(state => ({
    rtl: getDirection(state),
}))(NewCompanyNameModal);
