import md5 from 'md5';
import { SESSION_KEY } from './constants';
import AccessToken from './AccessToken';
import { axiosWithPersonalToken } from '../axiosConfig';
import AxiosHelpers from './axiosHelpers';

class Session {
    static createSessionId() {
        const id = localStorage.getItem(SESSION_KEY.id);

        if (typeof id === 'undefined' || id === null || id.length === 0) {
            // session id does not exists
            const newId = md5(Math.random().toString(36).slice(2));
            localStorage.setItem(SESSION_KEY.id, newId);

            return newId;
        }
        return id;
    }

    static destroySession() {
        // localStorage.clear();
        localStorage.removeItem(SESSION_KEY.access_token);
    }

    static getLanguage() {
        return localStorage.getItem(SESSION_KEY.language);
    }

    static getSessionId() {
        return localStorage.getItem(SESSION_KEY.id);
    }

    static async isUserLogged() {
        const token = AccessToken.getAccessToken();

        if (token) {
            try {
                const logged = await axiosWithPersonalToken.get('account/logged', {});
                return true;
            } catch (e) {
                const err = AxiosHelpers.handleRequestError(e);
                throw e;
            }
        }

        return false;
    }

    static saveUser(accessToken) {
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        const item = {
            value: accessToken,
            expiry: tomorrow
        };

        localStorage.setItem(SESSION_KEY.access_token, JSON.stringify(item));
    }

    static saveLanguage(language) {
        localStorage.setItem(SESSION_KEY.language, language);
    }
}

export default Session;
