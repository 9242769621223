import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import Alert from '@/shared/components/alert/Alert';
import LogInForm from './components/LogInForm';
import UserHelpers from '../../helpers/users/UserHelpers';

const LogIn = (props) => {
    const [loginFailed, setLoginFailed] = useState(false);
    const [extraErrorText, setExtraErrorText] = useState('');
    const [loginFailCount, setLoginFailCount] = useState(0);
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [passwordSet, setPasswordSet] = useState(false);
    const { t } = useTranslation('login');
    const history = useHistory();

    const timerRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const passwordSetParam = new URLSearchParams(location.search).get('passwordSet');
        setPasswordSet(passwordSetParam === 'true');

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const showError = (additionalText) => {
        timerRef.current = setTimeout(() => {
            if (loginFailCount > 2 && !additionalText) {
                additionalText = t('loginFailedManyTimes');
            }

            setLoginFailed(true);
            setExtraErrorText(additionalText);
            setLoginFailCount(loginFailCount + 1);
            setLoginButtonDisabled(false);
        }, 500);
    };

    const onLogin = async (formInputs) => {
        setLoginFailed(false);
        setLoginButtonDisabled(true);

        try {
            const logged = await UserHelpers.loginWithEmailAndPassword(formInputs.email, formInputs.password);
            history.push('/dashboard');
        } catch (error) {
            if (error.status === StatusCodes.UNAUTHORIZED && error.code === 'VERIFICATION_REQUIRED') {
                showError(t('emailNotVerifiedInfo'));
            } else {
                showError('');
            }
        }
    };

    return (
        <div className="account">
            <div className="account__wrapper">
                {passwordSet && (
                    <Alert
                        color="success"
                        className="mb-2"
                    >
                        <p>
                            {t('passwordSetAndLogin')}
                        </p>
                    </Alert>
                )}
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">{t('welcome')}
                            <span className="account__logo"> Oppia
                                <span className="account__logo-accent">{t('cloud')}</span>
                            </span>
                        </h3>
                        <h4 className="account__subhead subhead">{t('cloudPlatform')}</h4>
                    </div>
                    <LogInForm
                      onLogin={onLogin}
                      loginFailed={loginFailed}
                      extraErrorText={extraErrorText}
                      loginButtonDisabled={loginButtonDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

LogIn.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default connect(
    null,
    {}
)(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
