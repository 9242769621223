import { combineReducers } from 'redux';

import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import rtlReducer from './rtlReducer';
import userReducer from './userReducer';
import languageReducer from './languageReducer';

export default combineReducers({
  themeReducer,
  sidebarReducer,
  rtlReducer,
  userReducer,
  languageReducer
});
