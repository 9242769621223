import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-final-form';
import {Col, Row} from 'reactstrap';
import MeetingHelpers from '../../../helpers/meetings/MeetingHelpers';
import Alert from '../../../shared/components/alert/Alert';
import {ALERT_TYPE} from '../../../helpers/constants';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import InfoForm from '../forms/InfoForm';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';

const MeetingInfoTab = ({meeting, setMeeting}) => {
    const {id} = useParams();
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [initialMeetingValues, setInitialMeetingValues] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {t} = useTranslation(['meetings', 'common', 'forms']);
    const hasPermissionEditMeetings = PermissionHelpers.hasPermission(PERMISSIONS.create_and_update_meeting);

    const showSuccessAlert = (text) => {
        setAlertType(ALERT_TYPE.success);
        setAlertText(text);
    };

    const showErrorAlert = (text) => {
        setAlertType(ALERT_TYPE.danger);
        setAlertText(text);
    };

    const onSubmit = async (data) => {
        if (hasPermissionEditMeetings) {
            try {
                const newMeetingValues = await MeetingHelpers.updateMeeting(
                    id,
                    data.title,
                    data.startDate,
                    data.startTime,
                    data.endTime,
                    data.lastRegistrationDate,
                    data.lastRegistrationTime,
                    data.status,
                    data.language,
                    data.registrationClosed,
                    data.max,
                    data.location,
                    data.address,
                    data.city,
                    data.deliveryType,
                    !data.paid,
                    data.registrationClosedReason
                );

                // Show success
                showSuccessAlert(t('dataSavedSuccessfully', {ns: 'forms'}));
                setIsSubmitted(true);
                // Set new values for meeting
                // (required to set new initial values to form for cancel-button to reset to currently saved values)
                setMeeting(newMeetingValues);
            } catch (e) {
                // Show error
                showErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
            }
        }
    };

     useEffect(() => {
         function getInitialMeetingValues() {
             const initialValues = {
                 address: meeting.address ?? '',
                 city: meeting.city ?? '',
                 deliveryType: meeting.deliveryType ?? '',
                 endTime: DateTimeHelpers.formatTime(meeting.endTime),
                 language: meeting.language.toLowerCase() ?? '',
                 lastRegistrationDate: DateTimeHelpers.formatDate(meeting.lastRegistrationDate),
                 lastRegistrationTime: DateTimeHelpers.formatTime(meeting.lastRegistrationDate),
                 location: meeting.location ?? '',
                 max: meeting.maxParticipants ?? '',
                 paid: Number(meeting.free) !== 1,
                 registrationClosed: Number(meeting.registrationClosed) === 1,
                 registrationClosedReason: meeting.registrationClosedReason ?? '',
                 startDate: DateTimeHelpers.formatDate(meeting.startDate),
                 startTime: DateTimeHelpers.formatTime(meeting.startTime),
                 status: meeting.status ?? '',
                 title: meeting.title ?? ''
             };

             setInitialMeetingValues(initialValues);
         }

        getInitialMeetingValues();
    }, [meeting]);

    return (
        <>
            {alertText.length > 0 && (
                <Row>
                    <Col xs={12} className="mb-4">
                        <Alert color={alertType} className="mt-1">
                            <p>{alertText}</p>
                        </Alert>
                    </Col>
                </Row>
            )}

            {/* : {reset, pristine, submitting} */}
            <Form onSubmit={onSubmit} initialValues={initialMeetingValues}>
                {({handleSubmit, dirty, dirtySinceLastSubmit, form}) => (
                    <form className="material-form material-form-flex" onSubmit={handleSubmit}>
                        <InfoForm dirty={dirty} dirtySinceLastSubmit={dirtySinceLastSubmit} form={form} nroOfParticipants={Number(meeting.registrations) ?? 0} isSubmitted={isSubmitted} canEditMeetings={hasPermissionEditMeetings} />
                    </form>
                )}
            </Form>
        </>
    );
};

MeetingInfoTab.propTypes = {
    meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setMeeting: PropTypes.func.isRequired
};

export default MeetingInfoTab;
