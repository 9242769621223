import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Modal from '@/shared/components/Modal';
import Alert from '@/shared/components/alert/Alert';
import UsersTable from './UsersTable';
import UsersForm from './UsersForm';
import UserHelpers from '../../../helpers/users/UserHelpers';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';
import AccessDenied from '../../Common/AccessDenied';


const Users = () => {
    const [accounts, setAccounts] = useState([]);
    const [accountsLoaded, setAccountsLoaded] = useState(false);
    const [showUserForm, setShowUserForm] = useState(false);
    const [userInformation, setUserInformation] = useState({});
    const [userEmail, setUserEmail] = useState('');
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [saveFailed, setSaveFailed] = useState(false);
    const [extraErrorText, setExtraErrorText] = useState('');
    const hasPermissionManageCloudUsers = PermissionHelpers.hasPermission(PERMISSIONS.manage_cloud_users);

    const { t } = useTranslation(['settings', 'common']);

    const getAccounts = async () => {
        const accountsData = await UserHelpers.getAccounts();
        setAccounts(accountsData);
    };

    useEffect(() => {
        // Get accounts
        getAccounts().then(() => setAccountsLoaded(true));
    }, []);

    const openUserForm = (userData) => {
        setUserInformation(userData);
        setShowUserForm(true);
        removeSaveAlerts();
    };

    const closeUserForm = () => {
        setAccountsLoaded(false);
        getAccounts().then(() => setAccountsLoaded(true));
        setShowUserForm(false);
    };

    const openDeleteUserModal = (email) => {
        setUserEmail(email);
        setShowDeleteUserModal(true);
        removeSaveAlerts();
    };

    const closeDeleteUserModal = () => {
        setAccountsLoaded(false);
        getAccounts().then(() => setAccountsLoaded(true));
        setShowDeleteUserModal(false);
    };

    const showSaveSuccessful = () => {
        setSaveSuccessful(true);
        setSaveFailed(false);
    };

    const showSaveFailed = () => {
        setSaveFailed(true);
        setSaveSuccessful(false);
    };

    const removeSaveAlerts = () => {
        setSaveFailed(false);
        setSaveSuccessful(false);
    };

    const handleDeleteUser = () => {
        const accountDeleted = UserHelpers.deleteAccount(userEmail);

        accountDeleted.then(() => {
            closeDeleteUserModal();
            showSaveSuccessful();
        }).catch((error) => {
            closeDeleteUserModal();
            showSaveFailed();
        });
    };

    const handleSaveUser = (firstName, lastName, email, oldEmail, roles, addUser) => {
        if (addUser) {
            const accountAdded = UserHelpers.addAccount(firstName, lastName, email, roles);

            accountAdded.then(() => {
                // set success alert for user
                showSaveSuccessful();
                closeUserForm();
            }).catch((error) => {
                // set error alert for user
                showSaveFailed();
                closeUserForm();
            });
        } else {
            const accountUpdated = UserHelpers.updateAccount(firstName, lastName, email, oldEmail, roles);

            accountUpdated.then(() => {
                // refresh logged user's data
                // set success alert for user
                UserHelpers.refreshUserData().then(() => {
                    showSaveSuccessful();
                    closeUserForm();
                })
                    .catch(() => {
                        showSaveFailed();
                        closeUserForm();
                    });
            }).catch((error) => {
                // set error alert for user
                showSaveFailed();
                closeUserForm();
            });
        }
    };

    return (
        <>
            {hasPermissionManageCloudUsers ? (
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <h3 className="page-title">{t('users')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {saveSuccessful && accountsLoaded && (
                                <Alert
                                    color="success"
                                    className="mb-2"
                                >
                                    <p>
                                        {t('saveSuccessful')}
                                    </p>
                                </Alert>
                            )}
                            {saveFailed && accountsLoaded && (
                                <Alert
                                    color="danger"
                                    className="mb-2"
                                >
                                    <p>
                                        {`${t('saveFailed')} ${extraErrorText ?? ''}`}
                                    </p>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    {accountsLoaded && (
                                        <>
                                            {showUserForm
                                                ? (
                                                    <UsersForm
                                                        userInformation={userInformation}
                                                        handleSaveUser={handleSaveUser}
                                                        closeUserForm={closeUserForm}
                                                    />
                                                )
                                                : (
                                                    <UsersTable
                                                        openUserForm={openUserForm}
                                                        openDeleteUserModal={openDeleteUserModal}
                                                        accounts={accounts}
                                                    />
                                                )}
                                            <Modal
                                                show={showDeleteUserModal}
                                                color="danger"
                                                title={t('areYouSure', { ns: 'common' })}
                                                message={t('deleteWithEmail', { email: userEmail })}
                                                toggleModal={closeDeleteUserModal}
                                                handleOk={handleDeleteUser}
                                            />
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default Users;
