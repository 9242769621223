import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import MeetingInfoTab from './MeetingInfoTab';
import MeetingDescriptionTab from './MeetingDescriptionTab';
import MeetingPackagesTab from './MeetingPackagesTab';

const MeetingTabs = ({meeting, setMeeting}) => {
    const [activeTab, setActiveTab] = useState('1');
    const { t } = useTranslation('common');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="tabs__wrap">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                    >
                        {t('targetTabs.info')}
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                    >
                        {t('targetTabs.provider')}
                    </NavLink>
                </NavItem> */}
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                    >
                        {t('targetTabs.content')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}
                    >
                        {t('targetTabs.productPackages')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <MeetingInfoTab meeting={meeting} setMeeting={setMeeting} />
                </TabPane>
                {/* <TabPane tabId="2">
                    <MeetingProviderTab meeting={meeting} />
                </TabPane> */}
                <TabPane tabId="2">
                    <MeetingDescriptionTab meeting={meeting} setMeeting={setMeeting} />
                </TabPane>
                <TabPane tabId="3">
                    <MeetingPackagesTab meeting={meeting} />
                </TabPane>
            </TabContent>
        </div>
    );
};

MeetingTabs.propTypes = {
    meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setMeeting: PropTypes.func.isRequired
};

export default MeetingTabs;
