import {Button} from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PermissionHelpers from '../../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../../helpers/roles/Permissions';
import InvoiceAddressHelpers from '../../../../helpers/Order/InvoiceAddressHelpers';
import InvoiceAddressModal from './InvoiceAddressModal';

const NewInvoiceAddressModal = ({ companyNames, setUpdatedInvoiceAddress }) => {
    const [show, setShow] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['companies']);
    const hasPermissionEditCompanies = PermissionHelpers.hasPermission(PERMISSIONS.manage_companies);

    const toggleModal = () => {
        setErrorAlert('');
        setShow(prevShow => !prevShow);
    };

    const handleSave = async (data) => {
        try {
            const newDomain = await InvoiceAddressHelpers.createInvoiceAddress(
                data.nameId,
                null,
                data.address,
                data.city,
                data.invoiceCompany,
                data.country,
                data.eInvoiceAddress,
                data.eInvoiceCode,
                data.eInvoiceEmail,
                data.eInvoiceOperator,
                data.identifier,
                data.info,
                data.postalCode
            );

            setUpdatedInvoiceAddress(newDomain);
            toggleModal();
        } catch (e) {
            setErrorAlert(t('errors.failedToSaveData', {ns: 'forms'}));
        }
    };

    return (
        <>
            {hasPermissionEditCompanies && (
                <Button
                    className="float-right"
                    color="success"
                    onClick={toggleModal}
                >
                    <p>{t('addInvoiceAddress', { ns: 'companies' })}</p>
                </Button>
            )}

            <InvoiceAddressModal
                companyNames={companyNames}
                invoiceAddress={[]}
                toggleModal={toggleModal}
                showModal={show}
                saveData={handleSave}
                companySelectorDisabled={false}
                alertText={errorAlert}
            />
        </>
    );
};

NewInvoiceAddressModal.propTypes = {
    companyNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setUpdatedInvoiceAddress: PropTypes.func.isRequired,
};

export default NewInvoiceAddressModal;
