import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PasswordField from '@/shared/components/form/Password';
import Alert from '@/shared/components/alert/Alert';
import { emailValid, required } from '../../../helpers/forms/validation';

const LogInForm = ({
    onLogin,
    loginFailed,
    extraErrorText,
    loginButtonDisabled
    }) => {
    const { t } = useTranslation('login');

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    return (
        <Form
          onSubmit={onLogin}
        >
            {({ handleSubmit }) => (
                <form className="form" onSubmit={handleSubmit}>
                    {loginFailed && (
                        <Alert
                            color="danger"
                            className="mt-1"
                        >
                            <p>
                                {t('loginFailed')}
                                {extraErrorText && (
                                    <div className="mt-2">
                                        {extraErrorText}
                                    </div>
                                )}
                            </p>
                        </Alert>
                    )}
                    <div className="form__form-group">
                        <span className="form__form-group-label">{t('email')}</span>
                        <Field
                            name="email"
                            validate={composeValidators(required, emailValid)}
                        >
                        {({ input, meta }) => (
                            <>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <input {...input} type="text" placeholder={t('email')} />
                                </div>
                                {meta.error && meta.touched && (
                                    <span className="account__forgot-password text-danger">
                                        {meta.error}
                                    </span>
                                )}
                            </>
                        )}
                        </Field>
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label">{t('password')}</span>
                        <Field
                            name="password"
                            validate={required}
                            component={PasswordField}
                            placeholder={t('password')}
                            className="input-without-border-radius"
                            keyIcon
                            showForgotPassword
                        />

                    </div>
                    <Button
                        className="account__btn"
                        type="submit"
                        color="primary"
                        disabled={loginButtonDisabled}
                    >
                        {t('signIn')}
                    </Button>
                </form>
            )}
        </Form>
    );
};

LogInForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
    loginFailed: PropTypes.bool.isRequired,
    extraErrorText: PropTypes.string.isRequired,
    loginButtonDisabled: PropTypes.bool.isRequired
};

export default LogInForm;
