import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import ImageIcon from 'mdi-react/ImageIcon';
import PropTypes from 'prop-types';
import {CloudinaryConfig} from '../../helpers/Cloudinary/CloudinaryConfig';
import CloudinaryHelpers from '../../helpers/Cloudinary/CloudinaryHelpers';

const ChangeImageButton = ({ setError, changeImage, targetType }) => {
    const {t} = useTranslation('profile');

    const myWidget = window.cloudinary.createUploadWidget({
            cloudName: CloudinaryConfig.cloudName,
            uploadPreset: 'brxv44rt',
            folder: `${CloudinaryConfig.baseFolder}${CloudinaryHelpers.getTypeRoute(targetType)}`
        },
        (error, result) => {
            if (!error && result && result.event === 'success') {
                const publicId = result.info.public_id ?? '';
                const imageName = publicId ? publicId.substring(publicId.lastIndexOf('/') + 1) : '';

                if (imageName) {
                    changeImage(imageName);
                } else {
                    setError('profileMain.imageUploadFailed');
                }
            } else if (error) {
                setError(error);
            }
        });

    const openPictureWidget = () => {
        myWidget.open();
    };

    return (
        <Button
            className="icon"
            color="primary"
            outline
            onClick={openPictureWidget}
        >
            <p><ImageIcon /> {t('profileMain.changePicture')}</p>
        </Button>
    );
};

ChangeImageButton.propTypes = {
    setError: PropTypes.func.isRequired,
    changeImage: PropTypes.func.isRequired,
    targetType: PropTypes.string.isRequired
};

export default ChangeImageButton;
