import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row, UncontrolledTooltip} from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import DataReactTable from '../../Common/DataReactTable';
import ContactHelpers from '../../../helpers/Contacts/ContactHelpers';
import Alert from '../../../shared/components/alert/Alert';
import ContactAndCompanySearchInput from './ContactAndCompanySearchInput';
import RouteHelpers from '../../../helpers/RouteHelpers';

const ContactsTable = (props) => {
    const defaultPageSizeOnLoad = 20;
    const { t } = useTranslation(['meetings', 'common', 'errors']);
    const [isLoading, setIsLoading] = useState(false);
    const [pageSize, setPageSize] = useState(defaultPageSizeOnLoad);
    const [loadedPagesData, setLoadedPagesData] = useState([]); // contacts by page
    const [totalContacts, setTotalContacts] = useState(0); // nro of contacts in total
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [searchKey, setSearchKey] = useState('');
    const [errorText, setErrorText] = useState('');
    const prevStateValues = useRef({pageSize, searchKey}).current;

    // TODO: filtteröinti

    const columns = React.useMemo(() => [
        {
            Header: '#',
            disableGlobalFilter: true,
            Cell: ({ row }) => (
                <span>{row.index + 1}</span>
            )
        },
        {
            Header: t('firstName', { ns: 'common' }),
            accessor: 'firstName',
            disableGlobalFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('lastName', { ns: 'common' }),
            accessor: 'lastName',
            disableGlobalFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('email', { ns: 'common' }),
            accessor: 'email',
            disableGlobalFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('title', { ns: 'common' }),
            accessor: 'title',
            disableGlobalFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('company', { ns: 'common' }),
            accessor: 'company.name',
            disableGlobalFilter: true,
            disableSortBy: true,
        },
        {
            Header: '',
            accessor: 'editOrDelete',
            disableGlobalFilter: true,
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    <Link to={RouteHelpers.getContactRoute(row.original.id)}>
                        <span id={`LinkTooltip${row.original.id}`} className="table__button-icon lnr lnr-arrow-right" />
                        <UncontrolledTooltip placement="bottom" target={`LinkTooltip${row.original.id}`}>
                            {t('showDetails', { ns: 'tooltips' })}
                        </UncontrolledTooltip>
                    </Link>
                </>
            )
        }
    ], []);

    const createTableData = (m) => {
        let dataWithExtraRows = [];

        if (m.length > 0) {
            // Create array with "empty" contacts
            const extraContactsArray = getEmptyDataArray(totalContacts);

            // Split array to chunks (size = page size)
            const extraContactChunks = _.chunk(extraContactsArray, pageSize);

            // Replace empty contacts with real data for loaded pages
            _.forEach(m, function (value, key) {
                extraContactChunks[value.index] = value.data;
            });

            // Flatten array of chunks to one array
            dataWithExtraRows = _.flatten(extraContactChunks);
        }

        return { tableHeaderData: columns, tableRowsData: dataWithExtraRows };
    };

    const addLoadedPageData = (idx, pageData, reinitializeTable) => {
        let pages = _.cloneDeep(loadedPagesData);

        // Clear data if reinitializing table (after page size etc. changes)
        if (reinitializeTable) {
            pages = [];
        }

        const loadedPageIndexes = _.map(pages, 'index');

        if (!loadedPageIndexes.includes(idx)) {
            pages.push({
                'index': idx,
                'data': pageData
            });
        }

        setLoadedPagesData(pages);
    };

    const getEmptyDataArray = (arraySize) => {
        return _.fill(Array(arraySize), {
            'company': {
                'name': ''
            },
            'email': '',
            'firstName': t('loading', { ns: 'common' }),
            'lastName': '',
            'title': ''
        });
    };

    const getNewContactRows = async (newPageIndex, newPageSize, reinitializeTable) => {
        // If reinitializing table -> clear data and add 'loading' text for rows
        if (reinitializeTable) {
            setLoadedPagesData([]);
        }

        const loadedPageIndexes = _.map(loadedPagesData, 'index');

        if (!loadedPageIndexes.includes(newPageIndex) || reinitializeTable) {
            setIsLoading(true);

            try {
                const contactsData = await ContactHelpers.getContacts(
                    newPageSize,
                    newPageIndex * newPageSize,
                    searchKey
                );

                setTotalContacts(contactsData.total ? contactsData.total : 0);
                setIsLoading(false);
                addLoadedPageData(newPageIndex, contactsData.contacts, reinitializeTable);
                setErrorText('');
            } catch (error) {
                setIsLoading(false);
                setErrorText(t('failedToRetrieveData', { ns: 'errors' }));
            }
        }
    };

    useEffect(async () => {
        let reinitTable = false;

        if (prevStateValues.pageSize !== pageSize || prevStateValues.searchKey !== searchKey) {
            // Clear previous data if page size or search key changes
            reinitTable = true;
        }

        await getNewContactRows(currentPageIndex, pageSize, reinitTable);

        return () => {
            prevStateValues.pageSize = pageSize;
            prevStateValues.searchKey = searchKey;
        };
    }, [currentPageIndex, pageSize, searchKey]);

    const fetchData = useCallback(
        (newPageIndex, newPageSize) => {
            // trigger data fetching
            setCurrentPageIndex(newPageIndex);
            setPageSize(newPageSize);
        },
        []
    );

    const searchContacts = (newSearchKey) => {
        setCurrentPageIndex(0);
        setSearchKey(newSearchKey);
    };

    const tableData = useMemo(() => createTableData(loadedPagesData), [loadedPagesData]);

    return (
        <Col>
            <ContactAndCompanySearchInput
                makeSearch={searchContacts}
                searchDisabled={(isLoading && loadedPagesData.length === 0)}
                label={t('searchContactBy', { ns: 'contacts' })}
            />

            {(isLoading && loadedPagesData.length === 0) && (
                <Row>
                    <Col>
                        {t('loading', { ns: 'common' })}
                    </Col>
                </Row>
            )}
            {errorText.length > 0 && (
                <Alert
                    color="danger"
                    className="mt-1"
                >
                    {errorText}
                </Alert>
            )}
            {(loadedPagesData.length > 0) && (
                <>
                    <Row>
                        <Col>
                            <DataReactTable
                                reactTableData={tableData}
                                searchEngineOn={false}
                                pageSize={[defaultPageSizeOnLoad, 50, 100]}
                                fetchData={fetchData}
                                defaultPageSize={pageSize}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    );
};

ContactsTable.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number,
        original: PropTypes.shape({
            id: PropTypes.number
        }),
    })
};

ContactsTable.defaultProps = {
    row: {
        index: ''
    }
};

export default ContactsTable;
