import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import _ from 'lodash';
import DataReactTable from '../../Common/DataReactTable';
import EditProductPackageModal from '../../ProductPackages/modals/EditProductPackageModal';
import PermissionHelpers from '../../../helpers/roles/PermissionHelpers';
import {PERMISSIONS} from '../../../helpers/roles/Permissions';
import CopyProductPackageModal from '../../ProductPackages/modals/CopyProductPackageModal';

const ProductPackagesTable = ({ productPackages, setUpdatedPackage, addNewPackage, targetId, targetType }) => {
    const { t, i18n } = useTranslation(['meetings', 'common']);
    const hasPermissionEditMeetings = PermissionHelpers.hasPermission(PERMISSIONS.create_and_update_meeting);

    const createTableData = () => {
        const columns = [
            /* {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? '👇' : '👉'}
          </span>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
              {row.isExpanded ? '👇' : '👉'}
            </span>
                    ) : null,
            }, */
            {
                // Make an expander cell
                Header: '', // No header
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps({title: ''})}>
                        {row.isExpanded ? <span className="lnr lnr-chevron-down" /> : <span className="lnr lnr-chevron-right" />}
                    </span>
                    /* Needed with sub rows: row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
                          {row.isExpanded ? '1' : '2'}
                        </span>
                    ) : null */
                ),
            },
            {
                Header: '#',
                accessor: 'idx',
                disableGlobalFilter: true
            },
            {
                Header: t('code', { ns: 'common' }),
                accessor: 'code'
            },
            {
                Header: t('name', { ns: 'common' }),
                accessor: 'title',
                disableGlobalFilter: true,
            },
            {
                Header: t('price', { ns: 'common' }),
                accessor: 'priceText',
                disableGlobalFilter: true,
            },
            {
                Header: t('active', { ns: 'common' }),
                accessor: 'activeIcon',
                disableGlobalFilter: true,
            },
            {
                Header: '',
                accessor: 'editOrDelete',
                disableGlobalFilter: true,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <>
                        {hasPermissionEditMeetings && ( // Needed with sub rows: && !row.original.isProduct
                            <>
                                <EditProductPackageModal
                                    productPackage={_.find(productPackages, { 'code': row.values.code})}
                                    setUpdatedPackage={setUpdatedPackage}
                                />
                                <CopyProductPackageModal
                                    productPackage={_.find(productPackages, { 'code': row.values.code})}
                                    addNewPackage={addNewPackage}
                                    targetId={targetId}
                                    targetType={targetType}
                                />
                            </>
                        )}
                    </>
                )
            }
        ];

        const data = productPackages.map((item, index) => {
            // Needed with sub rows
            // item.isProduct = false;

            // active
            if (Number(item.active) === 1) {
                item.activeIcon = (<span className="lnr lnr-checkmark-circle check-ok font-weight-bold"></span>);
            } else {
                item.activeIcon = (<span className="lnr lnr-cross-circle check-danger font-weight-bold"></span>);
            }

            // price
            item.priceText = `${item.price} €`;

            // index
            item.idx = index + 1;

            // return item;
            return {
                ...item,
                // subRows, // Needed with sub rows
            };
        });

        return { tableHeaderData: columns, tableRowsData: data };
    };

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <>
                <h5 className="bold-text">{t('products', {ns: 'common'})}</h5>
                    {row.original.products.map((item, index) => {
                        return (
                            <div>
                                <span className="mr-2">
                                   {item.code}
                                </span>
                                <span className="mr-2">
                                   {item.title} ({item.type?.name_fi}) {/* TODO: language */}
                                </span>
                                <span>
                                    {item.quantity} x {item.price} €
                                </span>
                            </div>
                        );
                    })}
            </>
        ),
        []
    );

    return (
        <Col>
            {(productPackages.length > 0) && (
                <>
                    <Row>
                        <Col>
                            <DataReactTable reactTableData={createTableData()} searchEngineOn={false} renderRowSubComponent={renderRowSubComponent} />
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    );
};

ProductPackagesTable.propTypes = {
    // openEditModal: PropTypes.func.isRequired,
    addNewPackage: PropTypes.func.isRequired,
    productPackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    row: PropTypes.shape({
        canExpand: PropTypes.bool,
        depth: PropTypes.number,
        /* original: PropTypes.shape({
            isProduct: PropTypes.bool.isRequired
        }), */ // Needed with sub rows
        values: PropTypes.shape({
            code: PropTypes.string
        }),
        getToggleRowExpandedProps: PropTypes.func,
        isExpanded: PropTypes.func
    }),
    setUpdatedPackage: PropTypes.func.isRequired,
    targetId: PropTypes.number.isRequired,
    targetType: PropTypes.string.isRequired
};

ProductPackagesTable.defaultProps = {
    row: {
        values: {
            code: ''
        }
    }
};

export default ProductPackagesTable;
