import { CHANGE_LANGUAGE, LOAD_TRANSLATIONS, SET_LANGUAGE_DEFAULTS } from '../actionTypes';

export const changeLanguage = language => ({
    type: CHANGE_LANGUAGE,
    language
});

/* export const loadTranslations = translations => ({
    type: LOAD_TRANSLATIONS,
    translations
});

export const setLanguageDefaults = payload => ({
    type: SET_LANGUAGE_DEFAULTS,
    payload
}); */
