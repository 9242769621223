import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PasswordField from '@/shared/components/form/Password';
import Alert from '@/shared/components/alert/Alert';
import { required, matchesOtherValue, passwordValid } from '../../../../helpers/forms/validation';

const SetPasswordForm = ({
                       setPasswordFailed,
                       extraErrorText,
                       setPasswordButtonDisabled
                   }) => {
    const { t } = useTranslation(['login', 'profile']);
    const {
        getFieldState
    } = useForm();

    const composeValidators = (...validators) => value => (
        validators.reduce((error, validator) => error || validator(value), undefined)
    );

    return (
        <>
            {setPasswordFailed && (
                <Alert
                    color="danger"
                    className="mt-1"
                >
                    <p>
                        {t('passwordSetFailed')}
                        {extraErrorText && (
                            <div className="mt-2">
                                {extraErrorText}
                            </div>
                        )}
                    </p>
                </Alert>
            )}
            <div className="form__form-group">
                <span className="form__form-group-label">{t('password')}</span>
                <Field
                    name="password"
                    validate={composeValidators(required, passwordValid)}
                    component={PasswordField}
                    placeholder={t('password')}
                    className="input-without-border-radius"
                    keyIcon
                />

            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('profileMain.confirmPassword', {ns: 'profile'})}</span>
                <Field
                    name="confirm_password"
                    validate={composeValidators(required, passwordValid, matchesOtherValue(getFieldState('password')?.value, t('password')))}
                    component={PasswordField}
                    placeholder={t('password')}
                    className="input-without-border-radius"
                    keyIcon
                />
            </div>
            <Button
                className="account__btn"
                type="submit"
                color="primary"
                disabled={setPasswordButtonDisabled}
            >
                {t('setPassword')}
            </Button>
        </>
    );
};

SetPasswordForm.propTypes = {
    setPasswordFailed: PropTypes.bool.isRequired,
    extraErrorText: PropTypes.string.isRequired,
    setPasswordButtonDisabled: PropTypes.bool.isRequired
};

export default SetPasswordForm;
