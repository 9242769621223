import { axiosInstance as axios, axiosWithPersonalToken } from '../../axiosConfig';
import store from '../../containers/App/store';
import { logInUser, logOutUser } from '../../redux/actions/userActions';
import AxiosHelpers from '../axiosHelpers';
import AccessToken from '../AccessToken';

class UserHelpers {
    static async getAccounts() {
        try {
            const accounts = await axiosWithPersonalToken.get('accounts');
            return accounts.data;
        } catch (e) {
            // TODO
            /* const err = AxiosHelpers.handleRequestError(e);
            throw ({code: err.code}); */
            return {};
        }
    }

    static async getRoles() {
        try {
            const roles = await axiosWithPersonalToken.get('roles');
            return roles.data;
        } catch (e) {
            return {};
        }
    }

    static async loginWithEmailAndPassword(email, password) {
        try {
            const contactData = await axios.post('account/login', {
                email,
                password
            });

            const accessToken = contactData.data.access_token ?? '';

            // Layout\index.jsx loads contact data and permissions
            store.dispatch(logInUser(
                '',
                '',
                '',
                '',
                accessToken,
                [],
                [],
                '',
                '',
                ''
            ));

            return contactData.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async setPassword(password, confirmPassword, token, id) {
        try {
            const passwordSet = await axios.post('account/setPassword', {
                password,
                confirmPassword,
                id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async logout() {
        try {
            const loggedOut = await axiosWithPersonalToken.post('account/logout', {});
            store.dispatch(logOutUser());
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getUser() {
        try {
            const contact = await axiosWithPersonalToken.get('user', {});
            return contact.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async getUserCompany() {
        try {
            const contactCompany = await axiosWithPersonalToken.get('user/company', {});
            return contactCompany.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateLanguage(language) {
        try {
            const languageChanged = await axiosWithPersonalToken.post('user/language', { language });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateUserImage(image) {
        try {
            const imageChanged = await axiosWithPersonalToken.post('user/image', { image });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async changeUserPassword(oldPassword, newPassword, confirmPassword) {
        try {
            const passwordChanged = await axiosWithPersonalToken.post('user/password', { oldPassword, newPassword, confirmPassword });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async addAccount(firstName, lastName, email, roles) {
        try {
            const accountAdded = await axiosWithPersonalToken.post('account/add', {
                firstName,
                lastName,
                email,
                roles
            });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateAccount(firstName, lastName, email, oldEmail, roles = null) {
        try {
            const params = {
                firstName,
                lastName,
                email,
                oldEmail
            };
            if (roles !== null) {
                params.roles = roles;
            }
            const accountUpdated = await axiosWithPersonalToken.post('account/update', params);
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async deleteAccount(email) {
        try {
            const accountDeleted = await axiosWithPersonalToken.post('account/delete', { email });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async updateProfile(firstName, lastName, email) {
        try {
            const profileUpdated = await axiosWithPersonalToken.post('contact/edit', {
                firstName,
                lastName,
                email,
            });
            return true;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }

    static async refreshUserData() {
        const accessToken = AccessToken.getAccessToken();

        if (accessToken.length > 0) {
            try {
                const contactData = await UserHelpers.getUser();

                if (contactData.email) {
                    store.dispatch(logInUser(
                        contactData.contact,
                        contactData.email,
                        contactData.firstname,
                        contactData.lastname,
                        accessToken,
                        contactData.permissions,
                        contactData.roles,
                        contactData.contactImage,
                        contactData.title,
                        contactData.mobile
                    ));
                }
            } catch (e) {
                const err = AxiosHelpers.handleRequestError(e);
                throw e;
            }
        }
    }
}

export default UserHelpers;
