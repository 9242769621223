import { Modal } from 'reactstrap';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { getDirection } from '../../../../redux/reducers/rtlReducer';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import {ERROR_CODES} from '../../../../helpers/ErrorCodes';
import DomainHelpers from '../../../../helpers/Company/DomainHelpers';
import EditDomainForm from '../forms/EditDomainForm';

const CompanyDomainModal = ({ rtl, domain, setUpdatedDomain, toggleModal, showModal }) => {
    const [errorAlert, setErrorAlert] = useState('');
    const { t } = useTranslation(['companies', 'common']);

    const toggleDomainModal = () => {
        setErrorAlert('');
        toggleModal();
    };

    const handleSave = async (data) => {
        try {
            const updatedDomain = await DomainHelpers.updateDomain(
                domain.id,
                data.domain
            );

            setUpdatedDomain(updatedDomain);
            toggleModal();
        } catch (e) {
            const errorCode = e.code ?? '';
            let errMsg = t('errors.failedToSaveData', {ns: 'forms'});
            if (errorCode === ERROR_CODES.companyDomainAlreadyExist) {
                errMsg = t('errors.companyDomainAlreadyExist', {ns: 'companies'});
            }

            setErrorAlert(errMsg);
        }
    };

    const initialDomainValues = {
        domain: domain.domain
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                toggle={toggleDomainModal}
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggleDomainModal}
                    />
                    <h4 className="text-modal  modal__title">
                        {t('domain', {ns: 'common'})}
                    </h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSave} initialValues={initialDomainValues}>
                        {({ handleSubmit }) => (
                            <form className="material-form" onSubmit={handleSubmit}>
                                <EditDomainForm
                                    toggleModal={toggleDomainModal}
                                    errorAlertText={errorAlert}
                                    submitButtonText={t('buttons.save', {ns: 'forms'})}
                                />
                            </form>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

CompanyDomainModal.propTypes = {
    rtl: RTLProps.isRequired,
    domain: PropTypes.oneOfType([PropTypes.object]).isRequired,
    setUpdatedDomain: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default connect(state => ({
    rtl: getDirection(state),
}))(CompanyDomainModal);
