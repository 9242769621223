export const ALERT_TYPE = {
    danger: 'danger',
    success: 'success'
};

export const CONTACT_PROFILE_TYPE = {
    contact: 'contact',
    user: 'user' // Logged in user
};

export const DELIVERY_TYPE_CODE = {
    classRoom: 'C',
    hybrid: 'H',
    online: 'O'
};

export const LANGUAGE_CODE = {
    fi: 'fi',
    en: 'en'
};

export const ORDER_STATUS = {
    active: 'A',
    cancelled: 'C',
    inProgress: 'P'
};

export const PAYMENT_STATUS = {
    charged: 'C',
    manual: 'M',
    open: 'O',
    paid: 'P'
};

export const PAYMENT_TYPE = {
    creditCard: 'C',
    invoice: 'I'
};

export const PRODUCT_PACKAGE_TARGET_TYPE = {
    meeting: 'meeting'
};

export const SESSION_KEY = {
    id: 'id',
    language: 'language',
    access_token: 'cloud_token'
};

export const TARGET_REGISTRATION_STATUS = {
    active: 'A',
    cancelled: 'C',
    waiting: 'W'
};

export const TARGET_STATUS = {
    active: 'A',
    cancelled: 'C',
    planned: 'P'
};

export const TARGET_SEARCH_PARAMS = {
    cancelled: 'cancelled',
    upcoming: 'upcoming',
    past: 'past'
};

export const TARGET_TYPE = {
    meeting: 'meeting'
};

export const ROLE_NAMES = {
    mainUser: 'main_user',
    meetings: 'meetings'
};

export const ROLE_TYPES = {
    admin: 1,
    viewer: 2
};
