import isMatch from 'date-fns/isMatch';
import parse from 'date-fns/parse';
import {format} from 'date-fns';
import _, { toLower } from 'lodash';
import i18n from '../../translations';

export const dateValid = value => (!value || isMatch(value, 'dd.MM.yyyy') ? undefined : i18n.t('errors.dateInvalid', {ns: 'forms'}));

export const dateInFuture = (value) => {
    const valid = dateValid(value);
    if (typeof valid !== 'undefined') {
        return valid;
    }

    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    const givenDate = parse(value, 'dd.MM.yyyy', new Date());
    givenDate.setHours(0);
    givenDate.setMinutes(0);
    givenDate.setSeconds(0);
    givenDate.setMilliseconds(0);

    return givenDate.getTime() >= now.getTime() ? undefined : i18n.t('errors.dateNotInFuture', {ns: 'forms'});
};

export const required = value => (value ? undefined : i18n.t('errors.fieldRequired', {ns: 'forms'}));
export const requiredIfAnotherValueGiven = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo || value) {
        return undefined;
    }

    return i18n.t('errors.fieldRequiredIfAnotherValueGiven', {ns: 'forms', field: compareToFieldName.toLowerCase()});
};

export const timeValid = value => (!value || new RegExp('^([0-9]{2}):([0-9]{2})$').test(value) ? undefined : i18n.t('errors.timeInvalid', {ns: 'forms'}));

export const emailValid = (email) => {
    if (!email) return undefined;

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return undefined;
    }

    return i18n.t('errors.emailInvalid', {ns: 'forms'});
};

export const passwordValid = password => (password && password.length >= 4 ? undefined : i18n.t('errors.passwordInvalid', {ns: 'forms'}));

export const validStringInteger = (value) => {
    if (!value) return false;

    const err = i18n.t('errors.numberInvalid', {ns: 'forms'});
    value = value.trim();
    if (!value || value.length === 0) return err;
    let valid = true;

    value.split('').forEach(function (i) {
        if (!Number.isInteger(_.parseInt(i))) {
            valid = false;
        }
    });

    return valid && _.parseInt(value) >= 0 ? undefined : err;
};

export const greaterOrEquals = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo) return undefined;

    const err = `${i18n.t('errors.valueMustBeGreaterOrEqual', {ns: 'forms'})} ${compareToFieldName}`;
    return _.parseInt(value) >= _.parseInt(compareTo) ? undefined : err;
};

export const minValue = min => (value) => {
    if (!value) return false;
    const err = `${i18n.t('errors.valueMustBeGreaterOrEqual', {ns: 'forms'})} ${min}`;
    return _.parseInt(value) >= _.parseInt(min) ? undefined : err;
};

export const smallerOrEquals = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo) return undefined;

    const err = `${i18n.t('errors.valueMustBeSmallerOrEqual', {ns: 'forms'})} ${compareToFieldName}`;
    return _.parseInt(value) <= _.parseInt(compareTo) ? undefined : err;
};

export const matchesOtherValue = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo) return undefined;

    const err = `${i18n.t('errors.valueMustMatch', {ns: 'forms'})} ${toLower(compareToFieldName)}`;
    return value === compareTo ? undefined : err;
};

export const timeEarlier = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo) return undefined;

    const err = `${i18n.t('errors.valueMustBeSmallerOrEqual', {ns: 'forms'})} ${compareToFieldName}`;
    const dateNow = new Date();
    const dateNowStr = format(dateNow, 'yyyy-MM-dd');
    const startTime = new Date(`${dateNowStr} ${value}`);
    const endTime = new Date(`${dateNowStr} ${compareTo}`);

    return startTime < endTime ? undefined : err;
};

export const timeLater = (compareTo, compareToFieldName) => (value) => {
    if (!compareTo || !value) return undefined;

    const err = `${i18n.t('errors.valueMustBeGreaterOrEqual', {ns: 'forms'})} ${compareToFieldName}`;
    const dateNow = new Date();
    const dateNowStr = format(dateNow, 'yyyy-MM-dd');
    const startTime = new Date(`${dateNowStr} ${compareTo}`);
    const endTime = new Date(`${dateNowStr} ${value}`);

    return endTime > startTime ? undefined : err;
};

export const validBusinessId = (value) => {
    if (!value) return undefined;

    if (/^[0-9]{7}-[0-9]$/i.test(value) || /^[a-zA-Z]{2}[0-9]{8}$/i.test(value)) {
        return undefined;
    }

    return i18n.t('errors.businessIdInvalid', {ns: 'forms'});
};

export const validDomain = (value) => {
    if (!value) return undefined;

    if (/^([0-9a-zA-Z-_]+\.[0-9a-zA-Z-_]+)+$/i.test(value)) {
        return undefined;
    }

    return i18n.t('errors.domainInvalid', {ns: 'forms'});
};
