import {TARGET_TYPE} from '../constants';
import {CLOUDINARY_ROUTES, CLOUDINARY_TARGETS} from './CloudinaryConstants';
import {CloudinaryConfig} from './CloudinaryConfig';

class CloudinaryHelpers {
    static getTypeRoute(targetType) {
       if (targetType === TARGET_TYPE.meeting) {
            return CLOUDINARY_ROUTES.meeting;
       }
       if (targetType === CLOUDINARY_TARGETS.company) {
           return CLOUDINARY_ROUTES.company;
       }
       if (targetType === CLOUDINARY_TARGETS.contact) {
           return CLOUDINARY_ROUTES.contact;
       }
       if (targetType === CLOUDINARY_TARGETS.banner) {
           return CLOUDINARY_ROUTES.banner;
       }

        return '';
    }

    static getPublicId(targetType, imageName) {
        return `${CloudinaryConfig.baseFolder}${CloudinaryHelpers.getTypeRoute(targetType)}${imageName}`;
    }
}

export default CloudinaryHelpers;
