import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';

class MeetingRegistrationHelpers {
    static async getMeetingRegistration(id) {
        try {
            const registration = await axiosWithPersonalToken.get(`meetings/registrations/${id}`, {});
            return registration.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw e;
        }
    }
}

export default MeetingRegistrationHelpers;
