import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility, onLanguageChange }) => (
    <div className="topbar">
        <div className="topbar__wrapper">
            <div className="topbar__left">
                <TopbarSidebarButton
                  onClickMobile={changeMobileSidebarVisibility}
                  onClickDesktop={changeSidebarVisibility}
                />
                <Link className="topbar__logo" to="/" />
            </div>
            <div className="topbar__right">
                <TopbarProfile />
                <TopbarLanguage
                  onLanguageChange={onLanguageChange}
                />
            </div>
        </div>
    </div>
);

Topbar.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    onLanguageChange: PropTypes.func.isRequired
};

export default Topbar;
