import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { connect } from 'react-redux';
import UserHelpers from '../../../../helpers/users/UserHelpers';
import ContactProfileCompany from '../../../Contact/components/ContactProfileCompany';

const ProfileCompany = () => {
    const [companyFetched, setCompanyFetched] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const {t} = useTranslation('profile');

    const getContactCompany = async () => {
        try {
            const contactCompany = await UserHelpers.getUserCompany();

            setCompanyData(contactCompany);
            setCompanyFetched(true);
        } catch (e) {
            setCompanyFetched(true);
        }
    };

    useEffect(() => {
        getContactCompany();
    }, []);


    return (
        <>
            {companyFetched && (
                <ContactProfileCompany companyData={companyData} />
            )}
        </>
    );
};

ProfileCompany.propTypes = {};

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps
)(ProfileCompany);
