import { CHANGE_LANGUAGE, LOAD_TRANSLATIONS, SET_LANGUAGE_DEFAULTS } from '../actionTypes';
// import messagesFi from '../../translations/fi.json';
// import messagesEn from '../../translations/en.json';
import Session from '../../helpers/Session';

/* let messages = {
    'fi': messagesFi,
    'en': messagesEn,
    'se': {}
}; */

const initialState = {
    defaultLanguage: 'fi',
    language: 'fi',
    languages: [],
    // messages: messages.fi,
};

/* function getLanguageMessages(newLanguage) {
    let msg = [];
    newLanguage = newLanguage.toUpperCase();

    if (newLanguage === 'SE') {
        newLanguage = 'SV';
    }

    switch (newLanguage) {
        case 'FI': msg = messages.fi;
            break;
        case 'EN': msg = messages.en;
            break;
        case 'SV': msg = messages.se;
            break;
        default: msg = messages.en;
            break;
    }

    return msg;
} */

export default function languageReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            const newLanguage = action.language;
            // let msg = messages.en;

            // msg = getLanguageMessages(newLanguage);

            Session.saveLanguage(newLanguage);

            return {
                ...state,
                language: newLanguage
                // messages: msg
            };
        }
        /* case LOAD_TRANSLATIONS: {
            const translations = action.translations;

            if (typeof translations !== 'undefined' && translations !== null) {
                messages = translations;
            }

            const language = state.language;
            const msg = getLanguageMessages(language);

            return {
                ...state,
                messages: msg
            };
        }
        case SET_LANGUAGE_DEFAULTS: {
            const defaultLanguage = action.payload.defaultLanguage;

            return {
                ...state,
                defaultLanguage,
                language: defaultLanguage,
                languages: action.payload.languages
            };
        } */
        default:
            return state;
    }
}

export const getLanguage = state => state.languageReducer.language;
export const getLanguages = state => state.languageReducer.languages;
export const getDefaultLanguage = state => state.languageReducer.defaultLanguage;
