export const PERMISSIONS = {
    // Cloud users
    manage_cloud_users: 'manage_cloud_users',

    // Companies
    manage_companies: 'manage_companies',
    show_companies: 'show_companies',

    // Contacts
    manage_contacts: 'manage_contacts',
    show_contacts: 'show_contacts',

    // Meetings
    create_and_update_meeting: 'create_and_update_meeting',
    show_meetings: 'show_meetings'
};
