import { axiosWithPersonalToken } from '../../axiosConfig';
import AxiosHelpers from '../axiosHelpers';
import CodeError from '../../shared/components/error/CodeError';

class CompanyNameHelpers {
    static async createCompanyName(companyId, name, businessId) {
        try {
            const created = await axiosWithPersonalToken.post(`companies/${companyId}/names`, {
                name,
                businessId
            });
            return created.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }

    static async updateCompanyName(id, name, businessId) {
        try {
            const updated = await axiosWithPersonalToken.put(`companies/names/${id}`, {
                name,
                businessId
            });
            return updated.data;
        } catch (e) {
            const err = AxiosHelpers.handleRequestError(e);
            throw new CodeError(err.message, err.code);
        }
    }
}

export default CompanyNameHelpers;
