import {
    CHANGE_USER_IMAGE,
    LOG_IN_USER,
    LOG_OUT_USER
} from '../actionTypes';
import Session from '../../helpers/Session';

const initialState = {
    contactId: '',
    domain: '',
    email: '',
    firstname: '',
    lastname: '',
    permissions: [],
    roles: [],
    title: '',
    image: '',
    mobile: ''
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN_USER: {
            const { email, accessToken } = action;

            let domain = '';
            const emailSplit = email.split('@');

            if (typeof emailSplit[1] !== 'undefined' && emailSplit[1] !== null) {
                domain = emailSplit[1];
            }

            // Save to session
            Session.saveUser(accessToken);

            return {
                ...state,
                contactId: action.contactId,
                domain,
                email: action.email,
                firstname: action.firstname,
                lastname: action.lastname,
                permissions: action.permissions,
                roles: action.roles,
                title: action.title,
                image: action.image,
                mobile: action.mobile
            };
        }
        case LOG_OUT_USER: {
            Session.destroySession();

            return {
                ...state,
                contactId: '',
                domain: '',
                email: '',
                firstname: '',
                lastname: '',
                permissions: [],
                roles: [],
                title: '',
                image: '',
                mobile: ''
            };
        }
        case CHANGE_USER_IMAGE: {
            return {
                ...state,
                image: action.image
            };
        }
        default:
            return state;
    }
}

export const getUserDomain = state => state.userReducer.domain;
export const getUserEmail = state => state.userReducer.email;
export const getUserId = state => state.userReducer.contactId;
export const getUserFirstName = state => state.userReducer.firstname;
export const getUserLastName = state => state.userReducer.lastname;
export const getUserMobile = state => state.userReducer.mobile;
export const getUserName = (state) => {
    return `${state.userReducer.firstname} ${state.userReducer.lastname}`;
};
export const getPermissions = state => state.userReducer.permissions;
export const getRoles = state => state.userReducer.roles;
export const getUserTitle = state => state.userReducer.title;
export const getUserImage = state => state.userReducer.image;
